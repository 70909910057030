import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

function SideBar() {
  const location = useLocation();
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState("");

  const renderNavLink = (linkIndex, iconName, linkText, path) => {
    const isActive = location.pathname === path;
    const linkClassNames = `nav-link ${isActive ? "active" : ""}`;

    return (
      <li className={linkClassNames} key={linkIndex}>
        <Link to={path}>
          <i className={`bx ${iconName} icon`}></i>
          <span className="text nav-text">{linkText}</span>
        </Link>
      </li>
    );
  };
  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("role");
    setRedirectTo("/login");
  };

  if (redirectTo) {
    history.push(redirectTo);
  }

  return (
    <>
      <link
        href="https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css"
        rel="stylesheet"
      />

      <nav className="sidebar open">
        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links">
              {renderNavLink(1, "bx-money-withdraw", "Pagos", "/admin/pagos")}
              {renderNavLink(
                2,
                "bx-envelope",
                "Email Registro",
                "/admin/registro-psicologos"
              )}
              {renderNavLink(
                3,
                "bx-check",
                "Validar Psi",
                "/admin/validar-psicologos"
              )}
              {renderNavLink(
                4,
                "bxs-report",
                "Generar CSV",
                "/admin/generar-csv"
              )}
              {renderNavLink(
                5,
                "bx-error-alt",
                "Psi Pendientes",
                "/admin/psicologos-pendientes"
              )}
            </ul>
          </div>
          <div className="logout d-flex justify-content-center">
            <button onClick={logout}>
              <i className="bx bx-log-out icon"></i>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default withRouter(SideBar);
