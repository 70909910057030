import React, { useEffect, useState } from "react";
import "../../assets/css/miEspacio.css";
import axiosInstance from "../../helpers/axiosConfig";
import ProximaCita from "./ProximaCita";
import SectionsRefs from "./SectionsRefs";
import { Helmet } from "react-helmet";
import OfertaPaquetes from "./OfertaPaquetes";
import OfertaPrimeraCita from "./OfertaPrimeraCita";

const MiEspacio = () => {
  const [nextCita, setNextCita] = useState(null);
  const [loading, setLoading] = useState(true);
  const [psicologoId, setPsicologoId] = useState("");

  useEffect(() => {
    async function fetchCitas() {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/citas");
        if (response.status === 200) {
          const citaProxima = await getNextCita(response.data.body);
          if (citaProxima) {
            setNextCita(citaProxima);
            setPsicologoId(citaProxima.psicologo_id);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    fetchCitas();
  }, []);

  async function getNextCita(citas) {
    const citasFuturas = citas.filter(
      (cita) =>
        new Date(cita.fecha) > new Date(new Date().getTime() - 50 * 60000) &&
        (cita.estado_id === 2 || cita.estado_id === 3)
    );
    if (citasFuturas.length === 0) {
      return null;
    }

    citasFuturas.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
    const citaProxima = citasFuturas[0];

    return citaProxima;
  }

  return (
    <>
      <Helmet>
        <title>Mindly | Mi Espacio</title>
        <meta name="description" content="Mi Espacio" />
      </Helmet>
      <div className="mi-espacio-section">
        <ProximaCita nextCita={nextCita} loading={loading} />
        <SectionsRefs psicologoIdProp={psicologoId} />
        <OfertaPaquetes />
      </div>
    </>
  );
};

export default MiEspacio;
