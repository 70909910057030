import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Agendar from "../Agendar";

const EventModal = ({ isOpen, closeModal }) => {
  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal} className="justify-content-center">
        Agregar horario no disponible
      </ModalHeader>
      <ModalBody>
        <Agendar />
      </ModalBody>
    </Modal>
  );
};

export default EventModal;
