import React from "react";
import axiosInstance from "../../helpers/axiosConfig";

const NotAvailableModalBody = ({ eventProps }) => {
  const handleClick = async () => {
    try {
      const response = await axiosInstance.delete(
        `/citas/no-disponible/${eventProps.citaId}`
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="text-center">
      <p className="description-text m-3">
        *Este Horario no estara disponible para que los pacientes puedan agendar
        citas
      </p>
      <button
        className="btn btn-danger m-2"
        style={{ width: "50%", margin: "auto !important" }}
        onClick={handleClick}
      >
        Eliminar horario no disponible
      </button>
    </div>
  );
};

export default NotAvailableModalBody;
