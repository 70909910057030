import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/ayudarDepresion/depresion-min.jpg";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Cómo Ayudar a una Persona con Depresión - 10 Consejos</title>
          <meta
            name="description"
            content="La depresión es una enfermedad mental seria que afecta a millones de personas en todo el mundo. ¡Descubre 10 formas para ayudar a otros!"
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img
                    src={standard}
                    className="img-fluid"
                    alt="Hombre triste sentado en una silla"
                    itemProp="image"
                  />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Depresión - Cómo ayudar
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        Cómo Ayudar a una Persona con Depresión - 10 Consejos
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 29 de
                          Noviembre, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="mt-3">
                      La <strong>depresión</strong> es una enfermedad mental
                      seria que afecta a millones de personas en todo el mundo.
                      Puede manifestarse de diversas maneras y tener un{" "}
                      <strong>impacto significativo</strong> en la vida diaria
                      de quienes la padecen.
                    </p>
                    <p>
                      Si tienes a alguien cercano que enfrenta la depresión, es
                      crucial ofrecer apoyo de manera{" "}
                      <strong>comprensiva</strong> y <strong>solidaria.</strong>{" "}
                      Aquí te proporcionamos algunas pautas sobre cómo ayudar a
                      una persona con depresión.
                    </p>
                    <p>
                      En este artículo, exploraremos el narcisismo,{" "}
                      <strong className="text-primary">qué es</strong> y{" "}
                      <strong className="text-primary">
                        cuales son sus síntomas
                      </strong>
                    </p>
                    {/* <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote> */}
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      1. Educación y Comprensión
                    </h2>
                    <p>
                      El primer paso para ayudar a alguien con depresión es
                      intentar educarte sobre la enfermedad. La depresión no es
                      simplemente sentirse triste; es un{" "}
                      <strong>trastorno médico</strong> que afecta la forma en
                      que una persona piensa, siente y maneja las actividades
                      diarias.
                    </p>
                    <p>
                      Comprender los <strong>síntomas</strong> y las{" "}
                      <strong>causas</strong> de la depresión te permitirá ser
                      más empático y comprensivo.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      2. Escucha Activa y Empatía
                    </h2>
                    <p>
                      Una de las maneras más poderosas de ayudar a alguien con
                      depresión es simplemente <strong>escuchar.</strong> A
                      menudo, las personas que enfrentan esta enfermedad se
                      sienten solas y incomprendidas.
                    </p>
                    <p>
                      Ofrece tu oído de manera activa y{" "}
                      <strong>sin juzgar.</strong> Haz preguntas abiertas para
                      animarles a compartir sus sentimientos y experiencias. La
                      empatía es clave; intenta ponerte en su lugar para
                      comprender mejor lo que están experimentando.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      3. Sé un Apoyo Incondicional
                    </h2>
                    <p>
                      Es esencial que la persona sienta que tienes su apoyo
                      incondicional. Aunque no puedas resolver sus problemas,
                      estar presente y mostrar que te importa puede marcar la
                      diferencia. A veces, simplemente{" "}
                      <strong>saber que alguien está ahí</strong> para ellos
                      puede proporcionar un gran alivio emocional.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      4. Anima a la Búsqueda de Ayuda Profesional
                    </h2>
                    <p>
                      La depresión a menudo requiere{" "}
                      <strong>tratamiento profesional.</strong>
                      Anima a la persona a buscar la ayuda de un terapeuta,
                      psicólogo o psiquiatra. Ofrecer tu apoyo en este proceso,
                      como acompañarles a la cita o ayudarles a encontrar un
                      profesional adecuado, puede ser de gran ayuda.
                    </p>
                    <p>
                      En{" "}
                      <Link to="/home" className="bold text-primary">
                        Mindly
                      </Link>
                      , tenemos a un grupo de{" "}
                      <strong>psicólogos expertos</strong> en salud mental
                      listos para ofrecer el servicio de mayor calidad. Haz
                      click{" "}
                      <Link
                        to="/home"
                        className="text-primary text-decoration-underline"
                      >
                        aquí
                      </Link>{" "}
                      para conocer más.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      5. Fomenta un Estilo de Vida Saludable
                    </h2>
                    <p>
                      Una buena <strong>salud física</strong> puede tener un
                      impacto positivo en la salud mental. Anima a la persona a
                      mantener un estilo de vida saludable, que incluya una
                      <strong>dieta equilibrada</strong>,{" "}
                      <strong>ejercicio regular</strong> y suficiente{" "}
                      <strong>descanso.</strong> <br />
                      Estos hábitos pueden ayudar a mejorar el estado de ánimo y
                      proporcionar una base sólida para el tratamiento.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      6. Comprende los Límites del Apoyo
                    </h2>
                    <p>
                      Aunque es vital ofrecer apoyo, también es crucial
                      comprender que no eres un{" "}
                      <strong>terapeuta profesional.</strong> No asumas la
                      responsabilidad de "curar" a la persona. Anima la búsqueda
                      de ayuda profesional y reconoce tus propios límites.
                    </p>
                    <p>
                      La depresión puede ser compleja, y a veces se necesita la
                      experiencia de un <strong>profesional</strong> para
                      abordarla de manera eficaz.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      7. Sé Paciente y Consistente
                    </h2>
                    <p>
                      La recuperación de la depresión lleva tiempo y es un
                      proceso gradual. Sé paciente y consistente en tu apoyo. Es
                      posible que haya momentos en los que la persona se sienta
                      mejor y otros en los que parezca retroceder. La
                      consistencia en tu apoyo y comprensión puede ser un faro
                      en medio de la oscuridad.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      8. Evita el Estigma y la Culpa
                    </h2>
                    <p>
                      La depresión no es una elección ni una debilidad. Evita el
                      estigma y la culpa alrededor de la enfermedad. En lugar de
                      juzgar, ofrece compasión y comprensión. La sociedad a
                      menudo malinterpreta la depresión, y tu papel puede ser
                      <strong> desafiar esos estigmas</strong> y crear un
                      entorno de apoyo.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      9. Involúcrate en Actividades Positivas
                    </h2>
                    <p>
                      Incentiva la participación en actividades que solían
                      disfrutar. Ya sea salir a caminar, ver una película, o
                      practicar un hobby, estas actividades pueden tener un
                      <strong> impacto positivo</strong> en el estado de ánimo.
                      Sin embargo, respeta los límites y no fuerces a la persona
                      a participar si no se siente cómoda.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      10. Cuida de Ti Mismo
                    </h2>
                    <p>
                      Apoyar a alguien con depresión puede ser emocionalmente
                      agotador. Asegúrate de cuidar de tu propia salud mental.
                      Busca apoyo en amigos, familiares o profesionales si es
                      necesario. Mantener un equilibrio saludable entre el apoyo
                      a los demás y el autocuidado es esencial para ser un apoyo
                      efectivo.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Conclusión
                    </h2>
                    <p>
                      Ayudar a una persona con depresión implica educación,
                      empatía, paciencia y apoyo continuo. La depresión puede
                      afectar a cualquiera, y tu papel como apoyo comprensivo
                      puede marcar la diferencia en la vida de alguien que está
                      luchando contra esta enfermedad.
                    </p>

                    <p>
                      Si tú o alguien que conoces está sufriendo por depresión,
                      en{" "}
                      <a
                        href="https://www.mindly.la/home"
                        className="text-primary"
                      >
                        Mindly
                      </a>
                      , tenemos a un grupo de{" "}
                      <strong>psicólogos expertos</strong> en salud mental
                      listos para ofrecer el servicio de mayor calidad. <br />{" "}
                      <br />
                      ¡Agenda tu sesión hoy mismo! <br></br> <br></br> La{" "}
                      <strong>primera sesión</strong> cuenta con un
                      <strong> 50% OFF</strong>.{" "}
                      <Link
                        to="/register"
                        className="text-primary text-decoration-underline"
                      >
                        ¡Regístrate ahora!
                      </Link>
                      <br></br>
                    </p>
                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>183
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
