import React from "react";
import { Link } from "react-router-dom";
import bagIcon from "../../assets/images/bag-icon-purple-min.png";
import "../../assets/css/miEspacioOffers.css";

const OfertaPaquetes = () => {
  return (
    <div className="proxima-cita-section-container">
      <div className="ref-lins-container ref-lins-container-offer d-flex flex-column justify-content-around align-items-center">
        <div className="offer-container">
          <div className="offer-info-container">
            <div style={{ minHeight: "5rem", minWidth: "5rem" }}>
              <img className="offer-icon" src={bagIcon} alt="bag-icon"></img>
            </div>
            <div className="offer-text-container">
              <h5 className="offer-title">¡Ahorra con paquetes!</h5>
              <p className="offer-text text-secondary">
                Obtén hasta un <strong>20%</strong> de descuento en tus citas
                comprando un paquete.
              </p>
            </div>
          </div>
        </div>
        <div className="offer-button-container">
          <Link
            to="/mi-espacio/paquetes"
            className="btn btn-soft-primary btnOferta"
          >
            Ver paquetes
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OfertaPaquetes;
