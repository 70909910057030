import React, { useEffect, useState, useRef } from "react";
import "../../assets/css/misHorarios.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DayDescanso from "./DayDescanso";
import axiosInstance from "../../helpers/axiosConfig";
import autoAnimate from "@formkit/auto-animate";

const Descansos = () => {
  const [availablity, setAvailability] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const daysMapCodes = {
    lun: "Lunes",
    mar: "Martes",
    mié: "Miércoles",
    jue: "Jueves",
    vie: "Viernes",
    sáb: "Sábado",
    dom: "Domingo",
  };

  //Animations
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 200 });
  }, [parent]);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const response = await axiosInstance.get("/psicologos/disponibilidad");
        if (response.status === 200) {
          setAvailability(response.data.body);
          let days = response.data.body.availableDays.split(",");
          days.sort((a, b) => {
            return (
              Object.keys(daysMapCodes).indexOf(a) -
              Object.keys(daysMapCodes).indexOf(b)
            );
          });
          if (days[0] !== "") {
            setAvailableDays(days);
          }
        }
      } catch (error) {}
    };
    fetchAvailability();
  }, []);

  return (
    <>
      {" "}
      <Helmet>
        <title>Mindly | Mis Horarios</title>
        <meta name="description" content="Mis Horarios" />
      </Helmet>
      <div className="misHorarios-section">
        <div className="misHorarios-container">
          <nav className="nav nav-pills flex-column flex-sm-row">
            <Link
              to="/mi-espacio/mis-horarios"
              className="flex-sm-fill text-sm-center nav-link tab-switcher"
            >
              Horarios
            </Link>
            <Link
              to="/mi-espacio/mis-horarios/descansos"
              className="flex-sm-fill text-sm-center nav-link active tab-switcher"
              aria-current="page"
            >
              Descansos
            </Link>
          </nav>
          <div className="misDescansos-container">
            <div className="misDescansos-cards" ref={parent}>
              {availableDays.length >= 1 &&
                availableDays.map((day, index) => (
                  <DayDescanso
                    key={index}
                    dayOfWeek={daysMapCodes[day]}
                    availablity={availablity}
                  />
                ))}
              {availableDays.length === 0 && (
                <p className="text-center text-secondary mt-5">
                  No hay ningún día de trabajo activo
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Descansos;
