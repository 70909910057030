// React Basic and Bootstrap
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import ArticleCard from "./ArticleCard";
import { Helmet } from "react-helmet";

// import images
import expectativas1 from "../../assets/images/blog/standard.jpg";
import empatia1 from "../../assets/images/blog/empatia1/josh-calabrese-qmnpqDwla_E-unsplash-min.jpg";
import emociones1 from "../../assets/images/blog/emociones1/tengyart-DoqtEEn8SOo-unsplash-min.jpg";
import mitomania from "../../assets/images/blog/mitomania/sander-sammy-q7ZlbWbDnYo-unsplash-min.jpg";
import heridas from "../../assets/images/blog/heridasDeInfancia/heridasDeInfancia-min.jpg";
import amorPropio from "../../assets/images/blog/amorPropio/amor-propio-1-min.jpg";
import narcisismo from "../../assets/images/blog/narcisismo/narcisismo.jpg";
import ayudaDepresion from "../../assets/images/blog/ayudarDepresion/depresion-min.jpg";
import manejoEmociones from "../../assets/images/blog/manejoEmociones/manejoEmociones-min.jpg";

class PageBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [
        {
          id: 1,
          image: expectativas1,
          title: "Las expectativas: ¿Por qué nos afectan tanto?",
          like: "33",
          link: "/blog/expectativas",
          autor: "Daniela Castro",
          date: "17 de Agosto, 2023",
        },
        {
          id: 2,
          image: empatia1,
          title:
            "Empatía: La Clave para Relaciones Significativas | Aprende Cómo",
          like: "82",
          link: "/blog/que-es-la-empatia-definicion-importancia-desarrollo",
          autor: "Daniela Castro",
          date: "17 de Agosto, 2023",
        },
        {
          id: 3,
          image: emociones1,
          title: "Tipos de emociones: Descubre los Secretos para Gestionarlas",
          like: "115",
          link: "/blog/tipos-de-emociones-como-gestionarlas",
          autor: "Daniela Castro",
          date: "20 de Agosto, 2023",
        },
        {
          id: 4,
          image: mitomania,
          title: "¿Qué es la Mitomanía? Síntomas, Causas y Tratamiento",
          like: "98",
          link: "/blog/mitomania-sintomas-causas-tratamiento",
          autor: "Daniela Castro",
          date: "1 de Octubre, 2023",
        },
        {
          id: 5,
          image: heridas,
          title: "¿Qué son las heridas de la infancia? | Explora sus 5 tipos",
          like: "102",
          link: "/blog/5-heridas-de-la-infancia",
          autor: "Daniela Castro",
          date: "23 de Noviembre, 2023",
        },
        {
          id: 6,
          image: amorPropio,
          title: "Amor propio: Qué es y 7 pasos para mejorar tu autoestima",
          like: "123",
          link: "/blog/amor-propio-y-autoestima",
          autor: "Daniela Castro",
          date: "23 de Noviembre, 2023",
        },
        {
          id: 7,
          image: narcisismo,
          title: "Personalidad narcisista: ¿Qué es y cuales son sus Síntomas?",
          like: "153",
          link: "/blog/que-es-narcisista",
          autor: "Daniela Castro",
          date: "29 de Noviembre, 2023",
        },
        {
          id: 8,
          image: ayudaDepresion,
          title: "Cómo Ayudar a una Persona con Depresión - 10 Consejos",
          like: "183",
          link: "/blog/como-ayudar-personas-con-depresion",
          autor: "Daniela Castro",
          date: "29 de Noviembre, 2023",
        },
        {
          id: 9,
          image: manejoEmociones,
          title: "Manejo de Emociones: ¿Qué es?",
          like: "122",
          link: "/blog/manejo-de-emociones",
          autor: "Daniela Castro",
          date: "1 de Diciembre, 2023",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Mindly | Artículos sobre salud mental</title>
          <meta
            name="description"
            content="Mindly es una plataforma de psicólogos online que te permite encontrar al psicólogo ideal para ti. Conoce a nuestros psicólogos y agenda tu cita."
          />
        </Helmet>
        {/* breadcrumb */}
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> Artículos sobre salud mental </h4>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item">
                    <Link to="/home">Mindly</Link>
                  </li>{" "}
                  <li className="breadcrumb-item">
                    <Link to="/blog">Blog</Link>
                  </li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">
                    Índice
                  </li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              {/* blog box */}
              {this.state.blogs.map((blog, key) => (
                <ArticleCard article={blog} key={key} />
              ))}

              <Col xs="12">
                <Pagination listClassName="justify-content-center mb-0">
                  <PaginationItem>
                    <PaginationLink to="#">Prev</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink to="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageBlog;
