import React, { useEffect, useState } from "react";
import CitasContainer from "./CitasContainer";
import "../../assets/css/misCitas.css";
import axiosInstance from "../../helpers/axiosConfig";
import ThreeDots from "../../components/Shared/Loaders/ThreeDots";
import { Helmet } from "react-helmet";

function MisCitas() {
  const [citas, setCitas] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchCitas() {
      try {
        const response = await axiosInstance.get("/citas");
        if (response.status === 200) {
          setCitas(response.data.body);
        }
      } catch (error) {
        setError(error);
      }
    }
    fetchCitas();
    setIsLoading(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>Mindly | Mis Citas</title>
        <meta name="description" content="Mis Citas" />
      </Helmet>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <ThreeDots />
        </div>
      )}
      {!isLoading && <CitasContainer citas={citas} />}
    </>
  );
}

export default MisCitas;
