import React, { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig.js";
import axios from "axios";

const PageOAuthSucceed = () => {
  const [redirectTo, setRedirectTo] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const pacienteId = params.get("pacienteId");

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
    if (pacienteId) {
      setIpLocation(pacienteId);
    } else {
      setRedirectTo("/mi-espacio");
    }
  }, [token, pacienteId]);

  function setAuthToken(token) {
    const expiresAt = new Date().getTime() + 1000 * 60 * 60 * 11;
    localStorage.setItem("authToken", token);
    localStorage.setItem("expiresAt", expiresAt);
    localStorage.setItem("role", "paciente");
  }

  async function setIpLocation(pacienteId) {
    try {
      const ip = await getIp();
      await axiosInstance.post("/ip-location", {
        ip,
        paciente_id: pacienteId,
      });
    } catch (error) {
      console.log(error);
    }
    setRedirectTo("/mi-espacio");
  }

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Spinner color="primary" />
    </div>
  );
};

export default PageOAuthSucceed;
