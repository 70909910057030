import React, { useState, useEffect } from "react";
import "../../assets/css/review.css";
import {
  Input,
  Form,
  Alert,
  Button,
  Spinner,
  Modal,
  ModalBody,
} from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

function ReviewForm() {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(5);
  const [hover, setHover] = useState(0);
  const [reviewCreated, setReviewCreated] = useState(false);
  const { psicologoId } = useParams();
  const [authToken, setAuthToken] = useState(null);
  const [psicologo, setPsicologo] = useState(null);
  const [redirectTo, setRedirectTo] = useState("");

  const location = useLocation();

  useEffect(() => {
    getAuthToken();
    if (psicologoId) {
      fetchPsicologo(psicologoId);
    }
  }, []);

  const toggle = () => {
    setReviewCreated(!reviewCreated);
    setRedirectTo("/home");
  };

  async function fetchPsicologo(psicologoId) {
    try {
      const response = await axiosInstance.get(`/psicologos/${psicologoId}`);
      if (response.status === 200) {
        setPsicologo(response.data.body);
      }
    } catch (error) {
      setError("Error al cargar el psicólogo");
    }
  }

  const getAuthToken = () => {
    if (location.search) {
      const token = location.search.split("=")[1];
      setAuthToken(token);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const reviewObj = {
      psicologo_id: psicologoId,
      fecha: new Date(),
      puntaje: rating,
      descripcion: event.target.opinion.value,
    };
    createReview(reviewObj);
    setIsLoading(false);
  };

  async function createReview(review) {
    try {
      const response = await axiosInstance.post(`/reviews`, {
        newResenia: review,
        token: authToken,
      });
      console.log(response);
      if (response.status === 201) {
        setReviewCreated(true);
      }
    } catch (error) {
      throw new Error("Error al crear la reseña");
    }
  }

  if (redirectTo) {
    history.push(redirectTo);
  }

  return (
    <>
      <Helmet>
        <title>Mindly | Crear Reseña</title>
        <meta name="description" content="Crear Reseña" />
      </Helmet>
      {reviewCreated && (
        <Modal isOpen={reviewCreated} toggle={toggle}>
          <ModalBody>
            <div className="text-center">
              <div className="mb-4">
                <i className="bx bx-check-circle display-4"></i>
              </div>
              <h4>¡Reseña enviada! 🙌🏽</h4>
              <p className="text-muted mb-0">¡Gracias por tu opinión!</p>
            </div>
            <div className="mt-4 text-center">
              <button
                className="btn btn-primary btn-block m-2"
                onClick={toggle}
              >
                Genial! 😄
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
      {psicologo && (
        <div className="review-section">
          <div className="review-form-container">
            <div className="review-form-header">
              {error && (
                <Alert className="bg-soft-primary fw-medium">
                  <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
                  {error}
                </Alert>
              )}
              <h5>
                ¡Heyy! Nos encantaría que nos dejes tu opinión sobre{" "}
                {psicologo.nombre}.
              </h5>
              <p className="text-muted">
                Tu opinión nos ayuda a mejorar la calidad de nuestros
                profesionales para poder brindar el mejor servicio.
              </p>
            </div>
            <Form onSubmit={handleSubmit} className="review-from">
              <div className="mb-3">
                <Input
                  type="textarea"
                  maxLength={200}
                  name="opinion"
                  id="opinion"
                  placeholder="Escribe tu opinión"
                />
              </div>
              <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      key={index}
                      className={index <= (hover || rating) ? "on" : "off"}
                      onClick={() => setRating(index)}
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(rating)}
                    >
                      <span className="star">&#9733;</span>
                    </button>
                  );
                })}
              </div>
              <div>
                <Button
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn btn-pills btn-primary btnActualizar d-flex justify-content-center align-items-center enviarOpinionBtn"
                >
                  {isLoading ? (
                    <Spinner size="sm" className="mx-2"></Spinner>
                  ) : (
                    <i className="bx bx-calendar-plus"></i>
                  )}
                  <p className="m-0">Envíar opinión</p>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}

export default ReviewForm;
