import React, { useEffect, useState } from "react";
import "../../assets/css/cita.css";
import PsicologoCard from "../../components/Shared/PsicologoSmallCard";
import axiosInstance from "../../helpers/axiosConfig";
import { Alert, Tooltip } from "reactstrap";
import { useParams, Link, useLocation } from "react-router-dom";
import CitaLoader from "../../components/Shared/Loaders/CitaLoader";
import ReCompra from "./ReCompra.js";
import { Helmet } from "react-helmet";

function Cita() {
  const [cita, setCita] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [potentialNextDay, setPotentialNextDay] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [unavailableDateTimes, setUnavailableDateTimes] = useState(null);
  const [newCitaObj, setNewCitaObj] = useState(null);
  const [asked, setAsked] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { citaId } = useParams();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const location = useLocation();
  let isUpdate = false;
  if (location.state) {
    isUpdate = location.state.isUpdate;
  }

  useEffect(() => {
    async function getCita(citaId) {
      try {
        const response = await axiosInstance.get(`/citas/${citaId}`);
        if (response.data.status === 200) {
          setCita(response.data.body);
          setLoading(false);
        }
      } catch (error) {
        setError("Error al obtener la cita");
      }
    }
    if (citaId) {
      getCita(citaId);
    }
  }, [citaId]);

  const handleIngresarLinkClick = async (event) => {
    event.preventDefault();

    const url = `/sesion?authToken=${encodeURIComponent(
      cita.paciente_authToken
    )}`;
    window.open(url, "_blank");

    try {
      await axiosInstance.patch(`/citas/${cita.id}/paciente-joined`);
    } catch (error) {
      console.error("Error updating cita");
    }

    setTimeout(async () => {
      await fetchPsicologoAvailability();
    }, 600000);
  };

  const isCitaDisponible = () => {
    const availableDays = availability.availableDays.split(",");
    const potentialDay = potentialNextDay.toLocaleString("es-ES", {
      weekday: "short",
    });

    if (!availableDays.includes(potentialDay.toLowerCase())) {
      return false; // El psicólogo no atiende en ese día
    }

    const availableFromTime = availability.availableFromTime;
    const availableToTime = availability.availableToTime;
    const potentialDate = new Date(potentialNextDay.toISOString());
    const potentialUTCHours = potentialDate.getUTCHours();
    const potentialUTCMinutes = potentialDate.getUTCMinutes();
    const potentialTime = `${potentialUTCHours
      .toString()
      .padStart(2, "0")}:${potentialUTCMinutes.toString().padStart(2, "0")}`;

    if (availableFromTime <= availableToTime) {
      if (
        potentialTime < availableFromTime ||
        potentialTime > availableToTime
      ) {
        return false; // El horario de la cita está fuera del rango disponible
      }
    } else {
      if (
        potentialTime < availableFromTime &&
        potentialTime > availableToTime
      ) {
        return false; // El horario de la cita está fuera del rango disponible
      }
    }

    const utcPotentialTime = potentialNextDay.toISOString();
    if (unavailableDateTimes.includes(utcPotentialTime)) {
      return false; // El horario de la cita ya está ocupado
    }

    return true; // La cita está disponible
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const fetchPsicologoAvailability = async () => {
    try {
      await fetchAvailability();
      await getUnavailableDateTimes();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAvailability = async () => {
    try {
      const response = await axiosInstance.get(
        `/psicologos/${cita.psicologo_id}/disponibilidad`
      );
      if (response.status === 200) {
        setAvailability(response.data.body);
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  async function getUnavailableDateTimes() {
    try {
      const response = await axiosInstance.get(
        `/psicologos/${cita.psicologo_id}/unavailableDateTimes`
      );
      if (response.status === 200) {
        setUnavailableDateTimes(response.data.body);
      }
    } catch (error) {
      console.error("Error fetching unavailableDateTimes:", error);
    }
  }

  const getNextDate = async () => {
    if (cita && cita.fecha) {
      const fechaActual = new Date(cita.fecha);
      const nextDate = new Date(
        fechaActual.getTime() + 7 * 24 * 60 * 60 * 1000
      );
      setPotentialNextDay(nextDate);
    }
  };

  useEffect(() => {
    getNextDate();
  }, [cita]);

  if (availability && potentialNextDay && unavailableDateTimes && !asked) {
    const isAvailable = isCitaDisponible();

    if (isAvailable) {
      setNewCitaObj({
        psicologo_id: cita.psicologo_id,
        paciente_id: cita.paciente_id,
        fecha: potentialNextDay.toISOString(),
      });

      toggleModal();
    }
    setAsked(true);
  }

  return (
    <>
      <Helmet>
        <title>Mindly | Cita</title>
        <meta name="description" content="Detalle de la Cita" />
      </Helmet>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {isUpdate && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          Cita actualizada con éxito
        </Alert>
      )}
      {cita && (
        <div className="cita-section-container">
          <div className="cita-container">
            {loading ? (
              <CitaLoader className="mx-5" />
            ) : (
              <div className="cita-container-items">
                {cita.psicologo_id && (
                  <div className="especialista-section">
                    <h6>Especialista</h6>
                    <div style={{ minHeight: "5.7rem" }}>
                      <PsicologoCard psicologoId={cita.psicologo_id} />
                    </div>
                  </div>
                )}
                <div className="cita-section">
                  <h6>Detalles de la cita</h6>
                  <div className="cita-status">
                    <img
                      src="https://i.imgur.com/yjF35i7.png"
                      alt="profile-icon"
                    />
                    <div className="cita-status-text">
                      <p className="cita-status-text-primary">
                        {cita.paciente || "Terapia individual"}
                      </p>
                      <p className="cita-status-text-secondary">
                        {(() => {
                          switch (cita.estado_id) {
                            case 1:
                              return (
                                <small className="text-warning">
                                  <i className="bx bx-time"></i> Pendiente de
                                  pago
                                </small>
                              );
                            case 2:
                              return (
                                <small className="text-success">
                                  Confirmada
                                </small>
                              );
                            case 3: {
                              const citaFecha = new Date(cita.fecha);
                              const now = new Date();
                              const differenceInHours =
                                (now - citaFecha) / (1000 * 60 * 60);

                              if (differenceInHours >= 1) {
                                return (
                                  <small className="text-primary">
                                    Completada
                                  </small>
                                );
                              } else {
                                return (
                                  <small className="text-success">
                                    Confirmada
                                  </small>
                                );
                              }
                            }
                            case 4:
                              return (
                                <small className="text-danger">Cancelada</small>
                              );
                            default:
                              return (
                                <small className="text-danger">Error</small>
                              );
                          }
                        })()}
                      </p>
                    </div>
                  </div>
                  <div className="cita-details">
                    <div className="cita-details-item">
                      <span className="cita-details-item-icon">
                        <i className="bx bx-calendar"></i>
                      </span>
                      <div className="cita-details-item-text">
                        <p className="text-secondary">
                          {new Date(cita.fecha).toLocaleString("es-AR", {
                            dateStyle: "medium",
                            timeZone: userTimezone,
                          })}
                        </p>
                        <p className="text-primary">
                          {new Date(cita.fecha).toLocaleString("es-AR", {
                            timeZone: userTimezone,
                            hour: "numeric",
                            minute: "numeric",
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="cita-details-item">
                      <span className="cita-details-item-icon">
                        <i className="bx bx-time"></i>
                      </span>
                      <div className="cita-details-item-text">
                        <p className="text-secondary">Duración</p>
                        <p className="text-primary">55 min</p>
                      </div>
                    </div>

                    <div className="cita-details-item">
                      <span className="cita-details-item-icon">
                        <i className="bx bx-video"></i>
                      </span>
                      <div className="cita-details-item-text">
                        <p className="text-secondary">Tipo de Servicio</p>
                        <p className="text-primary">Video llamada</p>
                      </div>
                    </div>

                    <div className="cita-details-item">
                      <span className="cita-details-item-icon">
                        <i className="bx bx-credit-card-front"></i>
                      </span>

                      <div className="cita-details-item-text">
                        <p className="text-secondary">Precio</p>
                        <p className="text-primary">
                          {Math.floor(cita.precio / 100)}{" "}
                          {cita.divisa.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="cita-btn-container">
                    {(() => {
                      const currentDate = new Date();
                      const citaDate = new Date(cita.fecha);
                      const diffTime = Math.round(
                        (citaDate - currentDate) / 1000 / 60
                      );
                      switch (cita.estado_id) {
                        case 1:
                          return (
                            <>
                              <div className="btn btn-primary disabled">
                                Ingresar a la sesión
                              </div>
                              <Link
                                to={{
                                  pathname: `/mi-espacio/payment/${cita.id}`,
                                }}
                                className="btn btn-soft-primary"
                              >
                                Abonar sesión
                              </Link>
                            </>
                          );
                        case 2:
                          if (diffTime <= 30 && diffTime >= -55) {
                            return (
                              <>
                                <a
                                  href="/sesion"
                                  onClick={handleIngresarLinkClick}
                                  className="btn btn-primary"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Ingresar a la sesión
                                </a>
                                <div
                                  className="btn btn-soft-primary disabled"
                                  id="TooltipReprogramar"
                                  style={{ pointerEvents: "auto" }}
                                >
                                  Reprogramar sesión
                                </div>
                                <Tooltip
                                  placement="bottom"
                                  isOpen={tooltipOpen}
                                  target="TooltipReprogramar"
                                  toggle={toggle}
                                >
                                  Solo puedes reprogramar la sesión con 4 horas
                                  de antelación
                                </Tooltip>
                              </>
                            );
                          } else if (diffTime > 30 && diffTime <= 240) {
                            return (
                              <>
                                <div className="btn btn-primary disabled">
                                  Ingresar a la sesión
                                </div>

                                <div
                                  className="btn btn-soft-primary disabled"
                                  id="TooltipReprogramar"
                                  style={{ pointerEvents: "auto" }}
                                >
                                  Reprogramar sesión
                                </div>
                                <Tooltip
                                  placement="bottom"
                                  isOpen={tooltipOpen}
                                  target="TooltipReprogramar"
                                  toggle={toggle}
                                >
                                  Solo puedes reprogramar la sesión con 4 horas
                                  de antelación
                                </Tooltip>
                              </>
                            );
                          } else if (diffTime > 240) {
                            return (
                              <>
                                <div
                                  className="btn btn-primary disabled"
                                  id="TooltipIngresar"
                                  style={{ pointerEvents: "auto" }}
                                >
                                  Ingresar a la sesión
                                </div>
                                <Tooltip
                                  placement="bottom"
                                  isOpen={tooltipOpen}
                                  target="TooltipIngresar"
                                  toggle={toggle}
                                >
                                  Aún no puedes ingresar a la sesión
                                </Tooltip>
                                <Link
                                  to={{
                                    pathname: `/mi-espacio/especialistas/${cita.psicologo_id}/agendar`,
                                    state: {
                                      reScheduleCitaId: cita.id,
                                    },
                                  }}
                                  className="btn btn-soft-primary"
                                >
                                  Reprogramar sesión
                                </Link>
                              </>
                            );
                          } else if (diffTime < -55) {
                            return (
                              <>
                                <div className="btn btn-primary disabled">
                                  Ingresar a la sesión
                                </div>
                                <Link
                                  to={{
                                    pathname: `/mi-espacio/especialistas/${cita.psicologo_id}/agendar`,
                                    state: {
                                      reScheduleCitaId: cita.id,
                                    },
                                  }}
                                  className="btn btn-soft-primary"
                                >
                                  Reprogramar sesión
                                </Link>
                              </>
                            );
                          }
                        case 3:
                          if (diffTime <= 30 && diffTime >= -55) {
                            return (
                              <>
                                <a
                                  href="/sesion"
                                  onClick={handleIngresarLinkClick}
                                  className="btn btn-primary"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Ingresar a la sesión
                                </a>
                                <div className="btn btn-soft-primary disabled">
                                  Reprogramar sesión
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <Link
                                to={`/mi-espacio/especialistas/${cita.psicologo_id}/agendar`}
                                className="btn btn-primary"
                              >
                                Agendar nueva cita
                              </Link>
                            );
                          }
                        case 4:
                          return (
                            <Link
                              to={`/mi-espacio/especialistas/${cita.psicologo_id}/agendar`}
                              className="btn btn-primary"
                            >
                              Agendar nueva cita
                            </Link>
                          );

                        default:
                          return <small className="text-danger">Error</small>;
                      }
                    })()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {newCitaObj && (
        <ReCompra
          isOpen={modalOpen}
          toggleModal={toggleModal}
          newCitaObj={newCitaObj}
          userTimezone={userTimezone}
        />
      )}
    </>
  );
}

export default Cita;
