import React, { useState, useEffect, useRef } from "react";
import { InputGroup, Input } from "reactstrap";
import PsicologoCard from "./PsicologoCard";
import Select from "react-select";
import autoAnimate from "@formkit/auto-animate";
import axiosInstance from "../../helpers/axiosConfig";
import axios from "axios";

function PsicologosContainer({ psicologos }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGender, setSelectedGender] = useState("todos");
  const [selectedEspecialidades, setSelectedEspecialidades] = useState([]);
  const [preciosInternacionales, setPreciosInternacionales] = useState([]);
  const [isNewUser, setIsNewUser] = useState(false);

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    fetchPreciosInternacionales(res.data.ip);
    fetchUserData();
  };

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get("/user/data");
      // If the user was created 2 days ago or less, it's a new user
      console.log(response.data.body);
      if (
        new Date(response.data.body.created_at) >
          new Date(Date.now() - 2 * 24 * 60 * 60 * 1000) &&
        response.data.body.discount > 0
      ) {
        setIsNewUser(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPreciosInternacionales = async (ip) => {
    try {
      const response = await axiosInstance.get(
        `/ip-location/preciosInternacionales/${ip}`
      );
      setPreciosInternacionales(response.data.body);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Animation
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 200 });
  }, [parent]);

  const especialidadesOptions = [
    { value: "Ansiedad", label: "Ansiedad" },
    { value: "Depresión", label: "Depresión" },
    { value: "Estrés", label: "Estrés" },
    { value: "Autoestima", label: "Autoestima" },
    { value: "Pareja", label: "Pareja" },
    { value: "Ansiedad Social", label: "Ansiedad Social" },
    { value: "Adicciones", label: "Adicciones" },
    { value: "Crianza", label: "Crianza" },
    { value: "Fobias", label: "Fobias" },
    { value: "Ataques de pánico", label: "Ataques de pánico" },
    { value: "Trastornos alimenticios", label: "Trastornos alimenticios" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #6E32FA" : provided.border,
      boxShadow: state.isFocused ? "0 0 0 0.1rem rgb(103 48 236 / 20%)" : null,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#6730ec1a",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#6E32FA",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused || state.isSelected
          ? "#6730ec1a"
          : provided.backgroundColor,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      backgroundColor: "#6730ec0d",
      color: "#6E32FA",
      ":hover": {
        backgroundColor: "#6730ec66",
      },
    }),
  };

  const activePsicologos = psicologos.filter(
    (psicologo) => psicologo.is_active && psicologo.is_public
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e.value);
  };

  const handleEspecialidadesChange = (selectedOptions) => {
    setSelectedEspecialidades(selectedOptions);
  };

  const filteredPsicologos = activePsicologos.filter((psicologo) => {
    const fullName = `${psicologo.nombre} ${psicologo.apellido}`.toLowerCase();
    const nameMatch = fullName.includes(searchTerm.toLowerCase());

    const genderMatch =
      selectedGender === "todos" ||
      psicologo.genero_id.toString() === selectedGender;

    const especialidadesMatch =
      selectedEspecialidades.length === 0 ||
      selectedEspecialidades.some((option) =>
        psicologo.Especialidads.some(
          (especialidad) => especialidad.especialidad === option.value
        )
      );

    return nameMatch && genderMatch && especialidadesMatch;
  });

  return (
    <div className="psicologos-container">
      <div className="cards-container" ref={parent}>
        <div className="title">
          <div className="m-auto w-100">
            <h5>Psicólogos recomendados para ti</h5>
          </div>
          <div className="filters-contaiener">
            <div className="search-bar">
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Buscar por nombre"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <button className="btn btn-primary">Buscar</button>
              </InputGroup>
            </div>
            <div className="especialidades-filter">
              <Select
                isMulti
                options={especialidadesOptions}
                value={selectedEspecialidades}
                onChange={handleEspecialidadesChange}
                placeholder="Especialidades"
                styles={customStyles}
                isSearchable={false}
              />
            </div>
            <div className="gender-filter">
              <Select
                options={[
                  { value: "todos", label: "Todos" },
                  { value: "1", label: "Hombre" },
                  { value: "2", label: "Mujer" },
                ]}
                value={selectedGender}
                onChange={handleGenderChange}
                placeholder="Género"
                styles={customStyles}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        {filteredPsicologos.map((psicologo) => (
          <PsicologoCard
            key={psicologo.id}
            psicologo={psicologo}
            precioObject={preciosInternacionales}
            isNewUser={isNewUser}
          />
        ))}
      </div>
    </div>
  );
}

export default PsicologosContainer;
