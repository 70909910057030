import React, { useEffect, useState } from "react";
import CitaLoader from "../../components/Shared/Loaders/CitaLoader";
import "../../assets/css/cita.css";
import axiosInstance from "../../helpers/axiosConfig";
import { Tooltip } from "reactstrap";

const CitaSummary = ({ eventProps }) => {
  const [cita, setCita] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (eventProps) {
      const citaObj = {
        estado_id: eventProps.citaEstado,
        fecha: eventProps.citaFecha,
        paciente: eventProps.citaPaciente,
        pacienteProfilePic: eventProps.pacienteProfilePic,
        psicologo_authToken: eventProps.citaAuthToken,
      };
      setCita(citaObj);
      setLoading(false);
    }
  }, [eventProps]);

  const completeCita = async () => {
    try {
      await axiosInstance.post("/citas/complete", {
        citaId: eventProps.citaId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleIngresarLinkClick = (event) => {
    event.preventDefault();

    const currentDate = new Date();
    const citaDate = new Date(cita.fecha);
    const diffTime = Math.round((citaDate - currentDate) / 1000 / 60);
    if (diffTime <= 5 && diffTime >= -31 && cita.estado_id === 2) {
      completeCita();
    }

    const url = `/sesion?authToken=${encodeURIComponent(
      cita.psicologo_authToken
    )}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {cita && (
        <div className="cita-section-container pt-0">
          <div className="cita-container mw-100 pt-0">
            {loading ? (
              <CitaLoader />
            ) : (
              <div className="cita-container-items">
                <div className="cita-section">
                  <div className="cita-status justify-content-center">
                    <img
                      src={
                        cita.pacienteProfilePic ||
                        "https://i.imgur.com/yjF35i7.png"
                      }
                      onError={({ target }) => {
                        target.src = "https://i.imgur.com/yjF35i7.png";
                        target.onError = null;
                      }}
                      alt="profile-icon"
                      style={{ borderRadius: "50%" }}
                    />
                    <div className="cita-status-text">
                      <p className="cita-status-text-primary">
                        {(() => {
                          if (cita.paciente) {
                            return cita.paciente;
                          } else {
                            return "Terapia individual";
                          }
                        })()}
                      </p>
                      <p className="cita-status-text-secondary">
                        {(() => {
                          switch (cita.estado_id) {
                            case 1:
                              return (
                                <small className="text-warning">
                                  <i className="bx bx-time"></i> Pendiente de
                                  pago
                                </small>
                              );
                            case 2:
                              return (
                                <small className="text-success">
                                  Confirmada
                                </small>
                              );
                            case 3:
                              return (
                                <small className="text-primary">
                                  Completada
                                </small>
                              );
                            case 4:
                              return (
                                <small className="text-danger">Cancelada</small>
                              );
                            default:
                              return (
                                <small className="text-danger">Error</small>
                              );
                          }
                        })()}
                      </p>
                    </div>
                  </div>
                  <div className="cita-details mb-4">
                    <div className="cita-details-item">
                      <span className="cita-details-item-icon">
                        <i className="bx bx-calendar"></i>
                      </span>
                      <div className="cita-details-item-text">
                        <p className="text-secondary">
                          {new Date(cita.fecha).toLocaleString("es-AR", {
                            dateStyle: "medium",
                            timeZone: userTimezone,
                          })}
                        </p>
                        <p className="text-primary">
                          {new Date(cita.fecha).toLocaleString("es-AR", {
                            timeZone: userTimezone,
                            hour: "numeric",
                            minute: "numeric",
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="cita-details-item">
                      <span className="cita-details-item-icon">
                        <i className="bx bx-time"></i>
                      </span>
                      <div className="cita-details-item-text">
                        <p className="text-secondary">Duración</p>
                        <p className="text-primary">55 min</p>
                      </div>
                    </div>
                  </div>
                  <div className="cita-btn-container">
                    {(() => {
                      const currentDate = new Date();
                      const citaDate = new Date(cita.fecha);
                      const diffTime = Math.round(
                        (citaDate - currentDate) / 1000 / 60
                      );
                      if (
                        diffTime <= 5 &&
                        diffTime >= -55 &&
                        (cita.estado_id === 2 || cita.estado_id === 3)
                      ) {
                        return (
                          <>
                            <a
                              href="/sesion"
                              onClick={handleIngresarLinkClick}
                              className="btn btn-primary"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ingresar a la sesión
                            </a>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div
                              className="btn btn-primary disabled"
                              id="TooltipIngresar"
                              style={{ pointerEvents: "auto" }}
                            >
                              Ingresar a la sesión
                            </div>
                            <Tooltip
                              placement="bottom"
                              isOpen={tooltipOpen}
                              target="TooltipIngresar"
                              toggle={toggle}
                            >
                              Aún no puedes ingresar a la sesión
                            </Tooltip>
                          </>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CitaSummary;
