import React, { useState } from "react";

import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";

import { Spinner } from "reactstrap";

import { useClient } from "../../helpers/streamUseClient.js";
import { Streami18n } from "stream-chat-react";

import "stream-chat-react/dist/css/v2/index.css";
import "../../assets/css/chat.css";

const ChatComponent = ({ apiKey, user, userToken, sort, filters, options }) => {
  const [isChannelListVisible, setIsChannelListVisible] = useState(false);

  const chatClient = useClient({
    apiKey,
    user,
    tokenOrProvider: userToken,
  });

  if (!chatClient) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner size={"lg"} color="primary" />
      </div>
    );
  }

  const i18nInstance = new Streami18n({
    language: "es",
    translationsForLanguage: {
      "You have no channels currently":
        "Debes tener al menos una cita para chatear",
    },
  });

  const toggleChannelList = () => {
    setIsChannelListVisible(!isChannelListVisible);
  };

  return (
    <Chat
      client={chatClient}
      theme="str-chat__theme-light"
      i18nInstance={i18nInstance}
    >
      {" "}
      <div
        className="toggle-channel-list-container"
        onClick={toggleChannelList}
      >
        <i
          className={`bx bx-menu-alt-left toggle-channel-list-icon ${
            isChannelListVisible ? "active" : ""
          }`}
        ></i>
        <p className="m-0">
          {isChannelListVisible ? "Ocultar" : "Mostrar"} chats
        </p>
      </div>
      <div
        className={`chat-container ${
          isChannelListVisible ? "new-grid-template" : ""
        }`}
      >
        <div
          className={`channel-list-wrapper ${
            isChannelListVisible ? "visible" : ""
          }`}
        >
          <ChannelList filters={filters} sort={sort} options={options} />
        </div>
        <Channel>
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </div>
    </Chat>
  );
};

export default ChatComponent;
