import React, { useEffect, useState } from "react";
import "../../assets/css/misHorarios.css";
import {
  Row,
  Col,
  Label,
  Input,
  Form,
  Alert,
  Button,
  Spinner,
} from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import MisHorariosLoader from "../../components/Shared/Loaders/MisHorariosLoader";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const MisHorarios = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [desdeValue, setDesdeValue] = useState("");
  const [hastaValue, setHastaValue] = useState("");
  const [hastaOptions, setHastaOptions] = useState([]);
  const [availabilitySummary, setAvailabilitySummary] = useState("");
  const [desdeChanged, setDesdeChanged] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [saveIsDisabled, setsaveIsDisabled] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [psicologoId, setPsicologoId] = useState(null);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    changeAvailabilitySummary();
  }, [selectedDays, desdeValue, hastaValue]);

  useEffect(() => {
    fetchPsicologoAvaiability();
  }, []);

  const handleDayClick = async (day) => {
    setsaveIsDisabled(false);
    if (selectedDays.includes(day)) {
      setSelectedDays(
        await selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  async function fetchPsicologoAvaiability() {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("/psicologos/disponibilidad");
      if (response.status === 200) {
        const localeDesdeValue = await transformToLocalTime(
          response.data.body.availableFromTime
        );
        const localeHastaValue = await transformToLocalTime(
          response.data.body.availableToTime
        );
        setDesdeValue(localeDesdeValue);
        setHastaValue(localeHastaValue);
        setHastaOptions(getHastaOptions(localeHastaValue));
        let fetchedSelectedDays = [];
        if (response.data.body.availableDays !== "") {
          fetchedSelectedDays = response.data.body.availableDays.split(",");
        }
        setSelectedDays(fetchedSelectedDays);
        const psicologoDataResponse = await axiosInstance.get("/user/data");
        if (psicologoDataResponse.status === 200) {
          setIsPublic(psicologoDataResponse.data.body.is_public);
          setPsicologoId(psicologoDataResponse.data.body.id);
        }
      } else {
        setError("Error al obtener la disponibilidad");
      }
    } catch (error) {
      setError("Error al obtener la disponibilidad");
    }
    setIsLoading(false);
  }

  const changeAvailabilitySummary = () => {
    if (selectedDays.length === 0) {
      setAvailabilitySummary("Disponibilidad: Ningun dia seleccionado");
    } else if (selectedDays.length === 7) {
      setAvailabilitySummary(
        `Disponibilidad todos los días de ${desdeValue} a ${hastaValue}`
      );
    } else {
      setAvailabilitySummary(
        `Disponibilidad: ${selectedDays.join(
          ", "
        )} de ${desdeValue} a ${hastaValue}`
      );
    }
  };

  const isDaySelected = (day) => {
    return selectedDays.includes(day);
  };

  const getHastaOptions = (selectedDesde) => {
    const desdeHour = parseInt(selectedDesde.split(":")[0]);
    const options = [];
    for (let i = desdeHour; i <= 23; i++) {
      options.push(`${i}:00`);
    }
    return options;
  };

  const handleDesdeChange = (event) => {
    setsaveIsDisabled(false);
    const selectedDesde = event.target.value;
    setDesdeValue(selectedDesde);
    setHastaOptions(getHastaOptions(selectedDesde));
    setDesdeChanged(true);
  };

  const handleHastaChange = (event) => {
    setsaveIsDisabled(false);
    const selectedHasta = event.target.value;
    setHastaValue(selectedHasta);
  };

  const transformToLocalTime = async (utcTimeString) => {
    const [hours, minutes] = utcTimeString.split(":");
    const utcDate = new Date();
    utcDate.setUTCHours(hours);
    utcDate.setUTCMinutes(minutes);

    const userDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: userTimezone })
    );
    const userTimeString = userDate.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });

    return userTimeString;
  };

  const updateAvailability = async () => {
    setUpdateLoading(true);
    try {
      const currentDate = new Date();

      // Split the input time strings into hours and minutes
      const [desdeHours, desdeMinutes] = desdeValue.split(":");
      const [hastaHours, hastaMinutes] = hastaValue.split(":");

      if (desdeHours > hastaHours) {
        setError("La hora 'desde' debe ser menor a la hora 'hasta'");
        setUpdateLoading(false);
        return;
      }

      // Set the hours and minutes of the Date objects
      currentDate.setHours(desdeHours);
      currentDate.setMinutes(desdeMinutes);
      const desdeUTCString = currentDate.toISOString().slice(11, 16); // Get the UTC time string from the Date object

      currentDate.setHours(hastaHours);
      currentDate.setMinutes(hastaMinutes);
      const hastaUTCString = currentDate.toISOString().slice(11, 16); // Get the UTC time string from the Date object

      const response = await axiosInstance.patch("/psicologos/disponibilidad", {
        availableDays: selectedDays.join(","),
        availableFromTime: desdeUTCString,
        availableToTime: hastaUTCString,
      });

      const responsePsicologoActive = await axiosInstance.patch(
        `/psicologos/${psicologoId}`,
        {
          is_public: isPublic,
        }
      );
      setUpdateLoading(false);
      if (response.status === 200 && responsePsicologoActive.status === 200) {
        setsaveIsDisabled(true);
        setError("Disponibilidad actualizada correctamente");
      } else {
        setError("Error al actualizar la disponibilidad");
      }
    } catch (error) {
      setError("Error al actualizar la disponibilidad");
    }
  };

  return (
    <>
      <Helmet>
        <title>Mindly | Mis Horarios</title>
        <meta name="description" content="Mis Horarios" />
      </Helmet>
      <div className="misHorarios-section">
        <div className="misHorarios-container">
          <nav className="nav nav-pills flex-column flex-sm-row">
            <Link
              to="/mi-espacio/mis-horarios"
              className="flex-sm-fill text-sm-center nav-link active tab-switcher"
              aria-current="page"
            >
              Horarios
            </Link>
            <Link
              to="/mi-espacio/mis-horarios/descansos"
              className="flex-sm-fill text-sm-center nav-link tab-switcher"
            >
              Descansos
            </Link>
          </nav>
          {error && (
            <Alert className="bg-soft-primary fw-medium mt-2 mb-0">
              <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
              {error}
            </Alert>
          )}
          {isLoading ? (
            <MisHorariosLoader className="mb-5" />
          ) : (
            <div className="misHorarios-form-container">
              <div className="form-check form-switch d-flex align-items-center justify-content-center mt-0 mb-3">
                <Input
                  className="form-check-input my-0"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={!isPublic}
                  value={!isPublic}
                  onClick={() => {
                    setIsPublic(!isPublic);
                    setsaveIsDisabled(false);
                  }}
                ></Input>
                <Label
                  className="form-check-label my-0"
                  htmlFor="flexSwitchCheckDefault"
                  style={{ margin: "1rem" }}
                >
                  Desactivar agenda
                </Label>
              </div>
              {isPublic ? (
                <>
                  {" "}
                  <Form>
                    <Row>
                      <Col md="6">
                        <Label className="misHorarios-label">Desde: </Label>
                        <Input
                          type="select"
                          className="misHorarios-input"
                          value={desdeValue}
                          onChange={handleDesdeChange}
                          aria-label="desde select input"
                        >
                          <option value={desdeValue}>{desdeValue}</option>
                          {Array.from({ length: 18 }, (_, i) => i + 5).map(
                            (hour) =>
                              hour < 10 ? (
                                <option key={hour} value={`0${hour}:00`}>
                                  {`0${hour}:00`}
                                </option>
                              ) : (
                                <option key={hour} value={`${hour}:00`}>
                                  {`${hour}:00`}
                                </option>
                              )
                          )}
                        </Input>
                      </Col>
                      <Col md="6">
                        <Label className="misHorarios-label">Hasta: </Label>
                        <Input
                          type="select"
                          className="misHorarios-input"
                          onChange={handleHastaChange}
                          aria-label="hasta select input"
                        >
                          {!desdeChanged && (
                            <option value={hastaValue}>{hastaValue}</option>
                          )}

                          {hastaOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </Form>
                  <div className="misHorarios-days-container">
                    <div className="misHorarios-days">
                      <div
                        className={`misHorarios-day ${
                          isDaySelected("lun") ? "active" : ""
                        }`}
                        onClick={() => {
                          handleDayClick("lun");
                        }}
                      >
                        <p>L</p>
                      </div>
                      <div
                        className={`misHorarios-day ${
                          isDaySelected("mar") ? "active" : ""
                        }`}
                        onClick={() => handleDayClick("mar")}
                      >
                        <p>Mar</p>
                      </div>
                      <div
                        className={`misHorarios-day ${
                          isDaySelected("mié") ? "active" : ""
                        }`}
                        onClick={() => handleDayClick("mié")}
                      >
                        <p>Mi</p>
                      </div>
                      <div
                        className={`misHorarios-day ${
                          isDaySelected("jue") ? "active" : ""
                        }`}
                        onClick={() => handleDayClick("jue")}
                      >
                        <p>J</p>
                      </div>
                      <div
                        className={`misHorarios-day ${
                          isDaySelected("vie") ? "active" : ""
                        }`}
                        onClick={() => handleDayClick("vie")}
                      >
                        <p>V</p>
                      </div>
                      <div
                        className={`misHorarios-day ${
                          isDaySelected("sáb") ? "active" : ""
                        }`}
                        onClick={() => handleDayClick("sáb")}
                      >
                        <p>S</p>
                      </div>
                      <div
                        className={`misHorarios-day ${
                          isDaySelected("dom") ? "active" : ""
                        }`}
                        onClick={() => handleDayClick("dom")}
                      >
                        <p>D</p>
                      </div>
                    </div>
                  </div>
                  <div className="misHorarios-summary-container">
                    <div className="misHorarios-summary-text">
                      <p>{availabilitySummary}</p>
                    </div>
                  </div>
                </>
              ) : (
                <p className="center text-secondary mb-4">
                  Activá tu agendar para poder recibir pacientes
                </p>
              )}
              <div className="misHorarios-button-container">
                <Button
                  className="misHorarios-button btn btn-primary"
                  onClick={updateAvailability}
                  disabled={saveIsDisabled}
                >
                  {updateLoading && <Spinner className="me-2" size="sm" />}
                  Guardar Cambios
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MisHorarios;
