import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/mitomania/sander-sammy-q7ZlbWbDnYo-unsplash-min.jpg";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>¿Qué es la Mitomanía? Síntomas, Causas y Tratamiento</title>
          <meta
            name="description"
            content="La mitomanía es un trastorno psicológico que afecta a muchas personas en todo el mundo. Descubre sus síntomas, causas y tratamiento. ¡Aprende más!"
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img src={standard} className="img-fluid" alt="" />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Síntomas, Causas y Tratamiento
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        ¿Qué es la Mitomanía? Síntomas, Causas y Tratamiento
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 1 de
                          Octubre, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="mt-3">
                      La mitomanía es un <strong>trastorno psicológico</strong>{" "}
                      que afecta a muchas personas en todo el mundo. En este
                      artículo, exploraremos en detalle{" "}
                      <strong>qué es la mitomanía</strong>, cuáles son{" "}
                      <strong>sus síntomas</strong>, qué la causa y{" "}
                      <strong>cómo se puede tratar</strong> esta condición.
                      Acompáñanos en este viaje para comprender mejor la
                      mitomanía y cómo afecta a quienes la padecen.
                    </p>
                    {/* <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote> */}
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Qué es la mitomanía?
                    </h2>
                    <p>
                      La mitomanía, también conocida como pseudología
                      fantástica, es un trastorno psicológico caracterizado por
                      la tendencia compulsiva a{" "}
                      <strong className="text-primary">
                        mentir de manera recurrente
                      </strong>{" "}
                      y sin un motivo aparente. Las personas que sufren de
                      mitomanía a menudo tejen historias elaboradas y
                      fantasiosas que pueden ser difíciles de distinguir de la
                      realidad. Esta conducta compulsiva de mentir puede afectar
                      significativamente la vida cotidiana de quienes la
                      padecen.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Síntomas de la mitomanía
                    </h2>
                    <p>
                      Identificar la mitomanía puede ser complicado, ya que las
                      personas que la padecen a menudo son hábiles en la
                      creación de sus historias. Sin embargo, existen algunos
                      síntomas comunes que pueden ayudar a reconocer este
                      trastorno:
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Síntomas principales:
                    </h2>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Mentiras constantes:
                    </h3>
                    <p>
                      Las personas con mitomanía tienden a mentir de forma
                      habitual, incluso cuando no hay una razón evidente para
                      hacerlo.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Historias inverosímiles:
                    </h3>
                    <p>
                      Sus relatos suelen ser extremadamente fantasiosos y
                      difíciles de creer.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Sentimiento de necesidad:
                    </h3>
                    <p>
                      Sienten una compulsión incontrolable de mentir, como si no
                      pudieran evitarlo.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Falta de remordimiento:
                    </h3>
                    <p>
                      A menudo, no muestran arrepentimiento por sus mentiras,
                      incluso cuando son descubiertas.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Síntomas secundarios:
                    </h2>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Problemas en las relaciones:
                    </h3>
                    <p>
                      La mitomanía puede socavar las relaciones personales y
                      laborales debido a la desconfianza que genera.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Aislamiento social:
                    </h3>
                    <p>
                      Quienes la padecen pueden retirarse de la sociedad debido
                      a las consecuencias de sus mentiras.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Depresión y ansiedad:
                    </h3>
                    <p>
                      La mitomanía puede llevar a problemas de salud mental,
                      como la depresión y la ansiedad.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Causas de la mitomanía
                    </h2>
                    <p>
                      Las causas exactas de la mitomanía no se comprenden
                      completamente, pero se cree que pueden estar relacionadas
                      con factores psicológicos y emocionales. Algunos posibles
                      desencadenantes incluyen:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      Factores psicológicos:
                    </h3>
                    <p>
                      <strong>1. Baja autoestima:</strong> Las personas con baja
                      autoestima pueden mentir para impresionar a los demás.
                    </p>
                    <p>
                      <strong>2. Necesidad de atención:</strong> Buscan la
                      aprobación y la atención de los demás a través de sus
                      mentiras.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      Factores emocionales:
                    </h3>
                    <p>
                      <strong>1. Trauma infantil:</strong> Experiencias
                      traumáticas en la infancia pueden contribuir a la
                      mitomanía.
                    </p>
                    <p>
                      <strong>2. Falta de empatía:</strong> La incapacidad para
                      comprender cómo afectan sus mentiras a los demás puede ser
                      un factor.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Tratamiento de la mitomanía
                    </h2>
                    <p>
                      La mitomanía es un trastorno que{" "}
                      <strong>puede tratarse</strong> con la ayuda adecuada. El
                      tratamiento suele incluir terapia psicológica y, en
                      algunos casos, <strong>medicación.</strong> Aquí hay
                      algunas opciones de tratamiento:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      Terapia cognitivo-conductual:
                    </h3>
                    <p>
                      La terapia CBT se centra en identificar y cambiar los
                      patrones de pensamiento y comportamiento relacionados con
                      la mentira compulsiva.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      Terapia de grupo:
                    </h3>
                    <p>
                      Participar en grupos de apoyo puede proporcionar un
                      entorno seguro para compartir experiencias y estrategias
                      para superar la mitomanía.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      Medicamentos:
                    </h3>
                    <p>
                      En casos graves, los médicos pueden recetar medicamentos
                      para tratar síntomas relacionados, como la ansiedad o la
                      depresión.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Conclusión
                    </h2>
                    <p>
                      La mitomanía es un trastorno que afecta a muchas personas
                      en todo el mundo. Sus síntomas pueden ser perjudiciales
                      para las relaciones personales y la salud mental. <br />{" "}
                      Sin embargo, con el tratamiento adecuado, es posible
                      llevar una vida más saludable y libre de mentiras
                      compulsivas.
                      <br />
                      Recuerda, si tú o alguien que conoces está lidiando con la
                      mitomanía, ¡no estás solo! Buscar ayuda profesional es el
                      primer paso hacia la recuperación.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Preguntas Frecuentes (FAQs)
                    </h2>
                    <p>
                      <strong>
                        1. ¿Qué diferencia a la mitomanía de una simple mentira
                        ocasional?
                      </strong>
                    </p>
                    <p className="text-muted">
                      La mitomanía implica una compulsión incontrolable de
                      mentir de manera recurrente, mientras que una mentira
                      ocasional es un acto aislado.
                    </p>
                    <p>
                      <strong>
                        2. ¿Puede la mitomanía ser tratada con éxito?
                      </strong>
                    </p>
                    <p className="text-muted">
                      Sí, la mitomanía puede tratarse con terapia y apoyo
                      adecuados, y muchas personas han logrado superarla.
                    </p>
                    <p>
                      <strong>3. ¿La mitomanía es hereditaria?</strong>
                    </p>
                    <p className="text-muted">
                      No hay evidencia concluyente de que la mitomanía sea
                      hereditaria, pero puede haber una predisposición genética.
                    </p>
                    <p>
                      <strong>
                        4. ¿Qué profesionales pueden ayudar en el tratamiento de
                        la mitomanía?
                      </strong>
                    </p>
                    <p className="text-muted">
                      Psicólogos, psiquiatras y terapeutas especializados en
                      trastornos de la personalidad pueden ofrecer ayuda.
                    </p>
                    <p>
                      <strong>
                        5. ¿Cómo puedo ayudar a alguien que creo que tiene
                        mitomanía?
                      </strong>
                    </p>
                    <p className="text-muted">
                      Lo mejor que puedes hacer es alentar a la persona a buscar
                      ayuda profesional y ofrecer tu apoyo emocional durante el
                      proceso de tratamiento.
                    </p>

                    <p>
                      Si conoces a alguien que este sufriendo debido a la
                      Mitomanía, en{" "}
                      <a
                        href="https://www.mindly.la/home"
                        className="text-primary"
                      >
                        Mindly
                      </a>
                      , tenemos a un grupo de psicólogos expertos en salud
                      mental listos para ofrecer el servicio de mayor calidad.
                      ¡Agenda tu sesión hoy mismo! <br></br> <br></br> La{" "}
                      <strong>primera sesión</strong> cuenta con un
                      <strong> 50% OFF</strong>.{" "}
                    </p>

                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>98
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
