import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
//Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axiosInstance from "../../helpers/axiosConfig";
import { Helmet } from "react-helmet";
//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import recoveryimg from "../../assets/images/user/recovery.svg";
import logo from "../../assets/images/logo-light.png";

function PageChangePassword() {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState(null);

  const location = useLocation();

  useEffect(() => {
    getUserToken();
  }, []);

  const getUserToken = () => {
    if (location.search) {
      const token = location.search.split("=")[1];
      setUserToken(token);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Ingrese una contraseña")
        .min(8, "La contraseña debe tener al menos 8 caracteres"),
      password_confirmation: Yup.string()
        .required("Ingrese una contraseña")
        .min(8, "La contraseña debe tener al menos 8 caracteres")
        .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      if (values.password !== values.password_confirmation) {
        setMessage("Las contraseñas no coinciden");
        return;
      }
      try {
        const response = await axiosInstance.post("/auth/change-password", {
          token: userToken,
          newPassword: values.password,
        });
        if (response.status === 200) {
          setMessage("Contraseña cambiada correctamente");
        }
      } catch (error) {
        console.log(error);
        setMessage("Error al cambiar la contraseña");
      }
      setIsLoading(false);
    },
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>Mindly | Recuperar contraseña</title>
        <meta name="description" content="Recuperar contraseña" />
      </Helmet>
      {message && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {message}
        </Alert>
      )}
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-login d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img
                  src={recoveryimg}
                  className="img-fluid d-block mx-auto auth-svg"
                  alt=""
                />
              </div>
            </Col>
            <Col className="auth-form" lg={5} md={6}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <div className="card-title text-center">
                    <img className="auth-logo m-auto" src={logo}></img>
                    <h4 className="card-title text-center">
                      Recuperar contraseña
                    </h4>
                  </div>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col lg={12}>
                        <p className="text-muted">
                          {" "}
                          Genera una nueva contraseña.{" "}
                        </p>
                        <div className="mb-3">
                          <Label className="form-label" for="password">
                            Contraseña <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="key"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="password"
                            className="form-control ps-5"
                            name="password"
                            id="password"
                            placeholder="Ingrese una nueva contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            for="password_confirmation"
                          >
                            Repita la contraseña{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="key"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="password"
                            className="form-control ps-5"
                            name="password_confirmation"
                            id="password_confirmation"
                            placeholder="Repita la nueva contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.password_confirmation || ""
                            }
                            invalid={
                              validation.touched.password_confirmation &&
                              validation.errors.password_confirmation
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password_confirmation &&
                          validation.errors.password_confirmation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password_confirmation}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-grid">
                          <Button
                            color="primary"
                            className="d-flex justify-content-center align-items-center "
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="mx-2"></Spinner>
                            ) : (
                              <i className="bx bx-calendar-plus"></i>
                            )}
                            Recuperar contraseña
                          </Button>
                        </div>
                      </Col>
                      <div className="mx-auto">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            ¿Recordaste tu contraseña?
                          </small>{" "}
                          <Link to="/login" className="text-dark fw-bold">
                            Iniciar sesion
                          </Link>
                        </p>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default PageChangePassword;
