import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/emociones1/tengyart-DoqtEEn8SOo-unsplash-min.jpg";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Tipos de emociones: Descubre los Secretos para Gestionarlas
          </title>
          <meta
            name="description"
            content="Explora cómo manejar tus emociones y vive mejor. Consejos para comprender tus sentimientos. ¡Optimiza tu bienestar emocional hoy!"
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img src={standard} className="img-fluid" alt="" />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Definición, importancia y cómo gestionarlas
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        Tipos de emociones: Descubre los Secretos para
                        Gestionarlas
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 20 de
                          Agosto, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="mt-3">
                      Las emociones son una parte fundamental de nuestra vida
                      cotidiana. Nos acompañan en cada paso que damos,
                      influyendo en nuestra toma de decisiones, nuestras
                      relaciones interpersonales y nuestra salud mental. En este
                      artículo, exploraremos los diferentes{" "}
                      <strong> tipos de emociones </strong>
                      que experimentamos como seres humanos y aprenderemos{" "}
                      <strong>cómo gestionarlas </strong> de manera más
                      efectiva.
                    </p>
                    {/* <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote> */}
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Qué son las emociones?
                    </h2>
                    <p>
                      Las emociones son respuestas psicofisiológicas que
                      experimentamos ante diversos estímulos y situaciones. Son
                      una parte integral de nuestra experiencia humana y pueden
                      variar ampliamente en intensidad y duración. A
                      continuación, desglosaremos algunos de los tipos de
                      emociones más comunes:
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Emociones Primarias:
                    </h2>
                    <p>
                      Las emociones primarias son las{" "}
                      <strong className="text-primary">
                        {" "}
                        respuestas emocionales innatas
                      </strong>{" "}
                      que todos los seres humanos experimentan desde el
                      nacimiento. Estas incluyen:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Felicidad
                    </h3>
                    <p>
                      La felicidad es una emoción positiva que sentimos cuando
                      experimentamos placer, alegría o satisfacción. Gestionarla
                      implica buscar actividades y relaciones que nos hagan
                      sentir felices.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Tristeza
                    </h3>
                    <p>
                      La tristeza es una emoción que experimentamos en momentos
                      de pérdida, dolor o desilusión. Para gestionarla de manera
                      efectiva, es importante permitirse sentir y expresar la
                      tristeza de manera saludable.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Miedo
                    </h3>
                    <p>
                      El miedo es una emoción que surge en situaciones de
                      amenaza o peligro. Aprender a enfrentar nuestros miedos y
                      tomar medidas para protegernos es crucial para su gestión.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Ira
                    </h3>
                    <p>
                      La ira es una emoción intensa que surge cuando nos
                      sentimos frustrados, amenazados o injustamente tratados.
                      Canalizarla de manera constructiva es esencial.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Emociones Secundarias:
                    </h2>
                    <p>
                      Estas emociones son{" "}
                      <strong className="text-primary">más complejas</strong> y
                      pueden surgir a partir de las emociones primarias. Algunos
                      ejemplos incluyen:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      5. Envidia
                    </h3>
                    <p>
                      La envidia puede derivar de la tristeza o la
                      insatisfacción. Gestionarla implica reconocerla y trabajar
                      en la autoaceptación y la gratitud.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      6. Culpa
                    </h3>
                    <p>
                      La culpa a menudo surge de la tristeza o la ira. Para
                      gestionarla, es importante reconocer nuestras acciones y
                      tomar medidas para enmendar cualquier error.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Emociones Sociales:
                    </h2>
                    <p>
                      Las emociones sociales surgen en nuestras interacciones
                      con los demás. Algunos ejemplos son:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      7. Vergüenza
                    </h3>
                    <p>
                      La vergüenza puede surgir en situaciones sociales
                      embarazosas. Para gestionarla, es esencial recordar que
                      todos cometemos errores.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      8. Empatía
                    </h3>
                    <p>
                      La empatía es la capacidad de entender y compartir las
                      emociones de los demás. Practicar la empatía mejora
                      nuestras relaciones interpersonales.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Cómo gestionar nuestras emociones?
                    </h2>
                    <p>
                      Gestionar nuestras emociones de manera efectiva es
                      fundamental para nuestro bienestar emocional. Aquí hay
                      algunas estrategias que pueden ayudarte:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Reconoce tus emociones
                    </h3>
                    <p>
                      El primer paso para gestionar tus emociones es
                      reconocerlas. Tómate un momento para identificar lo que
                      estás sintiendo y por qué lo sientes.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Valida tus emociones
                    </h3>
                    <p>
                      No juzgues tus emociones como buenas o malas. Todas son
                      válidas y tienen un propósito.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Comunica tus emociones
                    </h3>
                    <p>
                      Hablar sobre tus emociones con amigos o familiares puede
                      ser terapéutico y fortalecer tus relaciones.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Practica la autorregulación emocional
                    </h3>
                    <p>
                      Aprende a controlar tus emociones sin reprimirlas. Esto
                      incluye la práctica de la meditación y la respiración
                      profunda.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      5. Busca apoyo profesional
                    </h3>
                    <p>
                      Si sientes que tus emociones son abrumadoras, considera
                      buscar la ayuda de un terapeuta o consejero.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Conclusión
                    </h2>
                    <p>
                      Las emociones son una parte esencial de nuestra
                      experiencia humana. Reconocer, validar y gestionar
                      nuestras emociones de manera efectiva nos permite vivir
                      una vida más equilibrada y saludable. No hay emociones
                      "malas", solo emociones que requieren comprensión y
                      gestión adecuada.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Preguntas Frecuentes
                    </h2>
                    <p>
                      <strong>1. ¿Qué son las emociones primarias?</strong>
                    </p>
                    <p className="text-muted">
                      Las emociones primarias son respuestas emocionales innatas
                      que todos los seres humanos experimentan desde el
                      nacimiento, como la felicidad, la tristeza, el miedo y la
                      ira.
                    </p>
                    <p>
                      <strong>
                        2. ¿Cómo puedo gestionar la envidia de manera saludable?{" "}
                      </strong>
                    </p>
                    <p className="text-muted">
                      Gestionar la envidia implica reconocerla y trabajar en la
                      autoaceptación y la gratitud.
                    </p>
                    <p>
                      <strong>
                        3. ¿Por qué es importante practicar la empatía?
                      </strong>
                    </p>
                    <p className="text-muted">
                      La empatía mejora nuestras relaciones interpersonales al
                      permitirnos entender y compartir las emociones de los
                      demás.
                    </p>
                    <p>
                      <strong>
                        4. ¿Cuál es el primer paso para gestionar nuestras
                        emociones?
                      </strong>
                    </p>
                    <p className="text-muted">
                      El primer paso es reconocer y validar nuestras emociones,
                      sin juzgarlas como buenas o malas.
                    </p>
                    <p>
                      <strong>
                        5. ¿Cuándo debería buscar apoyo profesional para
                        gestionar mis emociones?{" "}
                      </strong>
                    </p>
                    <p className="text-muted">
                      Deberías considerar buscar apoyo profesional si sientes
                      que tus emociones son abrumadoras y afectan negativamente
                      tu calidad de vida.
                    </p>

                    <p>
                      ¡Recuerda que gestionar tus emociones es un proceso
                      continuo que puede mejorar tu bienestar general!
                    </p>
                    <p>
                      En{" "}
                      <a
                        href="https://www.mindly.la/home"
                        className="text-primary"
                      >
                        Mindly
                      </a>
                      , tenemos a un grupo de expertos en salud mental listos
                      para ofrecerte el servicio de mayor calidad. ¡Agenda tu
                      sesión hoy mismo! <br></br> <br></br> La{" "}
                      <strong>primera sesión</strong> cuenta con un
                      <strong> 50% OFF</strong>.{" "}
                    </p>

                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>115
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
