import React, { useEffect, useState } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import axiosInstance from "../../helpers/axiosConfig.js";

export default function Component() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1040);
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/user/data");
        if (response.status === 200) {
          setUser(response.data.body);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (user.id) {
      if (isMobile) {
        Intercom({
          app_id: "vjx94t7n",
          custom_launcher_selector: ".intercom-launcher",
          hide_default_launcher: true,
          user_id: user.id,
          name: user.nombre,
          email: user.email,
        });
      } else {
        Intercom({
          app_id: "vjx94t7n",
          user_id: user.id,
          name: user.nombre,
          email: user.email,
        });
      }
    }
  }, [user, isMobile]);

  const toggleIntercom = () => {
    Intercom("show");
  };

  return (
    <>
      {isMobile && (
        <button
          className="intercom-launcher d-flex align-items-center justify-content-center"
          onClick={toggleIntercom}
        >
          <i
            className="bx bx-info-circle"
            style={{ fontSize: "1.8rem", color: "#21004B", margin: "1.8rem" }}
          ></i>
          {/* <p className="m-0 text-decoration-underline">¿Preguntas?</p> */}
        </button>
      )}
    </>
  );
}
