// React Basic and Bootstrap
import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Card,
  CardBody,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Helmet } from "react-helmet";
import "../../assets/css/login.css";
import autoAnimate from "@formkit/auto-animate";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import loginImg from "../../assets/images/user/login.svg";
import logo from "../../assets/images/logo-light.png";

const PageLogin = () => {
  const [error, setError] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  //Animations
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 200 });
  }, [parent]);
  const parent2 = useRef(null);
  useEffect(() => {
    parent2.current && autoAnimate(parent2.current, { duration: 200 });
  }, [parent2]);

  useEffect(() => {
    if (localStorage.getItem("role") === "psicologo") {
      setRedirectTo("/mi-espacio/agenda");
    } else if (localStorage.getItem("role") === "admin") {
      setRedirectTo("/admin/pagos");
    } else if (localStorage.getItem("role") === "paciente") {
      setRedirectTo("/mi-espacio");
    }
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string("Ingresa tu email")
        .email("Ingresa un email valido")
        .required("El email es requerido"),
      password: Yup.string()
        .min(8, "Tu contraseña debe tener al menos 8 caracteres")
        .required("Ingrese su contraseña"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      values.email = values.email.toLowerCase();
      const { email, password } = values;
      const expiresAt = new Date().getTime() + 1000 * 60 * 60 * 11;
      try {
        const response = await axiosInstance.post("/auth/login", {
          email,
          password,
        });
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("expiresAt", expiresAt);
        localStorage.setItem("role", response.data.user.role);
        switch (response.data.user.role) {
          case "paciente":
            setRedirectTo("/mi-espacio");
            break;
          case "psicologo":
            setRedirectTo("/mi-espacio/agenda");
            break;
          case "admin":
            setRedirectTo("/admin/pagos");
            break;
        }
      } catch (error) {
        setError("Usuario o contraseña incorrectos");
      }
      setIsLoading(false);
    },
  });

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  function handleFacebookClick() {
    setIsFacebookLoading(true);
  }

  function handleGoogleClick() {
    setIsGoogleLoading(true);
  }

  return (
    <div className="login-section">
      <Helmet>
        <title>Mindly | Iniciar sesión</title>
        <meta name="description" content="Iniciar sesión" />
      </Helmet>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-login d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img
                  src={loginImg}
                  className="img-fluid d-block mx-auto auth-svg"
                  alt="login vector"
                />
              </div>
            </Col>
            <Col className="auth-form-login" lg={5} md={6}>
              <Card className="login-page bg-white shadow rounded border-0">
                <CardBody>
                  <div className="card-title text-center">
                    <a href="/home">
                      <img
                        className="auth-logo mx-auto"
                        src={logo}
                        alt="harmony logo"
                      ></img>
                    </a>
                    <h4 className="mb-4">Iniciar sesión</h4>
                  </div>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3" ref={parent2}>
                          <Label className="form-label" htmlFor="email">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control ps-5"
                            name="email"
                            id="email"
                            placeholder="Email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3" ref={parent}>
                          <Label className="form-label" htmlFor="password">
                            Contraseña <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="key"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="password"
                            className="form-control ps-5"
                            name="password"
                            id="password"
                            placeholder="Contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="d-flex justify-content-between">
                          <div className="mb-3"></div>
                          <p className="forgot-pass mb-4">
                            <Link to="/recovery" className="text-dark fw-bold">
                              ¿Olvidaste tu contraseña?
                            </Link>
                          </p>
                        </div>
                      </Col>
                      <Col lg={12} className="mb-0">
                        <div className="d-grid">
                          <Button
                            to="/mi-espacio"
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            type="submit"
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="mx-2"></Spinner>
                            ) : (
                              <i className="bx bx-calendar-plus"></i>
                            )}
                            <p className="m-0">Ingresar</p>
                          </Button>
                        </div>
                      </Col>
                      <Col lg={12} className="mt-4 text-center">
                        <h6>O continúa con</h6>
                        <Row>
                          {/* <div className="col-6 mt-3">
                            <div className="d-grid">
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/auth/facebook`}
                                className="btn btn-light d-flex align-items-center justify-content-center"
                                onClick={handleFacebookClick}
                              >
                                {isFacebookLoading ? (
                                  <Spinner size="sm" className="mx-2"></Spinner>
                                ) : (
                                  <>
                                    <i className="mdi mdi-facebook text-primary mx-1"></i>
                                    Facebook
                                  </>
                                )}
                              </a>
                            </div>
                          </div> */}

                          <div
                            className="mt-2 mx-auto"
                            style={{ maxWidth: "12rem" }}
                          >
                            <div className="d-grid">
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/auth/google`}
                                className="btn btn-light"
                                onClick={handleGoogleClick}
                              >
                                {isGoogleLoading ? (
                                  <Spinner size="sm" className="mx-2"></Spinner>
                                ) : (
                                  <>
                                    <i className="mdi mdi-google text-danger"></i>{" "}
                                    Google
                                  </>
                                )}
                              </a>
                            </div>
                          </div>
                        </Row>
                      </Col>
                      <Col xs={12} className="text-center">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            ¿No tienes cuenta?
                          </small>{" "}
                          <Link to="/register" className="text-dark fw-bold">
                            Registrarme
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default PageLogin;
