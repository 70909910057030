import React from "react";
import { Link, useHistory } from "react-router-dom";
import chatLogo from "../../assets/images/chatLogo.png";

const BackToTop = () => {
  const history = useHistory();

  const toContactUs = () => {
    history.push("/contact-us");
  };

  window.onscroll = function () {
    backToTopButton();
  };

  function backToTopButton() {
    var ele = document.getElementById("back-to-top");
    if (ele) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        ele.style.display = "block";
      } else {
        ele.style.display = "none";
      }
    }
  }

  return (
    <React.Fragment>
      <Link
        to="#"
        onClick={() => toContactUs()}
        id="back-to-top"
        className="back-to-top"
        style={{ display: "none" }}
      >
        <img src={chatLogo} alt="isologo mindly" width="50rem" />
      </Link>
    </React.Fragment>
  );
};

export default BackToTop;
