import React, { useEffect, useState, useRef } from "react";
import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import "../../assets/css/calendar.css";
import axiosInstance from "../../helpers/axiosConfig";
import EventModal from "./EventModal";
import NotAvailableModal from "./AddNotAvailableModal";
import TimesLoader from "../../components/Shared/Loaders/ThreeDots";
import { Helmet } from "react-helmet";

const MyCalendar = () => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [citas, setCitas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [addNotAvailableModalOpen, setAddNotAvailableModalOpen] =
    useState(false);
  const [eventProps, setEventProps] = useState({});
  const calendarRef = useRef(null);

  const theme = {
    common: {
      holiday: {
        color: "#6E32FA",
      },
      saturday: {
        color: "#6E32FA",
      },
      gridSelection: {
        backgroundColor: "transparent",
        border: "0px ",
      },
    },
    week: {
      nowIndicatorLabel: { color: "#6E32FA" },
      nowIndicatorBullet: { backgroundColor: "#6E32FA" },
      nowIndicatorPast: { border: "2px dotted #6E32FA" },
      nowIndicatorToday: { border: "2px solid #6E32FA" },
      today: { backgroundColor: "rgba(103, 48, 236, 0.1)" },
      gridSelection: { color: "transparent" },
      panelResizer: { border: "0px" },
    },
    month: {
      holidayExceptThisMonth: { color: "rgba(51, 51, 51, 0.4)" },
    },
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleEventClick = (e) => {
    setEventProps(e.event.body);
    openModal();
  };

  useEffect(() => {
    async function fetchCitas() {
      try {
        const response = await axiosInstance.get("/citas/citas-con-pacientes");
        if (response.data.status === 200) {
          return response.data.body;
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function loadCitas() {
      setLoading(true);
      try {
        const fetchedCitas = await fetchCitas();
        const citasData = [];
        for (const cita of fetchedCitas) {
          const nombre = cita.Paciente?.nombre || "";
          const apellido = cita.Paciente?.apellido || "";
          const profile_pic = cita.Paciente?.profile_picture_url || "";

          const startDate = new Date(cita.fecha);
          const endDate = new Date(
            startDate.getTime() + 60 * 60 * 1000
          ).toISOString();
          let backgroundColor = "";

          switch (cita.estado_id) {
            case 2:
              backgroundColor = "green";
              break;
            case 3:
              backgroundColor = "#6E32FA";
              break;
            case 5:
              backgroundColor = "gray";
              break;
          }

          const citaObj = {
            id: cita.id,
            title: nombre + " " + apellido,
            body: {
              citaId: cita.id,
              citaEstado: cita.estado_id,
              citaFecha: cita.fecha,
              citaPaciente: nombre + " " + apellido,
              pacienteProfilePic: profile_pic,
              citaAuthToken: cita.psicologo_authToken,
            },
            start: startDate,
            end: endDate,
            isReadOnly: true,
            backgroundColor,
            color: "#ffffff",
            borderColor: "#beabda",
          };
          if (
            cita.estado_id === 2 ||
            cita.estado_id === 3 ||
            cita.estado_id === 5
          ) {
            citasData.push(citaObj);
          }
        }
        setCitas(citasData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadCitas();
  }, []);

  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
  };

  const handleClickPrevButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
  };

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
  };

  const handleClickAddNotAvailableTime = () => {
    setAddNotAvailableModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Mindly | Agenda</title>
        <meta name="description" content="Agenda" />
      </Helmet>

      <div className="calendar-container">
        {!loading ? (
          <>
            {" "}
            <div className="calendar-controls">
              <div className="calendar-controls-nextprev">
                <button
                  className="btn btn-primary mx-1"
                  onClick={handleClickPrevButton}
                >
                  <i className="bx bx-chevron-left"></i>
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleClickNextButton}
                >
                  <i className="bx bx-chevron-right"></i>
                </button>
              </div>
              <div>
                <button
                  className="btn btn-secondary mx-1 agregarHorarioNoDisponibleBtn"
                  onClick={handleClickAddNotAvailableTime}
                >
                  Agregar horario no disponible
                </button>
                <button
                  className="btn btn-primary mx-1"
                  onClick={handleClickTodayButton}
                >
                  Hoy
                </button>
              </div>
            </div>
            <Calendar
              ref={calendarRef}
              theme={theme}
              month={{
                dayNames: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
              }}
              week={{
                dayNames: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                taskView: false,
              }}
              usageStatistics={false}
              view="week"
              events={citas}
              isReadOnly={true}
              timezone={userTimezone}
              onClickEvent={handleEventClick}
            />
            <EventModal
              isOpen={modalOpen}
              closeModal={() => setModalOpen(false)}
              eventProps={eventProps}
            />
            <NotAvailableModal
              isOpen={addNotAvailableModalOpen}
              closeModal={() => setAddNotAvailableModalOpen(false)}
            />
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center bg-light m-5 p-5 rounded">
            <TimesLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default MyCalendar;
