import React, { Component } from "react";

import Features from "./Features";
import Section from "./Section";
import ExtraInfo from "./ExtraInfo";
import About from "./About";
import Questions from "./Questions";
import "../../assets/css/psiLanding.css";
import Topbar from "./Topbar";
import { Helmet } from "react-helmet";

export default class index extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);

    var featurebox = document.getElementsByName("featurebox");
    for (var i = 0; i < featurebox.length; i++) {
      if (i !== 0) {
        featurebox[i].classList.add("mt-5");
        featurebox[i].classList.add("mt-sm-0");
      }
    }
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn").classList.add("btn-primary");
        document.querySelector(".shoppingbtn").classList.remove("btn-light");
        document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Mindly | Psicología online eficiente y segura</title>
          <meta
            name="description"
            content="Mindly es una plataforma de psicólogos online que te permite encontrar al psicólogo ideal para ti. Conoce a nuestros psicólogos y agenda tu cita."
          />
        </Helmet>
        <Topbar hasDarkTopBar={this.props.hasDarkTopBar} />
        <Section />
        <section className="section bg-light">
          <Features />
        </section>
        <ExtraInfo />
        <section className="py-4 bg-light">
          <About />
        </section>
        <Questions />
      </React.Fragment>
    );
  }
}
