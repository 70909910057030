import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, Redirect } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Helmet } from "react-helmet";

import "../../assets/css/thankyou.css";

function PageThankYou() {
  const { citaId } = useParams();
  const [status, setStatus] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const paymentIntentIdFromUrl = new URLSearchParams(location.search).get(
    "payment_intent"
  );

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    const getSessionStatus = async () => {
      try {
        const res = await axiosInstance.get(
          `/payment/session-status?session_id=${sessionId}`
        );
        if (res.status === 200) {
          setStatus(res.data.body.status);
          setPaymentIntentId(res.data.body.payment_intent);
        }
      } catch (error) {}
    };

    if (paymentIntentIdFromUrl !== null) {
      setPaymentIntentId(paymentIntentIdFromUrl);
      setStatus("complete");
    } else {
      getSessionStatus();
    }
  }, []);

  useEffect(() => {
    const confirmCita = async () => {
      try {
        await axiosInstance.post("/payment/confirmCita", {
          paymentIntentId,
          userTimezone,
        });
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (status === "complete") {
      confirmCita();
    }
  }, [status, paymentIntentId, userTimezone]);

  if (status === "open") {
    return <Redirect to={`/mi-espacio/payment/${citaId}`} />;
  }

  if (status === "complete") {
    return (
      <>
        <Helmet>
          <title>Mindly | Gracias por tu pago</title>
          <meta name="description" content="Gracias por tu pago" />
        </Helmet>
        <section className="bg-home d-flex m-0 p-0">
          <Container className="thank-you-container">
            <Row className="justify-content-center">
              <Col xs={12}>
                <div className="text-center">
                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <>
                      <div
                        className="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                        style={{ height: "90px", width: "90px" }}
                      >
                        <i
                          className="bx bx-check-circle align-middle justify-middle h1 mb-0"
                          style={{ fontSize: "3rem" }}
                        ></i>
                      </div>
                      <h1 className="my-4 fw-bold">Genial!</h1>
                      <p className="text-muted para-desc mx-auto">
                        Has completado tu pago con éxito. Puedes ver tus citas
                        disponibles en el área "Mis Citas". ¡Gracias por confiar
                        en nosotros! 🎉
                      </p>
                      <Link
                        to="/mi-espacio/mis-citas"
                        className="btn btn-soft-primary mt-3"
                      >
                        Ir a mis citas
                      </Link>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          <script>
            {`fbq('track', 'Purchase', {value: 15, currency: 'USD'}, {eventID: '9437281'});
`}
          </script>
        </section>
      </>
    );
  }
  return null;
}

export default PageThankYou;
