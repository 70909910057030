import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { StreamChat } from "stream-chat";
import axiosInstance from "../../helpers/axiosConfig.js";
import { Badge } from "reactstrap";

function SideBar() {
  const location = useLocation();
  const [unreadCount, setUnreadCount] = useState(0);
  const apiKey = process.env.REACT_APP_STREAM_CHAT_API_KEY;
  const client = new StreamChat(apiKey);

  useEffect(() => {
    const fetchUserUnreadMsgCount = async () => {
      try {
        const response = await axiosInstance.get("/user/data");
        const chatToken = response.data.body.chatToken;
        const userId = response.data.body.id;
        const user = await client.connectUser({ id: userId }, chatToken);
        setUnreadCount(user.me.unread_count);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserUnreadMsgCount();

    // Cleanup function to disconnect the user when the component unmounts
    return () => {
      client.disconnectUser();
    };
  }, [client]);

  const renderNavLink = (linkIndex, iconName, linkText, path) => {
    const isActive = location.pathname === path;
    const linkClassNames = `nav-link ${isActive ? "active" : ""}`;

    return (
      <li className={linkClassNames} key={linkIndex}>
        <Link to={path}>
          {iconName === "bx-chat" && unreadCount > 0 ? (
            <>
              <div className="icon-badge">
                <i className={`bx ${iconName} icon chat-icon`}>
                  <Badge className="rounded-pill bg-soft-primary chat-badge">
                    {unreadCount}
                  </Badge>
                </i>
              </div>
              <span className="text nav-text">{linkText}</span>
            </>
          ) : (
            <>
              <i className={`bx ${iconName} icon`}></i>
              <span className="text nav-text">{linkText}</span>
            </>
          )}
        </Link>
      </li>
    );
  };

  return (
    <>
      <link
        href="https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css"
        rel="stylesheet"
      />

      <nav className="sidebar open">
        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links">
              {renderNavLink(0, "bx-home-alt", "Inicio", "/mi-espacio")}
              {renderNavLink(
                1,
                "bx-calendar",
                "Mis citas",
                "/mi-espacio/mis-citas"
              )}
              {renderNavLink(
                2,
                "bx-search",
                "Especialistas",
                "/mi-espacio/especialistas"
              )}
              {renderNavLink(3, "bx-gift", "Paquetes", "/mi-espacio/paquetes")}
              {renderNavLink(4, "bx-chat", "Chat", "/chat")}
              {renderNavLink(
                5,
                "bx-user-circle",
                "Mi cuenta",
                "/mi-espacio/mi-cuenta"
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default withRouter(SideBar);
