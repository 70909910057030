import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { Spinner, Card, CardBody } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import visaa from "../../assets/images/payments/payment/visaa.png";
import american from "../../assets/images/payments/payment/american.png";
import master from "../../assets/images/payments/payment/master.png";
import ThreeDots from "../../components/Shared/Loaders/ThreeDots";

function CheckoutForm({ clientSecret, tipoPaquete }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [useLastPaymentMethod, setUseLastPaymentMethod] = useState(true);
  const [contentLoading, setContentLoading] = useState(true);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paciente, setPaciente] = useState(null);

  const { citaId } = useParams();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    async function getPaymentIntent() {
      const paymentIntent = await stripe.retrievePaymentIntent(clientSecret);
      setPaymentIntent(paymentIntent.paymentIntent);
    }

    getPaymentIntent();

    const getPaymentMethods = async () => {
      try {
        const { data } = await axiosInstance.get("/payment/lastMethod");
        if (data.body !== "") {
          setPaymentMethod(data.body);
        } else {
          setUseLastPaymentMethod(false);
        }
        setContentLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getPaymentMethods();

    async function getPaciente() {
      try {
        const response = await axiosInstance.get("/user/data");
        if (response.data.status == 200) {
          setPaciente(response.data.body);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getPaciente();
  }, [stripe]);

  const handlePaymentMethodChange = (e) => {
    e.preventDefault();
    setUseLastPaymentMethod(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const confirmParams = {
      return_url: tipoPaquete
        ? `${process.env.REACT_APP_CLIENT_URL}/mi-espacio/paquetePayment/success`
        : `${process.env.REACT_APP_CLIENT_URL}/mi-espacio/payment/${citaId}/success`,
    };
    if (useLastPaymentMethod && paymentMethod) {
      confirmParams.payment_method = paymentMethod.id;
    }
    const confirmOptions = { confirmParams };
    if (!useLastPaymentMethod) {
      confirmOptions.elements = elements;
    } else {
      confirmOptions.clientSecret = clientSecret;
    }

    const { error } = await stripe.confirmPayment(confirmOptions);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
      console.log(error);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <div className="payment-form-container">
        <h6 className="mb-2">Checkout</h6>
        {paciente?.discount > 0 &&
          paymentIntent !== null &&
          !window.location.href.includes("paquetePayment") && (
            <p className="text-primary payment-message">
              <i className="uil uil-check-circle text-primary me-1"></i>
              Tienes un descuento del {paciente.discount * 100}% en tu primera
              cita!
              <span className="d-flex align-items-center justify-content-center">
                <p className="text-decoration-line-through m-0">
                  {Math.round(
                    paymentIntent.amount / 100 / (1 - paciente.discount)
                  )}

                  {paymentIntent.currency.toUpperCase()}
                </p>
                <i className="uil uil-arrow-right text-primary mx-1"></i>
                <p className="m-0">
                  {(paymentIntent.amount / 100).toFixed(2)}{" "}
                  {paymentIntent.currency.toUpperCase()}
                </p>
              </span>
            </p>
          )}
        <div className="payment-form-items">
          {contentLoading && <ThreeDots />}
          {useLastPaymentMethod && paymentMethod && paymentIntent !== null ? (
            <div className="payment-method-container">
              {(() => {
                switch (paymentMethod?.card?.brand) {
                  case "visa":
                    return (
                      <Card className="rounded shadow bg-dark border-0">
                        <CardBody>
                          <img
                            src={visaa}
                            height="60"
                            className="text-end"
                            alt=""
                          />
                          <div className="mt-4">
                            <h5 className="text-light">
                              •••• •••• •••• {paymentMethod.card.last4}
                            </h5>
                            <div className="d-flex justify-content-between">
                              <p className="h6 text-muted mb-0"></p>
                              <h6 className="mb-0 text-muted">
                                Exp:{" "}
                                <span className="text-muted">
                                  {paymentMethod.card.exp_month}/
                                  {paymentMethod.card.exp_year
                                    .toString()
                                    .slice(-2)}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  case "mastercard":
                    return (
                      <Card className="rounded shadow bg-light border-0">
                        <CardBody>
                          <img
                            src={master}
                            height="60"
                            className="text-end"
                            alt=""
                          />
                          <div className="mt-4">
                            <h5 className="text-dark">
                              •••• •••• •••• {paymentMethod.card.last4}
                            </h5>
                            <div className="d-flex justify-content-between">
                              <p className="h6 text-muted mb-0">
                                Cristino Murfy
                              </p>
                              <h6 className="mb-0 text-dark">
                                Exp:{" "}
                                <span className="text-muted">
                                  {paymentMethod.card.exp_month}/
                                  {paymentMethod.card.exp_year
                                    .toString()
                                    .slice(-2)}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  case "amex":
                    return (
                      <Card className="rounded shadow bg-light border-0">
                        <CardBody>
                          <img
                            src={american}
                            height="100"
                            className="text-end"
                            alt=""
                          />
                          <div className="mt-4">
                            <h5 className="text-dark">
                              •••• •••• •••• {paymentMethod.card.last4}
                            </h5>
                            <div className="d-flex justify-content-between">
                              <p className="h6 text-muted mb-0"></p>
                              <h6 className="mb-0 text-dark">
                                Exp:{" "}
                                <span className="text-muted">
                                  {paymentMethod.card.exp_month}/
                                  {paymentMethod.card.exp_year
                                    .toString()
                                    .slice(-2)}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    );
                  default:
                    return (
                      <Card className="rounded shadow bg-light border-0">
                        <CardBody>
                          <div className="mt-4">
                            <h5 className="text-dark">
                              •••• •••• •••• {paymentMethod.card.last4}
                            </h5>
                            <div className="d-flex justify-content-between">
                              <p className="h6 text-muted mb-0"></p>
                              <h6 className="mb-0 text-dark">
                                Exp:{" "}
                                <span className="text-muted">
                                  {paymentMethod.card.exp_month}/
                                  {paymentMethod.card.exp_year
                                    .toString()
                                    .slice(-2)}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    );
                }
              })()}
              <div className="btn-container">
                <button
                  disabled={isLoading || !stripe}
                  onClick={handleSubmit}
                  className="btn btn-primary"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <Spinner size="sm"></Spinner>
                    ) : (
                      <>
                        Pagar ${(paymentIntent.amount / 100).toFixed(2)}{" "}
                        {paymentIntent.currency.toUpperCase()}
                      </>
                    )}
                  </span>
                </button>
                <button
                  className="btn btn-soft-primary"
                  onClick={handlePaymentMethodChange}
                >
                  <span id="button-text">Cambiar medio de pago</span>
                </button>
              </div>
            </div>
          ) : (
            <>
              {!contentLoading && paymentIntent !== null && (
                <form id="payment-form" onSubmit={handleSubmit}>
                  <PaymentElement
                    id="payment-element"
                    options={paymentElementOptions}
                  />
                  <button
                    disabled={isLoading || !stripe || !elements}
                    id="submit"
                    className="btn btn-primary"
                  >
                    <span className="button-text d-flex justify-content-center align-items-center">
                      {isLoading ? (
                        <Spinner size="sm"></Spinner>
                      ) : (
                        <>
                          Pagar ${(paymentIntent.amount / 100).toFixed(2)}{" "}
                          {paymentIntent.currency.toUpperCase()}
                        </>
                      )}
                    </span>
                  </button>
                  {/* Show any error or success messages */}
                  {message && <div id="payment-message">{message}</div>}
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CheckoutForm;
