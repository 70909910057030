import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import "react-drawer/lib/react-drawer.css";
import { Container } from "reactstrap";

//Import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpenShop: false,
      wishlistModal: false,
      dropdownIsOpen: false,
      open: false,
      position: "right",
      dropdownOpen: false,
      landing: false,
      components: false,
      demo: false,
      doc: false,
      pages: false,
      company: false,
      account: false,
      email: false,
      blog: false,
      case: false,
      auth: false,
      login: false,
      signup: false,
      reset: false,
      utility: false,
      special: false,
      contact: false,
      multi: false,
      level2: false,
    };
    this.toggleLine = this.toggleLine.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.toggleDropdown.bind(this);
    this.togglemodal.bind(this);
  }

  /**
   * Right sidebar drawer
   **/

  toggleRightDrawer = () => {
    this.setState({ position: "right" });
    this.setState({ open: !this.state.open });
  };
  onDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }
  initMenu() {
    this.activateParentDropdown();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  activateParentDropdown() {
    var menuItems = document.getElementsByClassName("sub-menu-item");

    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        const immediateParent = matchingMenuItem.closest("li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }
        const parent = immediateParent.closest(".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector("a");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          var parentOfParent = parent.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          parentOfParent = matchingMenuItem.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
      return false;
    }
    return false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.initMenu();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.tagline ? this.props.tagline : null}

        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              {this.props.hasDarkTopBar ? (
                <Link className="logo" to="/">
                  <img
                    src={logodark}
                    height="24"
                    className="logo-light-mode"
                    alt=""
                  />
                  <img
                    src={logolight}
                    height="24"
                    className="logo-dark-mode"
                    alt=""
                  />
                </Link>
              ) : (
                <Link className="logo" to="/">
                  <span className="logo-light-mode">
                    <img src={logodark} className="l-dark" height="24" alt="" />
                    <img
                      src={logolight}
                      className="l-light"
                      height="24"
                      alt=""
                    />
                  </span>
                  <img
                    src={logolight}
                    height="24"
                    className="logo-dark-mode"
                    alt=""
                  />
                </Link>
              )}
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>

            <ul className="buy-button list-inline mb-0">
              <li className="list-inline-item mb-0">
                <Link
                  to="/mi-espacio"
                  onClick={this.toggleRightDrawer}
                  disabled={this.state.open}
                >
                  <div
                    id="buyButton"
                    className="btn btn-pills btn-soft-primary settingbtn"
                  >
                    Iniciar Sesión
                  </div>
                </Link>
              </li>{" "}
              <li className="list-inline-item ps-1 mb-0">
                <Link to="/registro-psicologos" rel="noreferrer">
                  <div id="buyButton" className="btn btn-pills shoppingbtn">
                    Empezar ahora
                  </div>
                </Link>
              </li>
            </ul>

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              <ul className="navigation-menu nav-dark" id="top-menu">
                <li>
                  <Link to="/blog" className="sub-menu-item">
                    Blog
                  </Link>
                </li>

                <li>
                  <Link to="/home" className="sub-menu-item">
                    Encuentra tu psicólogo
                  </Link>
                </li>
                <li>
                  <Link to="/para-especialistas" className="sub-menu-item">
                    Para especialistas
                  </Link>
                </li>
              </ul>
            </div>
          </Container>
        </header>
      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);
