import React, { Component } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../helpers/axiosConfig";

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preciosInternacionales: [],
      isLoading: true,
      isMobile: window.innerWidth <= 768, // Check if the screen width is less than or equal to 768
    };
  }
  async componentDidMount() {
    const res = await axios.get("https://api.ipify.org/?format=json");
    await this.fetchPreciosInternacionales(res.data.ip);
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    // Remove event listener when component is unmounted
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    // Update isMobile state when window is resized
    this.setState({
      isMobile: window.innerWidth <= 768,
    });
  };
  fetchPreciosInternacionales = async (ip) => {
    try {
      const response = await axiosInstance.get(
        `/ip-location/preciosInternacionales/${ip}`
      );
      this.setState({
        preciosInternacionales: response.data.body,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="mt-lg-4 align-items-center">
            <Col
              lg={5}
              md={12}
              className="col-lg-5 col-md-12 text-center text-lg-start"
            >
              <div className="section-title mb-lg-0 pb-2 pb-lg-0">
                <h3 className="title mb-4 mt-5">
                  Los mejores profesionales al mejor precio
                </h3>
                <p className="text-muted para-desc mx-auto mb-0">
                  En México, el precio de una cita oscila entre los{" "}
                  <span className="text-primary fw-bold">$700</span> y los{" "}
                  <span className="text-primary fw-bold">$1,500</span> pesos.
                </p>
                <p className="text-muted para-desc mx-auto mt-3 mb-0">
                  En Mindly, te ofrecemos algo{" "}
                  <span className="text-primary fw-bold">mucho mejor.</span>
                </p>
                <p className="text-muted para-desc mx-auto mt-3 mb-0">
                  Podrás agendar{" "}
                  <span className="text-primary fw-bold">
                    citas individuales
                  </span>{" "}
                  o <strong>ahorrar dinero</strong> adquiriendo algún{" "}
                  <span className="text-primary fw-bold">
                    paquete de citas.
                  </span>{" "}
                </p>
                {/* <span className="text-primary fw-bold"></span> */}
                <Link
                  to="/register"
                  rel="noopener noreferrer"
                  className="btn btn-primary mt-4"
                >
                  Prueba ahora
                </Link>
              </div>
            </Col>
            <Col lg={7} md={12}>
              <div className="ms-lg-5">
                <Row className="align-items-center">
                  <Col md={6} xs={12} className="pt-4 px-md-0">
                    <div className="card pricing pricing-primary starter-plan shadow rounded border-0">
                      <div className="card-body py-5">
                        <h6 className="title name fw-bold text-uppercase mb-4 text-primary">
                          Pack constancia
                        </h6>
                        <div className="d-flex mb-4">
                          <span className="h4 mb-0 mt-2">$</span>
                          {this.state.isLoading ? (
                            <Spinner
                              color="primary"
                              size={"sm"}
                              className="my-auto mx-2"
                            />
                          ) : (
                            <span className="price h1 mb-0">
                              {(
                                this.state.preciosInternacionales
                                  .precio_paquete_10 /
                                100 /
                                10
                              ).toFixed(0) || 29}
                            </span>
                          )}
                          <span className="h4 align-self-end mb-1">/cita</span>
                        </div>

                        <ul className="list-unstyled mb-0 ps-0">
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            <strong>10 citas</strong> de 50 min
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Psicólogos certificados
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Chat 24/7
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Citas encriptadas
                          </li>
                        </ul>
                        <Link to="/register" className="btn btn-primary mt-4">
                          Prueba ahora
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <div className="col-md-6 col-12 pt-2 pt-md-4 px-md-0">
                    <div className="card pricing pricing-primary bg-light shadow rounded border-0">
                      <div className="card-body py-5">
                        <h6 className="title name fw-bold text-uppercase text-primary mb-4">
                          PACK Sanar
                        </h6>
                        <div className="d-flex mb-4">
                          <span className="h4 mb-0 mt-2">$</span>
                          {this.state.isLoading ? (
                            <Spinner
                              color="primary"
                              size={"sm"}
                              className="my-auto mx-2"
                            />
                          ) : (
                            <span className="price h1 mb-0">
                              {(
                                this.state.preciosInternacionales
                                  .precio_paquete_6 /
                                100 /
                                6
                              ).toFixed(0) || 34}
                            </span>
                          )}
                          <span className="h4 align-self-end mb-1">/cita</span>
                        </div>

                        <ul className="list-unstyled mb-0 ps-0">
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            <strong>6 citas</strong> de 50 min
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Psicólogos cetificados
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Chat 24/7
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Citas encriptadas
                          </li>
                        </ul>
                        <Link to="/register" className="btn btn-primary mt-4">
                          Prueba ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                  {this.state.isMobile && (
                    <Col md={6} xs={12} className="pt-4 px-md-0">
                      <div className="card pricing pricing-primary starter-plan shadow rounded border-0">
                        <div className="card-body py-5">
                          <h6 className="title name fw-bold text-uppercase mb-4 text-primary">
                            Cita Individual
                          </h6>
                          <div className="d-flex mb-4">
                            <span className="h4 mb-0 mt-2">$</span>
                            {this.state.isLoading ? (
                              <Spinner
                                color="primary"
                                size={"sm"}
                                className="my-auto mx-2"
                              />
                            ) : (
                              <span className="price h1 mb-0">
                                {(
                                  this.state.preciosInternacionales
                                    .precio_individual / 100
                                ).toFixed(0)}
                              </span>
                            )}
                            <span className="h4 align-self-end mb-1">
                              /cita
                            </span>
                          </div>

                          <ul className="list-unstyled mb-0 ps-0">
                            <li className="h6 text-muted mb-0">
                              <span className="text-primary h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              <strong>1 cita</strong> de 50 min
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="text-primary h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Psicólogos certificados
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="text-primary h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Chat 24/7
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="text-primary h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Citas encriptadas
                            </li>
                          </ul>
                          <Link to="/register" className="btn btn-primary mt-4">
                            Prueba ahora
                          </Link>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
