import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

const Problem = () => {
  return (
    <React.Fragment>
      <div className="section-title mb-5 pb-2 pb-lg-0">
        <h3 className="title mb-4 text-center">Una pandemia silenciosa</h3>
        <p className="text-muted text-center para-desc mx-auto mb-0">
          Los niveles de <span className="text-primary fw-bold">ansiedad</span>{" "}
          y <span className="text-primary fw-bold">depresión</span> están en
          niveles históricos.
        </p>
        <p className="text-muted text-center para-desc mx-auto mb-0 mt-2">
          Es momento de <strong>hacer algo</strong> al respecto.
        </p>
      </div>
      <Container>
        <Row>
          <Col lg={4} md={6} xs={12}>
            <Card className="features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
              {" "}
              <CardBody className="p-0 content">
                <h5>😔 Ansiedad y Depresión</h5>
                <p className="para text-muted mb-0">
                  Mas del <strong>40% de la poblacion mundial</strong> lucha
                  contra desafios de la salud mental.{" "}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <Card className="features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="bx bx-chat"></i>
              </span>
              <CardBody className="p-0 content">
                <h5>📱 Ideales Inalcanzables </h5>
                <p className="para text-muted mb-0">
                  En un mundo de likes y comparaciones, las{" "}
                  <strong>redes sociales</strong> dañan nuestra salud mental.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={6} xs={12}>
            <Card className="features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="bx bx-chat"></i>
              </span>
              <CardBody className="p-0 content">
                <h5>🐺 Soledad</h5>
                <p className="para text-muted mb-0">
                  A pesar de vivir en la era mas conectada de la historia un{" "}
                  <strong>60% </strong>
                  de los jovenes se <strong>sienten solos.</strong>{" "}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg={5}
            md={12}
            className="col-lg-5 col-md-12 text-center text-lg-center w-100 mb-5"
          >
            <div className="section-title mb-5 mb-lg-0 pb-2 pb-lg-0 mt-5">
              <p className="text-muted para-desc mx-auto mb-0">
                Estamos aquí para{" "}
                <span className="text-primary fw-bold">cambiar </span> estas
                estadisticas.
              </p>
              <p className="text-muted para-desc mx-auto mb-0 mt-3">
                Todos pasamos por dificultades en nuestras vidas y queremos
                mostrarte que{" "}
                <span className="text-primary fw-bold">no estas solo/a.</span>{" "}
                💜
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Problem;
