import React, { useEffect, useState } from "react";
import axiosInstance from "../../helpers/axiosConfig";
import { Alert } from "reactstrap";
import SmallTextLoader from "../../components/Shared/Loaders/SmallTextLoader";

const CobrosBody = ({ month, year, completedCitas }) => {
  const [cobro, setCobro] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCobro(null);
    setLoading(true);
    fetchCobros(month, year);
  }, [month]);

  const fetchCobros = async (month, year) => {
    try {
      const response = await axiosInstance.get(
        `/facturas/mis-facturas/${month}/${year}`
      );
      setLoading(false);
      if (response.status === 200) {
        setCobro(response.data.body[0]);
      } else {
        setError("Error al obtener los cobros");
      }
    } catch (error) {
      setError("Error al obtener los cobros");
    }
  };

  const getFechaEstimadaCobro = (fecha) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a new Date object using the provided date string
    const date = new Date(fecha);

    // Convert the date to the user's local timezone
    const localDate = new Date(
      date.toLocaleString("en-US", { timeZone: userTimezone })
    );

    // Set the date to the last day of the month
    localDate.setMonth(localDate.getMonth() + 1, 4);

    // Format the date as DD/MM/YY
    const formattedDate = `${localDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(localDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${localDate.getFullYear().toString().slice(-2)}`;

    return formattedDate;
  };

  return (
    <div className="cobros-body">
      {error && (
        <Alert className="bg-soft-primary fw-medium">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {loading && (
        <div className="text-center misCobrosLoader">
          <SmallTextLoader className="my-4" />
        </div>
      )}
      <div className="cobros-body-container">
        {cobro && !loading && (
          <>
            {" "}
            <p className="cobros-body-text">
              <i className="bx bx-dollar-circle"></i>
              Monto a cobrar: USD {cobro.monto}
            </p>
            <p className="cobros-body-text">
              <i className="bx bx-trending-up"></i>
              Sesiones realizadas: {completedCitas}
            </p>
            {!cobro.pagada && (
              <p className="cobros-body-text">
                <i className="bx bx-calendar"></i>
                Fecha estimada de cobro: {getFechaEstimadaCobro(cobro.fecha)}
              </p>
            )}
            <p className="cobros-body-text">
              <i className="bx bx-check-circle"></i>
              Estado: {cobro.pagada ? "Cobrado" : "Por cobrar"}
            </p>
          </>
        )}
        {!cobro && !loading && (
          <div className="cobros-body-container m-4">
            <p className="text-secondary"> No hay información que mostrar </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CobrosBody;
