import React, { useEffect, useState } from "react";
import axiosInstance from "../../helpers/axiosConfig.js";
import Chat from "./Chat.js";
import { Alert } from "reactstrap";

const ChatPage = () => {
  const [userData, setUserData] = useState(null);
  const [chatToken, setChatToken] = useState(null);
  const [filters, setFilters] = useState(null); // { type: "messaging", members: { $in: [userData.id] } }
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiKey = process.env.REACT_APP_STREAM_CHAT_API_KEY;

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axiosInstance.get("/user/data");
        if (response.data.status === 200) {
          const { id, nombre, profile_picture_url, chatToken } =
            response.data.body;
          setUserData({ id, name: nombre, image: profile_picture_url });
          setChatToken(chatToken);
          setFilters({ type: "messaging", members: { $in: [id] } });
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchToken();
  }, []);

  const sort = { last_message_at: -1 };
  const options = {
    limit: 10,
  };

  return (
    <>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {!isLoading && userData && (
        <Chat
          apiKey={apiKey}
          user={userData}
          userToken={chatToken}
          sort={sort}
          filters={filters}
          options={options}
        />
      )}
    </>
  );
};

export default ChatPage;
