import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import CitaSummary from "./CitaSummary";
import NotAvailableModalBody from "./NotAvailableModalBody";

const EventModal = ({ isOpen, closeModal, eventProps }) => {
  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal} className="justify-content-center">
        {eventProps.citaEstado === 5
          ? " Horario no disponible"
          : "Detalles de la cita"}
      </ModalHeader>
      <ModalBody>
        {eventProps.citaEstado === 5 ? (
          <NotAvailableModalBody eventProps={eventProps} />
        ) : (
          <CitaSummary eventProps={eventProps} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default EventModal;
