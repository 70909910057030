// React Basic and Bootstrap
import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  Card,
  CardBody,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Helmet } from "react-helmet";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import autoAnimate from "@formkit/auto-animate";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import logo from "../../assets/images/logo-light.png";
import signup from "../../assets/images/user/signup.svg";

const PageSignUp = () => {
  const [error, setError] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [ip, setIP] = useState("");

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  //Animations
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 200 });
  }, [parent]);
  const parent2 = useRef(null);
  useEffect(() => {
    parent2.current && autoAnimate(parent2.current, { duration: 200 });
  }, [parent2]);

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Ingrese su nombre"),
      lastname: Yup.string().required("Ingrese su apellido"),
      email: Yup.string("Ingrese su email")
        .email("Ingrese un email valido")
        .required("El email es requerido"),
      password: Yup.string()
        .min(8, "La contraseña debe tener al menos 8 caracteres")
        .required("Ingrese su contraseña"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      values.email = values.email.toLowerCase();
      const { firstname, lastname, email, password } = values;
      const expiresAt = new Date().getTime() + 1000 * 60 * 60 * 11;
      try {
        const response = await axiosInstance.post("/pacientes/sign-up", {
          nombre: firstname,
          apellido: lastname,
          email,
          password,
          ip,
        });
        if (response.status === 201) {
          const response = await axiosInstance.post("/auth/login", {
            email,
            password,
          });
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem("expiresAt", expiresAt);
          localStorage.setItem("role", response.data.user.role);
          setRedirectTo("/mi-espacio/especialistas");
        } else {
          setError("El email ya se encuentra registrado");
        }
        setIsLoading(false);
      } catch (error) {
        setError("El email ya se encuentra registrado");
        setIsLoading(false);
      }
    },
  });

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  function handleFacebookClick() {
    setIsFacebookLoading(true);
  }

  function handleGoogleClick() {
    setIsGoogleLoading(true);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Mindly | Registrarme</title>
        <meta name="description" content="Registrarme" />
      </Helmet>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-auth-home d-table w-100">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img
                  src={signup}
                  className="img-fluid d-block mx-auto auth-svg"
                  alt="register img"
                />
              </div>
            </Col>
            <Col className="auth-form" lg={5} md={6}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <div className="card-title text-center">
                    <a href="/home">
                      <img className="auth-logo m-auto" src={logo}></img>
                    </a>
                    <h4 className="mb-4">Registrarme</h4>
                  </div>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="firstname">
                            Nombre <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control ps-5"
                            name="firstname"
                            id="firstname"
                            placeholder="nombre"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstname || ""}
                            invalid={
                              validation.touched.firstname &&
                              validation.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstname &&
                          validation.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="lastname">
                            Apellido <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user-check"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control ps-5"
                            name="lastname"
                            id="lastname"
                            placeholder="apellido"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastname || ""}
                            invalid={
                              validation.touched.lastname &&
                              validation.errors.lastname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastname &&
                          validation.errors.lastname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-3" ref={parent}>
                          <Label className="form-label" htmlFor="email">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control ps-5"
                            name="email"
                            id="email"
                            placeholder="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3" ref={parent2}>
                          <Label className="form-label" htmlFor="password">
                            Password <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="key"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="password"
                            className="form-control ps-5"
                            name="password"
                            id="password"
                            placeholder="contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="flexCheckDefault"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Aceptar{" "}
                              <Link
                                to="/terminos-y-condiciones"
                                className="text-primary"
                              >
                                Terminos y condiciones
                              </Link>
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="d-grid">
                          <Button
                            color="primary"
                            type="submit"
                            className="d-flex align-items-center justify-content-center"
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="mx-2"></Spinner>
                            ) : (
                              <i className="bx bx-calendar-plus"></i>
                            )}
                            <p className="m-0">Crear una cuenta</p>
                          </Button>
                        </div>
                      </Col>
                      <Col md={12} className="mt-4 text-center">
                        <h6>O continúa con</h6>
                        <Row>
                          {/* <Col className="col-6 mt-3">
                            <div className="d-grid">
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/auth/facebook`}
                                className="btn btn-light d-flex align-items-center justify-content-center"
                                onClick={handleFacebookClick}
                              >
                                {isFacebookLoading ? (
                                  <Spinner size="sm" className="mx-2"></Spinner>
                                ) : (
                                  <>
                                    <i className="mdi mdi-facebook text-primary mx-1"></i>
                                    Facebook
                                  </>
                                )}
                              </a>
                            </div>
                          </Col> */}

                          <div
                            className="mt-2 mx-auto"
                            style={{ maxWidth: "12rem" }}
                          >
                            <div className="d-grid">
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/auth/google`}
                                className="btn btn-light"
                                onClick={handleGoogleClick}
                              >
                                {isGoogleLoading ? (
                                  <Spinner size="sm" className="mx-2"></Spinner>
                                ) : (
                                  <>
                                    <i className="mdi mdi-google text-danger"></i>{" "}
                                    Google
                                  </>
                                )}
                              </a>
                            </div>
                          </div>
                        </Row>
                      </Col>
                      <div className="mx-auto">
                        <p className="mb-0 mt-4">
                          <small className="text-dark me-1">
                            ¿Ya tienes cuenta?
                          </small>{" "}
                          <Link to="/login" className="text-dark fw-bold">
                            Iniciar sesión
                          </Link>
                        </p>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PageSignUp;
