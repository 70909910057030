import React, { useEffect, useState } from "react";
import DateTimePicker from "./DateTimePicker";
import { useParams } from "react-router-dom";
import { Spinner, Badge } from "reactstrap";
import "../../assets/css/agendar.css";
import axiosInstance from "../../helpers/axiosConfig";
import { Helmet } from "react-helmet";

function Agendar() {
  const { psicologoId: initialPsicologoId } = useParams();
  const [psicologoId, setPsicologoId] = useState(initialPsicologoId);
  const [loading, setLoading] = useState(false);
  // const [freeCitaStatus, setFreeCitaStatus] = useState(false);
  const [availableCitas, setAvailableCitas] = useState(0);

  useEffect(() => {
    if (!psicologoId) {
      setLoading(true);

      const getPsicologoId = async () => {
        try {
          const response = await axiosInstance.get("/user/data");
          if (response.status === 200) {
            const { id } = response.data.body;
            setPsicologoId(id);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching psicologoId:", error);
          setLoading(false);
        }
      };

      getPsicologoId();
    }
  }, [psicologoId]);

  useEffect(() => {
    const setPacienteCitas = async () => {
      try {
        const response = await axiosInstance.get("/pacientes/:id");
        if (response.status === 200) {
          const { free_cita, available_citas } = response.data.body;
          setAvailableCitas(available_citas);
          // setFreeCitaStatus(free_cita);
        }
      } catch (error) {
        console.error("Error fetching free_cita:", error);
      }
    };

    setPacienteCitas();
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Mindly | Agendar Cita</title>
            <meta name="description" content="Agendar" />
          </Helmet>

          <div className="agenda-container">
            <div className="title">
              <h5>Selecciona una fecha y hora</h5>
            </div>
            <div className="info-container">
              <div className="info-item">
                <i className="bx bx-user-voice  mx-1"></i>
                <p>Terapia individual</p>
              </div>
              <div className="info-item">
                <i className="bx bx-video  mx-1"></i>
                <p>Video llamada</p>
              </div>
              <div className="info-item">
                <i className="bx bx-time mx-1"></i>
                <p>55 min</p>
              </div>
              {/* {freeCitaStatus == 1 && (
                <div className="info-item">
                  <Badge
                    className="bg-soft-primary my-2 info-item-badge"
                    style={{
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      lineHeight: "1.2",
                    }}
                  >
                    1 Sesión Gratuita{" "}
                  </Badge>
                </div>
              )} */}
              {availableCitas > 0 && (
                <div className="info-item">
                  <Badge
                    className="bg-soft-primary my-2 p-1"
                    style={{
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      lineHeight: "1.2",
                    }}
                  >
                    {availableCitas} Citas Disponibles
                  </Badge>
                </div>
              )}
            </div>
            {psicologoId && (
              <DateTimePicker
                psicologoId={psicologoId}
                // freeCita={freeCitaStatus}
                availableCitas={availableCitas}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Agendar;
