import React, { useEffect, useState } from "react";

import { Card, Alert } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Link } from "react-router-dom";

import defaultProfilePicture from "../../assets/images/def-profile-picture-min.png";

function PsicologoSmallCard({ psicologoId }) {
  const [error, setError] = useState(null);
  const [psicologo, setPsicologo] = useState(null);
  const [profilePictureLoaded, setprofilePictureLoaded] = useState(false);

  useEffect(() => {
    async function getPsicologo() {
      try {
        const res = await axiosInstance.get(`/psicologos/${psicologoId}`);
        setPsicologo(res.data.body);
      } catch (error) {
        setError(error);
      }
    }
    getPsicologo();
  }, [psicologoId]);

  return (
    <>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {psicologo && (
        <Link to={`/mi-espacio/especialistas/${psicologo.id}`}>
          <Card className="shadow rounded border-0 overflow-hidden cita-card">
            <div className="card-img">
              {profilePictureLoaded ? null : (
                <img
                  src={defaultProfilePicture}
                  className="img-fluid"
                  alt="Cargando..."
                ></img>
              )}
              <img
                style={profilePictureLoaded ? {} : { display: "none" }}
                src={psicologo.profile_picture_url}
                alt="psicologo"
                className="img-fluid"
                onLoad={() => setprofilePictureLoaded(true)}
              />
            </div>
            <div className="body-card">
              <p className="card-name">
                {psicologo.nombre + " " + psicologo.apellido}
              </p>
              <p className="card-date">Psicología</p>
            </div>
            <div className="card-icon">
              <i className="bx bx-chevron-right"></i>
            </div>
          </Card>
        </Link>
      )}
    </>
  );
}

export default PsicologoSmallCard;
