import React, { useEffect, useState } from "react";
import iconCalendar from "../../assets/images/icons/icon-calendar.png";
import iconCitas from "../../assets/images/icons/icon-citas.png";
import iconSearch from "../../assets/images/icons/icon-search.png";
import Link from "react-router-dom/Link";

const SectionsRefs = ({ psicologoIdProp }) => {
  const [psicologoId, setPsicologoId] = useState(psicologoIdProp);

  useEffect(() => {
    if (psicologoIdProp) {
      setPsicologoId(psicologoId);
    } else {
      setPsicologoId("");
    }
  }, [psicologoIdProp]);

  return (
    <div className="proxima-cita-section-container">
      <div className="ref-lins-container d-flex flex-row justify-content-around align-items-center px-5">
        <Link
          to={`/mi-espacio/especialistas/${psicologoId}`}
          className="ref-link"
        >
          <div className="ref-icon-container">
            <img className="ref-icon" src={iconCalendar} alt=""></img>
          </div>
          <p className="text-secondary refs-text">Agendar</p>
        </Link>
        <Link to="/mi-espacio/especialistas" className="ref-link">
          <div className="ref-icon-container">
            <img className="ref-icon" src={iconSearch} alt=""></img>
          </div>
          <p className="text-secondary refs-text">Busqueda</p>
        </Link>
        <Link to="/mi-espacio/mis-citas" className="ref-link">
          <div className="ref-icon-container">
            <img className="ref-icon" src={iconCitas} alt=""></img>
          </div>
          <p className="text-secondary refs-text">Citas</p>
        </Link>
      </div>
    </div>
  );
};

export default SectionsRefs;
