import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/amorPropio/amor-propio-1-min.jpg";
import amorPropio from "../../../assets/images/blog/amorPropio/amor-propio-2-min.jpg";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            ¿Qué es el Amor Propio? - Fortalece tu Autoestima en 7 Pasos
          </title>
          <meta
            name="description"
            content="El amor propio es la base fundamental para fortalecer tu autoestima y cultivar una relación saludable contigo mismo. 
¡Descubre qué es y como mejorarlo!"
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img
                    src={standard}
                    className="img-fluid"
                    alt="manos tocando sus dedos formando un corazon"
                  />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Amor propio y Autoestima
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        Amor propio: Qué es y 7 pasos para mejorar tu autoestima
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 23 de
                          Noviembre, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="mt-3">
                      El amor propio es la apreciación, aceptación y cuidado de
                      uno mismo. Se trata de cultivar una relación positiva y
                      saludable con uno mismo, reconociendo y valorando tanto
                      las <strong>fortalezas</strong> como las{" "}
                      <strong>debilidades.</strong>
                    </p>
                    <p>
                      En este artículo, exploraremos{" "}
                      <strong className="text-primary">qué es</strong> el amor
                      propio, y{" "}
                      <strong className="text-primary">
                        7 pasos para mejorar tu autoestima.
                      </strong>
                    </p>
                    {/* <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote> */}
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Qué es el Amor Propio?
                    </h2>
                    <p>
                      El <strong>amor propio</strong> es un concepto fundamental
                      en el desarrollo personal y emocional de cada individuo.
                      Se refiere a la <strong>valoración y aprecio</strong> que
                      una persona tiene hacia sí misma.
                    </p>
                    <p>
                      Es la capacidad de reconocer y aceptar tanto las virtudes
                      como las imperfecciones propias, permitiéndose a uno mismo
                      el cuidado, el respeto y la indulgencia necesarios para
                      una vida equilibrada y plena.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      La Falta de Amor Propio y sus Consecuencias
                    </h2>
                    <p>
                      La falta de amor propio puede dar lugar a una serie de
                      <strong> problemas</strong> que afectan tanto la salud
                      mental como la emocional.
                    </p>
                    <p>
                      Cuando una persona carece de amor propio, es más propensa
                      a caer en patrones de pensamiento negativos, autocrítica
                      excesiva y <strong>baja autoestima.</strong> <br /> Esto
                      puede conducir a la <strong> ansiedad</strong>, la
                      <strong> depresión</strong> y a una sensación constante de
                      <strong> insatisfacción</strong> con la vida.
                    </p>
                    <p>
                      Además, la ausencia de amor propio puede afectar las
                      <strong> relaciones interpersonales.</strong> Las personas
                      que no se valoran a sí mismas tienden a aceptar relaciones
                      tóxicas o abusivas, ya que no reconocen su propio valor y
                      creen que no merecen algo mejor.
                    </p>
                    <p>
                      Esto crea un <strong>círculo vicioso</strong> que perpetúa
                      la falta de amor propio y contribuye a la perpetuación de
                      situaciones nocivas.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      7 Pasos para Fortalecer el Amor Propio
                    </h2>
                    <p>
                      Afortunadamente, el amor propio es una habilidad que se
                      puede cultivar y fortalecer con el tiempo. Aquí te
                      presentamos <strong>siete pasos fundamentales</strong>{" "}
                      para mejorar y nutrir el amor propio en tu vida:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Autoconocimiento Profundo:
                    </h3>
                    <p>
                      Antes de poder amarte a ti mismo, es crucial conocerte a
                      fondo. Reflexiona sobre tus valores, fortalezas,
                      debilidades y metas. Conocerte te permite aceptar quién
                      eres y construir una base sólida para el amor propio.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Practica la Autocompasión:
                    </h3>
                    <p>
                      La autocompasión es clave para el amor propio. En lugar de
                      ser duro contigo mismo cuando cometes errores o enfrentas
                      desafíos, sé amable contigo mismo. Trátate con la misma
                      compasión que mostrarías a un amigo en momentos difíciles.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Establece Límites Saludables:
                    </h3>
                    <p>
                      El amor propio también implica proteger tus propios
                      límites. Aprende a decir "no" cuando sea necesario y
                      establece límites claros en tus relaciones y actividades.
                      Esto te permitirá mantener un equilibrio saludable entre
                      tus necesidades y las de los demás.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Cuida de tu Salud Física y Mental:
                    </h3>
                    <p>
                      El amor propio se alimenta de un cuerpo y mente
                      saludables. Prioriza el cuidado de tu salud física y
                      mental, incluyendo una alimentación balanceada, ejercicio
                      regular y tiempo para el descanso y la relajación. El
                      bienestar físico y emocional se entrelazan de manera
                      intrínseca.
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      5. Acepta tus Errores y Aprende de ellos:
                    </h3>
                    <p>
                      Nadie es perfecto, y cometer errores es parte de la
                      experiencia humana. En lugar de castigarte por tus fallas,
                      acéptalas y aprende de ellas. Cada error es una
                      oportunidad de crecimiento y desarrollo personal.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      6. Rodéate de Personas Positivas:
                    </h3>
                    <p>
                      Las personas que te rodean pueden influir
                      significativamente en tu percepción de ti mismo. Busca
                      relaciones positivas y de apoyo que fomenten el amor
                      propio. Alejarte de relaciones tóxicas es un acto de amor
                      hacia ti mismo.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      7. Celebra tus Logros:
                    </h3>
                    <p>
                      Reconoce y celebra tus logros, por pequeños que sean. Ya
                      sea superar un desafío personal o alcanzar una meta
                      importante, tómate el tiempo para apreciar y celebrar tus
                      éxitos. El reconocimiento de tus logros refuerza tu
                      autoestima y amor propio.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      La Importancia de la Gratitud y la Positividad:
                    </h2>
                    <img
                      src={amorPropio}
                      className="img-fluid mx-auto my-4 d-block"
                      style={{ maxHeight: "40vh" }}
                      alt="mujer dandose un abrazo a si misma"
                    />

                    <p>
                      Además de los pasos mencionados, cultivar la{" "}
                      <strong>gratitud</strong> y fomentar la positividad en tu
                      vida pueden tener un impacto significativo en el
                      fortalecimiento del amor propio.
                    </p>
                    <p>
                      Aprender a apreciar las cosas positivas en tu vida,
                      incluso en tiempos difíciles, promueve una mentalidad
                      optimista y contribuye a una autoimagen más positiva.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Conclusión
                    </h2>
                    <p>
                      En última instancia, el amor propio es un viaje continuo
                      de <strong>autodescubrimiento</strong> y{" "}
                      <strong>crecimiento personal.</strong> Al implementar
                      estos pasos y practicar la autocompasión, establecerás las
                      bases para una relación saludable contigo mismo.
                    </p>
                    <p>
                      El amor propio no solo mejora tu bienestar emocional y
                      mental, sino que también fortalece tus relaciones y te
                      capacita para enfrentar los desafíos de la vida con
                      <strong> resiliencia</strong> y{" "}
                      <strong>confianza.</strong> <br /> <br />
                      ¡Ama y valora cada parte de ti mismo, porque mereces vivir
                      una vida plena y auténtica!
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Preguntas Frecuentes (FAQs)
                    </h2>
                    <p>
                      <strong>
                        1. ¿Qué significa realmente el término "amor propio"?
                      </strong>
                    </p>
                    <p className="text-muted">
                      El amor propio se refiere a la relación y aprecio que una
                      persona tiene hacia sí misma. Implica reconocer y aceptar
                      tanto las virtudes como las imperfecciones propias,
                      fomentando una conexión saludable y positiva con uno
                      mismo.
                    </p>
                    <p>
                      <strong>
                        2. ¿Cuáles son los signos de falta de amor propio?
                      </strong>
                    </p>
                    <p className="text-muted">
                      La falta de amor propio puede manifestarse de diversas
                      maneras, incluyendo una baja autoestima, autocrítica
                      excesiva, búsqueda constante de validación externa y la
                      tendencia a tolerar relaciones tóxicas.
                    </p>
                    <p>
                      <strong>
                        3. ¿Cómo puedo comenzar a cultivar el amor propio?
                      </strong>
                    </p>
                    <p className="text-muted">
                      El proceso de cultivar el amor propio comienza con el
                      autoconocimiento profundo. Reflexiona sobre tus valores,
                      establece límites saludables, practica la autocompasión y
                      cuida tanto de tu salud física como mental.
                    </p>
                    <p>
                      <strong>
                        4. ¿Qué papel juegan las relaciones en el amor propio?
                      </strong>
                    </p>
                    <p className="text-muted">
                      Las relaciones pueden tener un impacto significativo en el
                      amor propio. Rodéate de personas positivas y de apoyo que
                      fomenten tu crecimiento personal. Alejarte de relaciones
                      tóxicas es esencial para preservar y fortalecer tu amor
                      propio.
                    </p>
                    <p>
                      <strong>
                        5. ¿Por qué es importante la gratitud y la positividad
                        en el desarrollo del amor propio?
                      </strong>
                    </p>
                    <p className="text-muted">
                      Cultivar la gratitud y fomentar la positividad contribuyen
                      a una mentalidad optimista. Apreciar las cosas positivas
                      en la vida, incluso en tiempos difíciles, fortalece la
                      autoimagen y complementa los esfuerzos para mejorar el
                      amor propio, creando una base sólida para el bienestar
                      emocional.
                    </p>

                    <p>
                      Si tú o alguien que conoces está sufriendo debido a falta
                      de amor propio o autoestima, en{" "}
                      <a
                        href="https://www.mindly.la/home"
                        className="text-primary"
                      >
                        Mindly
                      </a>
                      , tenemos a un grupo de{" "}
                      <strong>psicólogos expertos</strong> en salud mental
                      listos para ofrecer el servicio de mayor calidad. <br />{" "}
                      <br />
                      ¡Agenda tu sesión hoy mismo! <br></br> <br></br> La{" "}
                      <strong>primera sesión</strong> cuenta con un
                      <strong> 50% OFF</strong>.{" "}
                      <Link
                        to="/register"
                        className="text-primary text-decoration-underline"
                      >
                        ¡Regístrate ahora!
                      </Link>
                      <br></br>
                    </p>
                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>123
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
