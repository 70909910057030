import React, { useEffect } from "react";
import "../../assets/css/onboarding.css";

const OnBoarding = () => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    let og_embedURL =
      "https://mindly.outgrow.us/664a797bf7517a0793532370?custom=1";
    let og_urlParameters = window.location.search.substring(1);
    if (og_urlParameters.length) {
      og_embedURL += "&" + og_urlParameters;
    }
    document.getElementById("og_iframe_temp").src = og_embedURL;
  }, []);

  return (
    <div className="onBoarding">
      <iframe
        id="og_iframe_temp"
        allow="camera *;"
        style={{
          height: "100vh",
          overflow: "scroll !important",
          width: "100vw",
          margin: 0,
          lineHeight: 0,
          position: "absolute",
          zIndex: 1,
          top: 0,
          left: 0,
          // border: "none",
          // boxShadow: "none",
        }}
        src=""
        title="Mindlys Paciente On-boarding"
      ></iframe>
    </div>
  );
};

export default OnBoarding;
