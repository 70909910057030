import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axiosInstance from "../../helpers/axiosConfig";
import CheckoutForm from "./CheckoutForm";
import { Alert, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import "../../assets/css/payment.css";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = loadStripe(stripeApiKey);

function PaymentPage() {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { citaId } = useParams() || null;
  const { tipoPaquete } = useParams() || false;

  const toggle = () => setShowModal(!showModal);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        let endpoint = "/payment/paquete";
        let requestData = { precioPaquete: tipoPaquete };
        const { data } = await axiosInstance.post(endpoint, requestData);
        setClientSecret(data.body.client_secret);
      } catch (error) {
        setError("Algo salió mal, por favor intente de nuevo");
        setShowModal(true);
      }
    };
    createPaymentIntent();
  }, [tipoPaquete, citaId]);

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#6e32fa",
      fontFamily: "Krub, system-ui, sans-serif",
    },
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <>
      <Helmet>
        <title>Mindly | Checkout</title>
        <meta name="description" content="Checkout" />
      </Helmet>

      {error && (
        <Modal isOpen={showModal && error} toggle={toggle}>
          <ModalBody>
            <Alert className="bg-soft-primary fw-medium ">
              <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
              {error}
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button className="p-1" color="primary" onClick={toggle}>
              <i className="bx bx-x-circle h5 m-auto"></i>
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <div className="App">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              clientSecret={clientSecret}
              tipoPaquete={tipoPaquete}
            />
          </Elements>
        )}
      </div>
    </>
  );
}

export default PaymentPage;
