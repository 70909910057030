import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import BackToTop from "./backToTop";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const Footer = React.lazy(() => import("./Footer"));

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Layout extends Component {
  render() {
    return (
      <div className="overflow-hidden">
        <Suspense fallback={Loader()}>
          {(() => {
            if (
              this.props.location.pathname === "/login" ||
              this.props.location.pathname === "/register" ||
              this.props.location.pathname === "/recovery" ||
              this.props.location.pathname === "/sesion" ||
              this.props.location.pathname === "/cambio-de-contrasena" ||
              this.props.location.pathname === "/oauth-succeed" ||
              this.props.location.pathname === "/para-especialistas" ||
              this.props.location.pathname === "/registro-psicologos"
            ) {
              return <></>;
            } else {
              return <Topbar hasDarkTopBar={this.props.hasDarkTopBar} />;
            }
          })()}
          {this.props.children}
          {(() => {
            if (
              // this.props.location.pathname === "/index-portfolio" ||
              // this.props.location.pathname === "/index-logistics" ||
              // this.props.location.pathname === "/index-education"
              //ACA SE AGREGAN LAS RUTAS QUE NO VAN A TENER EL FOOTER
              this.props.location.pathname === "/login" ||
              this.props.location.pathname === "/register" ||
              this.props.location.pathname === "/recovery" ||
              this.props.location.pathname === "/sesion" ||
              this.props.location.pathname === "/cambio-de-contrasena" ||
              this.props.location.pathname === "/oauth-succeed"
            ) {
              return <></>;
            } else {
              return <Footer />;
            }
          })()}
          {(() => {
            if (!this.props.location.pathname === "/home") {
              return <></>;
            } else {
              return <BackToTop />;
            }
          })()}
        </Suspense>
      </div>
    );
  }
}

export default withRouter(Layout);
