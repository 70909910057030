import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/standard.jpg";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Las expectativas: ¿Por qué nos afectan tanto?</title>
          <meta
            name="description"
            content="Las expectativas son las ideas que se forman en la mente
                      de una persona acerca de lo que podría suceder en el
                      futuro."
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img src={standard} className="img-fluid" alt="" />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Gestión de Expectativas
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        Las expectativas: ¿Por qué nos afectan tanto?
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 17 de
                          Agosto, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="text-muted mt-3">
                      Las expectativas son las ideas que se forman en la mente
                      de una persona acerca de lo que podría suceder en el
                      futuro. Son suposiciones anticipadas que pueden ser
                      acertadas o erróneas. Vivir a la expectativa implica no
                      tomar decisiones ni actuar hasta que los eventos se
                      desarrollen. Sin embargo, ¿qué impacto tienen estas
                      expectativas en nuestra vida? ¿Cuándo se convierten en un
                      obstáculo en lugar de una guía?{" "}
                    </p>
                    <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Las expectativas: una doble cara
                    </h2>
                    <p className="text-muted">
                      En un mundo lleno de posibilidades, es natural tener
                      esperanzas y sueños sobre lo que está por venir. Pero las
                      expectativas pueden transformarse en una fuente de estrés,
                      ansiedad y desilusión cuando no están alineadas con la
                      realidad. La línea entre tener metas realistas y
                      expectativas poco racionales a menudo se desdibuja, y esto
                      puede causar más daño del que imaginamos.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      El dilema de vivir bajo expectativas
                    </h2>
                    <p className="text-muted">
                      Vivir bajo el yugo de las expectativas puede minar nuestra
                      independencia y la capacidad de disfrutar el momento
                      presente. Cuando nuestras vidas son gobernadas por cómo
                      creemos que deberían ser las cosas, corremos el riesgo de
                      perder oportunidades genuinas y experiencias auténticas.
                      Esto puede llevarnos a tomar decisiones impulsivas o
                      precipitadas que no son coherentes con una evaluación
                      realista de nuestras circunstancias.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Las ilusiones y su impacto
                    </h2>
                    <p className="text-muted">
                      La ilusión, un resultado directo de las expectativas poco
                      realistas, puede ser una trampa peligrosa. Construir
                      castillos en el aire sin una base sólida puede llevar a
                      decisiones lamentables en el ámbito laboral, en las
                      relaciones personales y en la vida en general. ¿Cuántas
                      veces hemos abandonado un camino seguro por el brillo
                      fugaz de una oportunidad sin fundamentos?
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Cuando las expectativas se encuentran con la realidad
                    </h2>
                    <p className="text-muted">
                      La intersección entre expectativas y realidad a menudo es
                      un terreno fértil para la frustración. Esperamos que las
                      cosas se desarrollen de cierta manera, pero la realidad
                      puede ser un campo de juego completamente diferente. Esta
                      discrepancia puede generarnos emociones negativas, como la
                      frustración y la decepción, que a su vez pueden impactar
                      nuestra autoestima y bienestar emocional.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Liberándote del peso de las expectativas
                    </h2>
                    <p className="text-muted">
                      La clave para lidiar con el impacto negativo de las
                      expectativas radica en la mentalidad. Tomar el control de
                      tu vida y ser proactivo en la búsqueda de tus objetivos es
                      crucial. Disfrutar el presente y sumergirse en la realidad
                      actual te permitirá encontrar satisfacción y sentido en
                      cada momento. Si te das cuenta de que las expectativas
                      están afectando tu vida de manera perjudicial, buscar
                      ayuda profesional es un paso valioso. Hoy en día, puedes
                      acceder a terapias en línea con psicólogos altamente
                      capacitados para aprender a manejar tus expectativas y
                      emociones de manera saludable.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      El arte de mantener expectativas saludables en las
                      relaciones
                    </h2>
                    <p className="text-muted">
                      Tener expectativas sobre las personas que nos rodean es
                      natural, pero también puede ser un terreno delicado. En
                      lugar de imponer nuestras expectativas a los demás, es
                      crucial comunicar de manera abierta y respetuosa lo que
                      esperamos y entender lo que los demás pueden ofrecer. Las
                      relaciones saludables se construyen sobre una base de
                      comprensión mutua y respeto por los límites individuales.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      El equilibrio entre expectativas y realidad
                    </h2>
                    <p className="text-muted">
                      No es realista eliminar por completo las expectativas de
                      nuestras vidas. Sin embargo, aprender a manejarlas y
                      distinguirlas de la realidad es esencial para mantener una
                      perspectiva equilibrada. Reconocer que las expectativas
                      son construcciones mentales y que la realidad puede variar
                      en gran medida es el primer paso hacia un enfoque más
                      saludable. <br /> <br />
                      En última instancia, las expectativas no tienen por qué
                      ser perjudiciales si las manejamos con cautela y
                      sensibilidad. Aprender a disfrutar el presente, aceptar la
                      realidad tal como es y trazar objetivos realistas puede
                      liberarnos del ciclo de decepción y frustración que a
                      menudo acompañan a las expectativas irrealistas. Si te
                      encuentras luchando con el peso de tus expectativas, no
                      dudes en buscar apoyo profesional para navegar este camino
                      con mayor claridad y bienestar.
                    </p>
                    <br />
                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>33
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
