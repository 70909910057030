import React from "react";
import { Container, Row, Col } from "reactstrap";

const Features = () => {
  const Features = [
    {
      id: 1,
      icon: "uil uil-megaphone h2 text-primary",
      title: "Aumenta tu cartera de pacientes",
    },
    {
      id: 2,
      icon: "uil uil-money-insert h2 text-primary",
      title: "Incrementa tus ingresos",
    },
    {
      id: 3,
      icon: "uil uil-selfie h2 text-primary",
      title: "Gestión de pagos y agenda automatizada",
    },
    {
      id: 4,
      icon: "uil uil-users-alt h2 text-primary",
      title: "Construye tu marca profesional en línea",
    },
    {
      id: 5,
      icon: "uil uil-clock h2 text-primary",
      title: "Establece tu propio horario de trabajo",
    },
    {
      id: 6,
      icon: "uil uil-shield-check h2 text-primary",
      title: "Comprometidos con las buenas prácticas de psicología online",
    },
  ];
  return (
    <React.Fragment>
      <Container id="beneficios">
        <div className="features-title">
          {" "}
          <h4 className="text-center">
            Beneficios de ser parte de la comunidad de Mindly
          </h4>
          <p className="text-muted para-desc mb-0 mx-auto text-center">
            {" "}
            Buscamos apoyar a miles de psicólogos para que puedan dar el{" "}
            <span className="text-primary fw-bold">mejor servicio</span>{" "}
            psicológico posible.
          </p>
        </div>

        {/* feature box */}
        <Row>
          {Features.map((item, key) => (
            <Col key={key} md={4} className="col-12 mt-5">
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className={item.icon}></i>
                </div>

                <div className="content mt-4">
                  <h5>{item.title}</h5>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Features;
