import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import "../../assets/css/profilePicture.css";
import { uploadFile } from "../../firebase/config";
import axiosInstance from "../../helpers/axiosConfig";
import imageCompression from "browser-image-compression";
import defaultProfilePicture from "../../assets/images/def-profile-picture-min.png";

const ProfilePicture = ({ userData, onProfilePictureSave }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCompressing, setIsCompressing] = useState(false);
  const [profilePictureLoaded, setprofilePictureLoaded] = useState(false);

  const fileInputRef = useRef(null);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePictureUpload = async (event) => {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true,
    };
    try {
      setIsCompressing(true);
      const compressedFile = await imageCompression(imageFile, options);
      await setNewProfilePicture(URL.createObjectURL(compressedFile));
      setFile(compressedFile);
      setIsCompressing(false);
    } catch (error) {
      setError(error);
    }
  };

  const handleSave = async () => {
    try {
      if (file) {
        setLoading(true);
        const url = await uploadFile(file);
        await updateUserProfilePicture(url);
        setLoading(false);
      }
      onProfilePictureSave();
    } catch (error) {
      console.log(error);
      setError(error);
    }
    toggleModal();
    setNewProfilePicture(null);
  };

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  const updateUserProfilePicture = async (url) => {
    try {
      await axiosInstance.patch(`/${userData.role}s/${userData.id}`, {
        profile_picture_url: url,
      });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="profile-picture-container">
      {profilePictureLoaded ? null : (
        <img
          src={defaultProfilePicture}
          alt="Cargando..."
          className={isHovered ? "darken" : ""}
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverLeave}
          onClick={toggleModal}
        ></img>
      )}
      <img
        style={profilePictureLoaded ? {} : { display: "none" }}
        src={userData.profile_picture_url}
        alt="user image"
        className={isHovered ? "darken" : ""}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
        onClick={toggleModal}
        onLoad={() => setprofilePictureLoaded(true)}
      />

      {isHovered && (
        <div className="edit-icon">
          <i className="bx bx-edit"></i>
        </div>
      )}

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Editar imagen de perfil</ModalHeader>
        <ModalBody>
          <input
            type="file"
            accept="image/*"
            onChange={handlePictureUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
            aria-label="Elegir archivo"
          />
          <Button color="primary" onClick={handleChooseFile}>
            Elegir archivo
          </Button>
          {isCompressing && (
            <div className="m-3">
              <Spinner color="primary" className="mx-2" />
            </div>
          )}
          {newProfilePicture && (
            <div className="preview-container">
              <img
                src={newProfilePicture}
                alt="preview"
                className="preview-image my-4"
              />
            </div>
          )}
          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              Error al subir la imagen
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSave}
            className="d-flex justify-content-space align-items-center"
          >
            {loading && (
              <span
                className="spinner-border spinner-border-sm mx-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Guardar
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProfilePicture;
