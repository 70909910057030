import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";

const AdminPagos = () => {
  const [facturas, setFacturas] = useState([]);
  const [filtroEstado, setFiltroEstado] = useState("");
  const [filtroAnio, setFiltroAnio] = useState("");
  const [filtroMes, setFiltroMes] = useState("");
  const [filtroPsicologo, setFiltroPsicologo] = useState("");
  const [confirmarPagoModal, setConfirmarPagoModal] = useState(false);
  const [facturaIdSeleccionada, setFacturaIdSeleccionada] = useState("");

  const meses = [
    { value: "1", label: "Enero" },
    { value: "2", label: "Febrero" },
    { value: "3", label: "Marzo" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Mayo" },
    { value: "6", label: "Junio" },
    { value: "7", label: "Julio" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
  ];

  const anios = ["2022", "2023", "2024", "2025"];

  useEffect(() => {
    fetchFacturas();
  }, []);

  const fetchFacturas = async () => {
    try {
      const response = await axiosInstance.get("/facturas");
      setFacturas(response.data.body);
    } catch (error) {
      console.log(error);
    }
  };

  const filtrarFacturas = () => {
    let facturasFiltradas = facturas;

    if (filtroEstado !== "") {
      facturasFiltradas = facturasFiltradas.filter(
        (factura) => factura.pagada === (filtroEstado === "pagada")
      );
    }

    if (filtroAnio !== "") {
      facturasFiltradas = facturasFiltradas.filter(
        (factura) =>
          new Date(factura.fecha).getFullYear() === parseInt(filtroAnio)
      );
    }

    if (filtroMes !== "") {
      facturasFiltradas = facturasFiltradas.filter(
        (factura) =>
          new Date(factura.fecha).getMonth() === parseInt(filtroMes) - 1
      );
    }

    if (filtroPsicologo !== "") {
      facturasFiltradas = facturasFiltradas.filter(
        (factura) =>
          factura.Psicologo.nombre
            .toLowerCase()
            .includes(filtroPsicologo.toLowerCase()) ||
          factura.Psicologo.apellido
            .toLowerCase()
            .includes(filtroPsicologo.toLowerCase())
      );
    }

    return facturasFiltradas;
  };

  const handleChangeFiltroEstado = (event) => {
    setFiltroEstado(event.target.value);
  };

  const handleChangeFiltroAnio = (event) => {
    setFiltroAnio(event.target.value);
  };

  const handleChangeFiltroMes = (event) => {
    setFiltroMes(event.target.value);
  };

  const handleChangeFiltroPsicologo = (event) => {
    setFiltroPsicologo(event.target.value);
  };

  const handleMarcarComoPagada = (facturaId) => {
    setFacturaIdSeleccionada(facturaId);
    setConfirmarPagoModal(true);
  };

  const handleConfirmarPago = async () => {
    // Aquí puedes realizar acciones adicionales antes de marcar la factura como pagada
    await handleEstadoPagoChange(facturaIdSeleccionada);
    setConfirmarPagoModal(false);
  };

  const handleCancelarPago = () => {
    setConfirmarPagoModal(false);
  };

  const handleEstadoPagoChange = async (facturaId) => {
    try {
      const response = await axiosInstance.patch(`/facturas/${facturaId}`, {
        pagada: true,
      });
      if (response.status === 200) {
        alert("Factura marcada como pagada correctamente");
      }
      fetchFacturas();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="px-5">
      <Form className="d-flex justify-content-center my-3">
        <FormGroup className="mx-1">
          <Label for="filtroEstado">Filtrar por estado:</Label>
          <Input
            type="select"
            id="filtroEstado"
            value={filtroEstado}
            onChange={handleChangeFiltroEstado}
          >
            <option value="">Todos</option>
            <option value="pagada">Pagada</option>
            <option value="noPagada">No pagada</option>
          </Input>
        </FormGroup>
        <FormGroup className="mx-1">
          <Label for="filtroAnio">Filtrar por año:</Label>
          <Input
            type="select"
            id="filtroAnio"
            value={filtroAnio}
            onChange={handleChangeFiltroAnio}
          >
            <option value="">Todos</option>
            {anios.map((anio) => (
              <option key={anio} value={anio}>
                {anio}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="mx-1">
          <Label for="filtroMes">Filtrar por mes:</Label>
          <Input
            type="select"
            id="filtroMes"
            value={filtroMes}
            onChange={handleChangeFiltroMes}
          >
            <option value="">Todos</option>
            {meses.map((mes) => (
              <option key={mes.value} value={mes.value}>
                {mes.label}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="mx-1">
          <Label for="filtroPsicologo">Filtrar por psicólogo:</Label>
          <Input
            type="text"
            id="filtroPsicologo"
            value={filtroPsicologo}
            onChange={handleChangeFiltroPsicologo}
          />
        </FormGroup>
      </Form>

      <Table className="">
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha</th>
            <th>Monto</th>
            <th>Pagada</th>
            <th>Psicólogo</th>
            <th>Marcar como pagada</th>
          </tr>
        </thead>
        <tbody>
          {filtrarFacturas().map((factura) => (
            <tr key={factura.id}>
              <td>{factura.id}</td>
              <td>{new Date(factura.fecha).toLocaleDateString()}</td>
              <td>{factura.monto}</td>
              <td>{factura.pagada ? "Sí" : "No"}</td>
              <td>
                {factura.Psicologo.nombre + " " + factura.Psicologo.apellido}
              </td>
              {!factura.pagada ? (
                <td>
                  <Button
                    color="primary"
                    onClick={() => handleMarcarComoPagada(factura.id)}
                  >
                    Marcar como pagada
                  </Button>
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal isOpen={confirmarPagoModal} toggle={handleCancelarPago}>
        <ModalHeader toggle={handleCancelarPago}>Confirmar pago</ModalHeader>
        <ModalBody>¿Estás seguro de marcar la factura como pagada?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmarPago}>
            Sí
          </Button>
          <Button color="secondary" onClick={handleCancelarPago}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminPagos;
