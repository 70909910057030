import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const PainAndGain = () => {
  return (
    <React.Fragment>
      <Container className="mt-100 mt-60">
        <div className="rounded bg-primary bg-gradient p-lg-5 p-4">
          <Row className="align-items-end">
            <Col md={8}>
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-3 text-white title-dark">
                  Estamos para ti 💛
                </h4>
                <p className="text-white-50 mb-3">
                  Esta demostrado que la <strong>terapia</strong> profesional es
                  el método mas efectivo para alcanzar tu mejor versión. <br />
                  <br /> En Mindly, optimizamos cada paso para hacerlo lo más{" "}
                  <strong>fácil</strong>, <strong>seguro</strong> y{" "}
                  <strong>efectivo </strong>
                  posible.
                </p>
                <p className="text-white-50 mt-2">
                  Puedes enfrentar tus desafíos en <strong>solitario</strong>, y
                  te alentamos a que lo intentes, pero en ocasiones nos
                  autosaboteamos, haciendo <strong>difícil</strong> avanzar por
                  cuenta propia.
                </p>
                <p className="text-white-50 mt-2">
                  Si lo que buscas es empezar un proceso de la mano de los
                  mejores expertos, <strong>estamos aqui para ti.</strong> 💜
                </p>
              </div>
            </Col>

            <Col md={4} className="mt-4 mt-sm-0">
              <div className="text-md-end text-center">
                <Link to="/register" className="btn btn-light">
                  Probar ahora
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default PainAndGain;
