import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Alert,
  Spinner,
  Badge,
} from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Redirect } from "react-router-dom";

const ReCompra = ({ isOpen, toggleModal, newCitaObj, userTimezone }) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [availableCitas, setAvailableCitas] = useState(0);

  useEffect(() => {
    const setPacienteCitas = async () => {
      try {
        const response = await axiosInstance.get("/pacientes/:id");
        if (response.status === 200) {
          const { available_citas } = response.data.body;
          setAvailableCitas(available_citas);
        }
      } catch (error) {
        console.error("Error fetching free_cita:", error);
      }
    };

    setPacienteCitas();
  }, []);

  const handleReservarClick = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `/psicologos/${newCitaObj.psicologo_id}/citas`,
        {
          fecha: newCitaObj.fecha,
          psicologo_id: newCitaObj.psicologo_id,
        }
      );
      if (response.status === 201) {
        if (availableCitas > 0) {
          const claimCitaResponse = await axiosInstance.patch(
            `/payment/claimCita`,
            {
              citaId: response.data.body,
              userTimezone,
            }
          );
          if (claimCitaResponse.status === 200) {
            setIsLoading(false);
            setRedirectTo(`/mi-espacio/mis-citas`);
          } else {
            setError("Error al reclamar la cita");
          }
        } else {
          setRedirectTo(`/mi-espacio/payment/${response.data.body}`); //TODO CHANGE
        }
      }
    } catch (error) {
      setError("Algo salió mal, por favor intente de nuevo");
    }
    setIsLoading(false);
  };
  const handleNoReservarClick = () => {
    toggleModal();
  };
  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    if (date instanceof Date && !isNaN(date)) {
      return daysOfWeek[date.getDay()];
    }
    return "";
  };
  const formatDate = (date) => {
    return new Date(date).toLocaleString("es-AR", {
      dateStyle: "long",
      timeZone: userTimezone,
    });
  };
  const formatTime = (date) => {
    return new Date(date).toLocaleString("es-AR", {
      timeStyle: "short",
      timeZone: userTimezone,
    });
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      <Modal isOpen={isOpen} toggle={toggleModal} className="recompra-modal">
        <ModalHeader toggle={toggleModal} className="recompra-modal-header">
          {newCitaObj.fecha && (
            <>
              ¿Misma hora el próximo {getDayOfWeek(new Date(newCitaObj.fecha))}?
              {availableCitas > 0 && (
                <Badge
                  className="bg-soft-primary mt-3 mx-1"
                  style={{
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                    lineHeight: "1.2",
                  }}
                >
                  {availableCitas} Citas Disponibles
                </Badge>
              )}
            </>
          )}
        </ModalHeader>
        <ModalBody className="recompra-modal-body">
          <p>
            ¿Qué te parece reservar otra cita para la próxima semana, a la misma
            hora? 😌
          </p>
          {newCitaObj.fecha && (
            <p>
              <strong>Fecha:</strong> {formatDate(newCitaObj.fecha)}
            </p>
          )}
          {newCitaObj.fecha && (
            <p>
              <strong>Hora:</strong> {formatTime(newCitaObj.fecha)}
            </p>
          )}
          <div className="recompra-modal-buttons d-flex justify-content-end">
            <Button
              color="primary"
              onClick={handleReservarClick}
              className="m-1 d-flex align-items-center"
            >
              {isLoading ? (
                <Spinner size="sm" className="m-1"></Spinner>
              ) : (
                <i className="bx bx-calendar m-0"></i>
              )}

              <p className="m-1 btn-text"> Claro que si! </p>
            </Button>
            <Button
              color="secondary"
              onClick={handleNoReservarClick}
              className="m-1"
            >
              <p className="m-0 btn-text">Por ahora no</p>
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReCompra;
