import React, { useState, useEffect } from "react";
import axiosInstance from "../../helpers/axiosConfig";
import "../../assets/css/payment.css";
import CheckoutSession from "./CheckoutSession";
import CheckoutFormWrapper from "./CheckoutFormWrapper";
import { Spinner } from "reactstrap";

function PaymentPage() {
  const [isCustomer, setIsCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCustomerStatus = async () => {
      try {
        const response = await axiosInstance.get("/pacientes/is-customer");
        if (response.status === 200) {
          console.log(response.data.body);
          setIsCustomer(response.data.body);
        }
      } catch (error) {
        console.error("Error fetching customer status:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomerStatus();
  }, []);

  if (loading) {
    return <Spinner size="sm"></Spinner>;
  }

  return <>{isCustomer ? <CheckoutFormWrapper /> : <CheckoutSession />}</>;
}

export default PaymentPage;
