import React, { useEffect, useState } from "react";
import "../../assets/css/misHorarios.css";
import { Input } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

const DescansoInput = ({
  dayOfWeekCode,
  availablity,
  descansoRange,
  descansoRangeTimeId,
}) => {
  const [desdeValue, setDesdeValue] = useState("Desde");
  const [hastaValue, setHastaValue] = useState("Hasta");
  const [availableToTimeLocal, setAvailableToTimeLocal] = useState("");
  const [availableFromTimeLocal, setAvailableFromTimeLocal] = useState("");
  const [desdeChanged, setDesdeChanged] = useState(false);
  const [hastaOptions, setHastaOptions] = useState([]);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(false);
  const [guardarDisabled, setGuardarDisabled] = useState(true);

  const [upsertedDescansoRangeTimeId, setCreatedDescansoRangeTimeId] =
    useState(descansoRangeTimeId);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (availablity) {
      if (availablity.availableFromTime) {
        transformToLocalTime(availablity.availableFromTime).then((res) => {
          setAvailableFromTimeLocal(res);
        });
      }
      if (availablity.availableToTime) {
        transformToLocalTime(availablity.availableToTime).then((res) => {
          setAvailableToTimeLocal(res);
        });
      }
    }
  }, [availablity]);

  useEffect(() => {
    if (
      descansoRangeTimeId &&
      descansoRange?.fromTime &&
      descansoRange?.toTime
    ) {
      transformToLocalTime(descansoRange.fromTime).then((res) => {
        setDesdeValue(res);
      });
      transformToLocalTime(descansoRange.toTime).then((res) => {
        setHastaValue(res);
      });
    }
  }, [descansoRange]);

  const transformToLocalTime = async (utcTimeString) => {
    const [hours, minutes] = utcTimeString.split(":");
    const utcDate = new Date();
    utcDate.setUTCHours(hours);
    utcDate.setUTCMinutes(minutes);

    const userDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: userTimezone })
    );
    const userTimeString = userDate.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });

    return userTimeString;
  };

  useEffect(() => {
    if (
      desdeValue !== "Desde" &&
      hastaValue !== "Hasta" &&
      !upsertedDescansoRangeTimeId
    ) {
      setGuardarDisabled(false);
    } else {
      setGuardarDisabled(true);
    }
  }, [desdeValue, hastaValue]);

  const getHastaOptions = (selectedDesde) => {
    const desdeHour = parseInt(selectedDesde.split(":")[0]);
    const options = [];
    for (
      let i = desdeHour;
      i <= parseInt(availableToTimeLocal.split(":")[0]);
      i++
    ) {
      options.push(`${i}:00`);
    }
    return options;
  };

  const handleDesdeChange = (event) => {
    setGuardarDisabled(false);
    const selectedDesde = event.target.value;
    setDesdeValue(selectedDesde);
    setHastaOptions(getHastaOptions(selectedDesde));
    setDesdeChanged(true);
  };

  const handleHastaChange = (event) => {
    setGuardarDisabled(false);
    const selectedHasta = event.target.value;
    setHastaValue(selectedHasta);
  };

  const handleEliminarDescanso = async () => {
    try {
      const response = await axiosInstance.delete(
        `/psicologos/descanso/${upsertedDescansoRangeTimeId}`
      );
      if (response.status === 200) {
        setHidden(true);
      } else {
        setMessage("Error al eliminar descanso");
      }
    } catch (error) {
      setMessage("Error al eliminar descanso");
    }
  };

  const createDescanso = async () => {
    try {
      const currentDate = new Date();

      // Split the input time strings into hours and minutes
      const [desdeHours, desdeMinutes] = desdeValue.split(":");
      const [hastaHours, hastaMinutes] = hastaValue.split(":");

      // Set the hours and minutes of the Date objects
      currentDate.setHours(desdeHours);
      currentDate.setMinutes(desdeMinutes);
      const desdeUTCString = currentDate.toISOString().slice(11, 16); // Get the UTC time string from the Date object

      currentDate.setHours(hastaHours);
      currentDate.setMinutes(hastaMinutes);
      const hastaUTCString = currentDate.toISOString().slice(11, 16); // Get the UTC time string from the Date object
      const response = await axiosInstance.put("/psicologos/descanso", {
        id: upsertedDescansoRangeTimeId,
        day_of_week: dayOfWeekCode,
        fromTime: desdeUTCString,
        toTime: hastaUTCString,
      });

      if (response.status === 200) {
        setCreatedDescansoRangeTimeId(response.data.body.id);
        setGuardarDisabled(true);
      }
    } catch (error) {
      setMessage(
        "Error al agregar descanso: asegurate que el tiempo de descanso indicado no interfiera con otro."
      );
    }
  };

  const toggle = () => {
    setMessage("");
    window.location.reload();
  };

  return (
    <>
      {message && (
        <Modal isOpen={message.length > 0} toggle={toggle}>
          <ModalBody className="m-auto p-4">
            <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
            <span>{message}</span>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Entendido
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {!hidden && (
        <div className="descanso-inputs">
          <Input
            className="dayOfWeekInput"
            type="select"
            value={desdeValue}
            onChange={handleDesdeChange}
          >
            <option value={desdeValue}>{desdeValue}</option>
            {availableFromTimeLocal &&
              availableToTimeLocal &&
              Array.from(
                {
                  length:
                    parseInt(availableToTimeLocal.split(":")[0]) -
                    parseInt(availableFromTimeLocal.split(":")[0]) +
                    1,
                },
                (_, index) => {
                  const hour =
                    parseInt(availableFromTimeLocal.split(":")[0]) + index;
                  return (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  );
                }
              )}
          </Input>
          <p>a</p>
          <Input
            className="dayOfWeekInput"
            type="select"
            value={hastaValue}
            onChange={handleHastaChange}
          >
            {!desdeChanged && <option value={hastaValue}>{hastaValue}</option>}

            {hastaOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>
          <button
            className="btn btn-success btn-guardarDescanso"
            disabled={guardarDisabled}
            onClick={createDescanso}
          >
            Guardar
          </button>
          <button
            className="btn btn-danger btn-eliminarDescanso"
            disabled={upsertedDescansoRangeTimeId == null}
            onClick={handleEliminarDescanso}
          >
            Eliminar
          </button>
        </div>
      )}
    </>
  );
};

export default DescansoInput;
