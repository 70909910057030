import React, { useEffect, useState } from "react";
import "../../assets/css/psicologo.css";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../helpers/axiosConfig";
import {
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import PsicologoLoader from "../../components/Shared/Loaders/PsicologoLoader";
import ProfilePictureUpload from "../MiCuenta/ProfilePictureUpload";
import Select from "react-select";
import { Helmet } from "react-helmet";

import EdiText from "react-editext";

import defaultProfilePicture from "../../assets/images/def-profile-picture-min.png";

function PsicologoPage({ psicologoIdProp }) {
  const [psicologo, setPsicologo] = useState({});
  const [sobreMi, setSobreMi] = useState("");
  const [primeraSesion, setPrimeraSesion] = useState("");
  const [estiloTerapia, setEstiloTerapia] = useState("");
  const [especialidades, setEspecialidades] = useState([]);
  const [idiomas, setIdiomas] = useState([]);
  const [resenias, setResenias] = useState([]);
  const psicologoId = useParams().psicologoId || psicologoIdProp;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingEspecialidades, setEditingEspecialidades] = useState(false);
  const [especialidadesOptions, setEspecialidadesOptions] = useState([]);
  const [editingIdioma, setEditingIdioma] = useState(false);
  const [satisfaccion, setSatisfaccion] = useState(null);
  const [modal, setModal] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [profilePictureLoaded, setprofilePictureLoaded] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchPsicologo(psicologoId);
    fetchIdiomas(psicologoId);
    fetchEspecialidades(psicologoId);
    fetchResenias(psicologoId);
    if (psicologoIdProp) {
      fetchEspecialidadesOptions();
    }
  }, [reRender]);

  useEffect(() => {
    async function fetchSatisfaccion() {
      try {
        const response = await axiosInstance.get(
          `/reviews/satisfaccion/${psicologoId}`
        );
        if (response.status === 200) {
          let satisfaccionResponse = response.data.body.satisfaccion;
          if (satisfaccionResponse < 3.7) {
            satisfaccionResponse += 0.6;
          }
          setSatisfaccion(satisfaccionResponse);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchSatisfaccion();
  }, [psicologoIdProp]);

  const toggle = () => setModal(!modal);

  const toggleUpdated = () => {
    setUpdated(!updated);
    setReRender(!reRender);
  };

  async function fetchEspecialidadesOptions() {
    try {
      const response = await axiosInstance.get(`/especialidades`);
      if (response.status === 200) {
        for (let i = 0; i < response.data.body.length; i++) {
          setEspecialidadesOptions((prev) => [
            ...prev,
            {
              label: response.data.body[i].especialidad,
              value: response.data.body[i].id,
            },
          ]);
        }
      }
    } catch (error) {}
  }

  async function fetchPsicologo(psicologoId) {
    try {
      const response = await axiosInstance.get(`/psicologos/${psicologoId}`);
      if (response.status === 200) {
        setPsicologo(response.data.body);
        setSobreMi(response.data.body.sobre_mi);
        setPrimeraSesion(response.data.body.primera_sesion);
        setEstiloTerapia(response.data.body.estilo_terapia);
        setUserData({
          id: response.data.body.id,
          role: "psicologo",
          profile_picture_url: response.data.body.profile_picture_url,
        });
        setLoading(false);
        setModal(!isProfileComplete());
      }
    } catch (error) {
      setError(error);
    }
  }

  async function fetchIdiomas(psicologoId) {
    try {
      const response = await axiosInstance.get(
        `/psicologos/${psicologoId}/idiomas`
      );
      if (response.status === 200) {
        setIdiomas(response.data.body);
      }
    } catch (error) {
      setError(error);
    }
  }

  async function fetchEspecialidades(psicologoId) {
    try {
      const response = await axiosInstance.get(
        `/psicologos/${psicologoId}/especialidades`
      );
      if (response.status === 200) {
        for (let i = 0; i < response.data.body.length; i++) {
          setEspecialidades((prev) => [
            ...prev,
            {
              label: response.data.body[i].especialidad,
              value: response.data.body[i].id,
            },
          ]);
        }
      }
    } catch (error) {
      setError(error);
    }
  }

  async function fetchResenias(psicologoId) {
    try {
      const response = await axiosInstance.get(
        `/psicologos/${psicologoId}/resenias`
      );
      if (response.status === 200) {
        setResenias(response.data.body);
      }
    } catch (error) {
      setError(error);
    }
  }

  async function handleIdiomaChange(e) {
    const { value } = e.target;
    if (e.target.checked) {
      setIdiomas((prev) => [...prev, value]);
    }
    if (!e.target.checked) {
      setIdiomas(idiomas.filter((idioma) => idioma !== value));
    }
  }

  async function handleProfileUpdate() {
    setUpdateLoading(true);
    const idiomasIdsArr = [];
    const especialidadesIdsArr = [];

    for (const especialidad of especialidades) {
      especialidadesIdsArr.push(especialidad.value);
    }

    for (const idioma of idiomas) {
      if (idioma === "Español") {
        idiomasIdsArr.push(1);
      }
      if (idioma === "Inglés") {
        idiomasIdsArr.push(2);
      }
    }

    try {
      const response = await axiosInstance.patch(`/psicologos/${psicologoId}`, {
        sobre_mi: sobreMi,
        primera_sesion: primeraSesion,
        estilo_terapia: estiloTerapia,
        idiomasIdsArr: idiomasIdsArr,
        especialidadesIdsArr: especialidadesIdsArr,
      });
      if (response.status === 200) {
        setUpdated(true);
      }
      setUpdateLoading(false);
    } catch (error) {
      setError("Error al guardar el perfil.");
    }
  }

  function isProfileComplete() {
    if (
      psicologoIdProp &&
      !loading &&
      sobreMi &&
      primeraSesion &&
      estiloTerapia &&
      idiomas.length &&
      especialidades.length
    ) {
      return true;
    }
    return false;
  }

  async function handleProfilePictureSave() {
    setUpdated(true);
  }

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <>
      <Helmet>
        <title>Mindly | {psicologo.nombre + " " + psicologo.apellido}</title>
        <meta name="description" content="Psicologo" />
      </Helmet>

      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {loading && (
        <div className=" psicologo-info-item w-75 m-auto mt-4">
          <PsicologoLoader />
        </div>
      )}
      {psicologoIdProp && (
        <>
          {!isProfileComplete() && (
            <Modal isOpen={modal} toggle={toggle}>
              <ModalBody className="m-auto p-4 d-flex flex-column">
                <span>Completa tu perfil para que sea público! 🚀</span>
                <Button color="primary" onClick={toggle} className="mt-4">
                  Entendido!
                </Button>
              </ModalBody>
            </Modal>
          )}
        </>
      )}
      {updated && (
        <Modal isOpen={updated} toggle={toggleUpdated}>
          <ModalBody className="m-auto p-4">
            <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
            <span>Los datos se actualizaron correctamente 🚀</span>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleUpdated}>
              Genial!
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {psicologo && !loading && (
        <>
          <div className="psicologo-section-container">
            <div className="psicologo-info-items-container">
              <div className="psicologo-info-item general">
                <div className="psicologo-info-container">
                  <div className="psicologo-info-header">
                    <div className="psicologo-info-header-primary">
                      {psicologoIdProp ? (
                        <div className="psicologo-info-header-img">
                          <ProfilePictureUpload
                            userData={userData}
                            onProfilePictureSave={handleProfilePictureSave}
                          />
                        </div>
                      ) : (
                        <div className="psicologo-info-header-img">
                          {profilePictureLoaded ? null : (
                            <img
                              src={defaultProfilePicture}
                              alt="Cargando..."
                              className="img-fluid rounded-circle border mx-2"
                            ></img>
                          )}
                          <img
                            style={
                              profilePictureLoaded ? {} : { display: "none" }
                            }
                            src={psicologo.profile_picture_url}
                            alt="psicologo"
                            className="img-fluid rounded-circle border mx-2"
                            onLoad={() => setprofilePictureLoaded(true)}
                          />
                        </div>
                      )}

                      <div className="psicologo-info-header-text">
                        <h5>{psicologo.nombre + " " + psicologo.apellido}</h5>
                        <p className="text-secondary">Psicología</p>
                        <span className="badge rounded-pill bg-soft-primary">
                          <b>Online</b>
                        </span>
                      </div>
                    </div>
                    <div className="psicologo-info-header-secondary">
                      <div className="psicologo-info-header-secondary-experiencia">
                        <p>
                          Experiencia:{" "}
                          <strong>{psicologo.anos_experiencia} Años</strong>
                        </p>
                      </div>
                      {psicologoIdProp && (
                        <div className="psicologo-info-header-secondary-estado">
                          <p className="d-flex">
                            Perfil:{" "}
                            {psicologo.is_active ? (
                              <p className="text-success mx-1">Completo</p>
                            ) : (
                              <>
                                {!isProfileComplete() ? (
                                  <p className="text-danger mx-1">Incompleto</p>
                                ) : (
                                  <>
                                    <p className="text-warning mx-1 mb-0">
                                      Completo, pendiente de verificación.{" "}
                                      <br />
                                      <span
                                        className="text-secondary"
                                        style={{ fontSize: 15 }}
                                      >
                                        (Lo verificaremos en las próximas 24hs)
                                      </span>
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      )}
                      <div className="psicologo-info-header-secondary-satisfaccion">
                        {satisfaccion && satisfaccion > 2 ? (
                          <p>
                            Satisfacción{" "}
                            <strong>
                              {Math.trunc((satisfaccion / 5) * 100)}%
                            </strong>
                          </p>
                        ) : (
                          <p>
                            Satisfacción <strong>{100}%</strong>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="psicologo-info-item">
                <div className="psicologo-info-container">
                  <div className="psicologo-info-title">
                    <i className="bx bxl-meta"></i> <h6>Sobre mí</h6>
                  </div>
                  <div className="psicologo-info-content">
                    {!psicologoIdProp ? (
                      <p className="text-secondary">{sobreMi}</p>
                    ) : (
                      <EdiText
                        type="textarea"
                        className="text-secondary"
                        editButtonContent={
                          <i
                            className="bx bx-edit text-secondary"
                            style={{ fontSize: "1.5rem" }}
                          ></i>
                        }
                        cancelButtonContent={
                          <i className="bx bx-x" style={{ color: "red" }}></i>
                        }
                        saveButtonContent={
                          <i
                            className="bx bx-check"
                            style={{ color: "green" }}
                          ></i>
                        }
                        editOnViewClick={true}
                        editButtonClassName="btn btn-link text-secondary"
                        value={sobreMi}
                        onSave={setSobreMi}
                        hideIcons={true}
                        validation={(val) => val.length < 450}
                        validationMessage="El texto no puede superar los 450 caracteres."
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="psicologo-info-item">
                <div className="psicologo-info-container">
                  <div className="psicologo-info-title">
                    <i className="bx bx-message-square-check"></i>{" "}
                    <h6>¿Qué esperar en la primera sesión?</h6>
                  </div>
                  <div className="psicologo-info-content">
                    {!psicologoIdProp ? (
                      <p className="text-secondary">{primeraSesion}</p>
                    ) : (
                      <EdiText
                        type="textarea"
                        className="text-secondary"
                        editButtonContent={
                          <i
                            className="bx bx-edit text-secondary"
                            style={{ fontSize: "1.5rem" }}
                          ></i>
                        }
                        cancelButtonContent={
                          <i className="bx bx-x" style={{ color: "red" }}></i>
                        }
                        saveButtonContent={
                          <i
                            className="bx bx-check"
                            style={{ color: "green" }}
                          ></i>
                        }
                        editOnViewClick={true}
                        editButtonClassName="btn btn-link text-secondary"
                        value={primeraSesion}
                        onSave={setPrimeraSesion}
                        hideIcons={true}
                        validation={(val) => val.length < 600}
                        validationMessage="El texto no puede superar los 600 caracteres."
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="psicologo-info-item">
                <div className="psicologo-info-container">
                  <div className="psicologo-info-title">
                    <i className="bx bx-brain"></i> <h6>Estilo de terapia</h6>
                  </div>
                  <div className="psicologo-info-content">
                    {!psicologoIdProp ? (
                      <p className="text-secondary">{estiloTerapia}</p>
                    ) : (
                      <EdiText
                        type="textarea"
                        className="text-secondary"
                        editButtonContent={
                          <i
                            className="bx bx-edit text-secondary"
                            style={{ fontSize: "1.5rem" }}
                          ></i>
                        }
                        cancelButtonContent={
                          <i className="bx bx-x" style={{ color: "red" }}></i>
                        }
                        saveButtonContent={
                          <i
                            className="bx bx-check"
                            style={{ color: "green" }}
                          ></i>
                        }
                        editOnViewClick={true}
                        editButtonClassName="btn btn-link text-secondary"
                        value={estiloTerapia}
                        onSave={setEstiloTerapia}
                        hideIcons={true}
                        validation={(val) => val.length < 600}
                        validationMessage="El texto no puede superar los 600 caracteres."
                      />
                    )}
                  </div>
                </div>
              </div>

              {especialidades && (
                <div className="psicologo-info-item especialidades">
                  <div className="psicologo-info-container">
                    <div className="psicologo-info-title">
                      <i className="bx bx-donate-heart"></i>{" "}
                      <h6>Especialidades</h6>
                    </div>
                    <div className="psicologo-info-edit">
                      {!editingEspecialidades && (
                        <div className="psicologo-info-content especialidades">
                          {especialidades.map((especialidad) => (
                            <div
                              className="especialidad-item"
                              key={especialidad.id}
                            >
                              <span className="badge rounded-pill bg-soft-primary">
                                <b>{especialidad.label}</b>
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                      {psicologoIdProp && editingEspecialidades && (
                        <>
                          <Select
                            isMulti
                            options={especialidadesOptions}
                            defaultValue={especialidades}
                            onChange={setEspecialidades}
                          />
                        </>
                      )}
                      {psicologoIdProp && (
                        <button className="btn btn-link text-secondary">
                          <i
                            className="bx bx-edit text-secondary"
                            style={{ fontSize: "1.5rem" }}
                            onClick={() =>
                              setEditingEspecialidades(!editingEspecialidades)
                            }
                          ></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {idiomas != null && (
                <div className="psicologo-info-item">
                  <div className="psicologo-info-container">
                    <div className="psicologo-info-title">
                      <i className="bx bx-user-voice"></i> <h6>Idiomas</h6>
                    </div>
                    <div className="idiomas-container-edit">
                      <div>
                        {(idiomas.includes("Español") || editingIdioma) && (
                          <div className="psicologo-info-content idiomas">
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/1280px-Flag_of_Spain.svg.png"
                              alt="Español"
                              width="30"
                              height="20"
                            />
                            <p className="text-secondary">Español</p>
                            {editingIdioma && (
                              <input
                                name="idiomaES"
                                value={"Español"}
                                type="checkbox"
                                className="m-3"
                                defaultChecked={
                                  idiomas.includes("Español") ? true : false
                                }
                                onClick={handleIdiomaChange}
                              />
                            )}
                          </div>
                        )}
                        {(idiomas.includes("Inglés") || editingIdioma) && (
                          <div className="psicologo-info-content idiomas">
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg"
                              alt="Inglés"
                              width="30"
                              height="20"
                            />
                            <p className="text-secondary">Inglés</p>
                            {editingIdioma && (
                              <input
                                name="idiomaEN"
                                value={"Inglés"}
                                type="checkbox"
                                className="m-3"
                                defaultChecked={
                                  idiomas.includes("Inglés") ? true : false
                                }
                                onClick={handleIdiomaChange}
                              />
                            )}
                          </div>
                        )}
                      </div>

                      {psicologoIdProp && !editingIdioma && (
                        <button className="btn btn-link text-secondary">
                          <i
                            className="bx bx-edit text-secondary"
                            style={{ fontSize: "1.5rem" }}
                            onClick={() => setEditingIdioma(!editingIdioma)}
                          ></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {resenias.length > 0 && !psicologoIdProp && (
                <div className="psicologo-info-item opiniones">
                  <div className="psicologo-info-container">
                    <div className="psicologo-info-title">
                      <i className="bx bx-message-square-dots"></i>{" "}
                      <h6>Opiniones</h6>
                    </div>
                    <div className="psicologo-info-content">
                      {resenias.map((resenia) => (
                        <div className="opiniones-item" key={resenia.id}>
                          <div className="opiniones-header">
                            <i className="bx bx-message-alt-dots"></i>{" "}
                            <p>
                              {new Date(resenia.fecha).toLocaleString("es-ES", {
                                dateStyle: "medium",
                                timeZone: userTimeZone,
                              })}
                            </p>
                          </div>
                          <div className="opiniones-body">
                            <p className="text-secondary">
                              {resenia.descripcion}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!psicologoIdProp && (
            <div className="psicologo-agendarBtn-container">
              <Link
                to={`/mi-espacio/especialistas/${psicologo.id}/agendar`}
                className="btn btn-primary btn-agendar"
              >
                {" "}
                <i className="bx bx-calendar"></i> Agendar cita
              </Link>
            </div>
          )}

          {psicologoIdProp && (
            <div className="psicologo-agendarBtn-container">
              <button
                className="btn btn-secondary d-flex"
                onClick={handleProfileUpdate}
              >
                {" "}
                {updateLoading ? (
                  <Spinner color="light" size="sm" className="my-0 mx-1" />
                ) : (
                  <i className="bx bx-check-square m-1"></i>
                )}
                Guardar cambios
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PsicologoPage;
