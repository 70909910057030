// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import contactDetail from "../../assets/images/contact-detail.jpg";

class PageContactDetail extends Component {
  constructor(props) {
    super(props);
    this.sendMail.bind(this);
    this.sendMailPac.bind(this);
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  };

  sendMail() {
    window.location.href = "mailto:psicologos@mindly.la";
  }

  sendMailPac() {
    window.location.href = "mailto:pacientes@mindly.la";
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="vh-100 d-flex align-items-center"
          style={{ background: `url(${contactDetail}) center center` }}
        >
          <div className="bg-overlay bg-overlay-white"></div>
          <div className="container" style={{ height: "80%" }}>
            <Row className="align-items-center">
              <Col lg={{ size: 6, offset: 6 }} md={{ size: 7, offset: 5 }}>
                <div className="title-heading mt-5 pt-4">
                  <h1 className="heading">Estamos para lo que necesites</h1>
                  <p className="text-dark">
                    Contactate con nosotros para cualquier duda o consulta.
                  </p>
                  <div className="d-flex flex-wrap contact-detail align-items-center mt-4">
                    <div className="m-2">
                      <h4 className="title fw-bold mt-1 mb-2">Pacientes</h4>
                      <div className="d-flex contact-detail align-items-center mt-3">
                        <div className="icon">
                          <FeatherIcon
                            icon="mail"
                            className="fea icon-m-md text-dark me-3"
                          />
                        </div>
                        <div className="flex-1 content">
                          <h5 className="title fw-bold mb-0">Email</h5>
                          <Link
                            to="#"
                            onClick={this.sendMailPac}
                            className="text-primary"
                          >
                            pacientes@mindly.la
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex contact-detail align-items-center mt-3">
                        <div className="icon">
                          <FeatherIcon
                            icon="message-circle"
                            className="fea icon-m-md text-dark me-3"
                          />
                        </div>
                        <div className="flex-1 content">
                          <h5 className="title fw-bold mb-0">WhatsApp</h5>
                          <a
                            href="https://wa.me/message/N6IPALHV5ZX2C1"
                            className="text-primary"
                            target="_blank"
                            rel="noreferrer"
                          >
                            +1 (202) 773-5260
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="m-2">
                      <h4 className="title fw-bold mt-1 mb-2">Psicólogos</h4>
                      <div className="d-flex contact-detail align-items-center mt-3 ">
                        <div className="icon">
                          <FeatherIcon
                            icon="mail"
                            className="fea icon-m-md text-dark me-3"
                          />
                        </div>
                        <div className="flex-1 content">
                          <h5 className="title fw-bold mb-0">Email</h5>
                          <Link
                            to="#"
                            onClick={this.sendMail}
                            className="text-primary"
                          >
                            psicologos@mindly.la
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex contact-detail align-items-center mt-3">
                        <div className="icon">
                          <FeatherIcon
                            icon="message-circle"
                            className="fea icon-m-md text-dark me-3"
                          />
                        </div>
                        <div className="flex-1 content">
                          <h5 className="title fw-bold mb-0">WhatsApp</h5>
                          <a
                            href="https://wa.me/message/N6IPALHV5ZX2C1"
                            className="text-primary"
                            target="_blank"
                            rel="noreferrer"
                          >
                            +1 (202) 773-5260
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled social-icon mb-0 mt-4">
                    {/* <li className="list-inline-item">
                      <a
                        href="https://web.facebook.com/people/Mindly/61550195502102/"
                        className="rounded"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FeatherIcon
                          icon="facebook"
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>{" "} */}
                    <li className="list-inline-item">
                      <a
                        href="https://www.instagram.com/mindly.la/"
                        target="_blak"
                        rel="noreferrer"
                        className="rounded"
                      >
                        <FeatherIcon
                          icon="instagram"
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>{" "}
                    <li className="list-inline-item">
                      <a
                        href="https://www.linkedin.com/company/mindly-la/"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded"
                      >
                        <FeatherIcon
                          icon="linkedin"
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>{" "}
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default PageContactDetail;
