import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { BsFilter } from "react-icons/bs";

function FilterCitas({ estado, setEstado }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onEstadoChange = (e) => setEstado(e.target.value);

  const clearFilter = () => setEstado(null);

  let estadoDescripcion;
  switch (estado) {
    case 1:
      estadoDescripcion = "Pendiente de pago";
      break;
    case 2:
      estadoDescripcion = "Confirmadas";
      break;
    case 3:
      estadoDescripcion = "Completadas";
      break;
    case 4:
      estadoDescripcion = "Cancelada";
      break;
    default:
      estadoDescripcion = "Todas las citas";
      break;
  }

  return (
    <div>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle caret color="primary" className="estado-filter">
          <BsFilter /> <p>{estadoDescripcion}</p>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem active={!estado} onClick={clearFilter}>
            Todas las citas
          </DropdownItem>
          <DropdownItem active={estado === 1} onClick={() => setEstado(1)}>
            Pendiente de pago
          </DropdownItem>
          <DropdownItem active={estado === 2} onClick={() => setEstado(2)}>
            Confirmadas
          </DropdownItem>
          <DropdownItem active={estado === 3} onClick={() => setEstado(3)}>
            Completadas
          </DropdownItem>
          <DropdownItem active={estado === 4} onClick={() => setEstado(4)}>
            Cancelada
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
}

export default FilterCitas;
