import React, { useState, useRef, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import CitaCard from "./CitaCard";
import CitasFilter from "./CitasFilter";
import autoAnimate from "@formkit/auto-animate";

function CitasContainer({ citas }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [estado, setEstado] = useState("");
  const itemsPerPage = 3;

  //Animations
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 200 });
  }, [parent]);

  // Filter the citas by estado
  const filteredCitas = estado
    ? citas.filter((cita) => cita.estado_id === estado)
    : citas;

  const totalPages = Math.ceil(filteredCitas.length / itemsPerPage);

  // Get the current page of items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCitas
    .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
    .slice(indexOfFirstItem, indexOfLastItem);

  // Handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="citas-section-container">
      {" "}
      <div className="citas-title">
        <h5>Mis citas</h5>
        <CitasFilter estado={estado} setEstado={setEstado} />
      </div>
      <div className="citas-container" ref={parent}>
        {citas.length > 0 ? (
          <div className="citas-container-items">
            {currentItems.map((cita) => (
              <CitaCard key={cita.id} cita={cita} />
            ))}
          </div>
        ) : (
          <p className="no-citas-text text-muted">No hay citas que mostrar</p>
        )}
      </div>
      <div className="citas-pagination-container">
        {totalPages > 1 && (
          <Pagination
            aria-label="Citas pagination"
            className="citas-pagination"
          >
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink
                previous
                onClick={() => handlePageChange(currentPage - 1)}
              />
            </PaginationItem>
            {Array.from({ length: totalPages }, (_, i) => {
              const pageNumber = i + 1;
              return (
                <PaginationItem
                  active={pageNumber === currentPage}
                  key={pageNumber}
                >
                  <PaginationLink onClick={() => handlePageChange(pageNumber)}>
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              );
            })}
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink
                next
                onClick={() => handlePageChange(currentPage + 1)}
              />
            </PaginationItem>
          </Pagination>
        )}
      </div>
    </div>
  );
}

export default CitasContainer;
