import React from "react";
import { Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";

//Import Images
import img1 from "../../assets/images/client/makers.png";
import img2 from "../../assets/images/client/amazon.png";
import img3 from "../../assets/images/client/google.png";
import img4 from "../../assets/images/client/elPais.png";

const Counter = () => {
  const counters = [
    {
      title: "En satisfacción",
      end: 98.0,
      postFix: ".74%",
      desc: "De pacientes",
    },
    {
      title: "Especialistas",
      end: 16,
      postFix: "+",
      desc: "Altamente calificados",
    },
    {
      title: "Vidas impactadas",
      end: 1140,
      postFix: "+",
      desc: "En todo el mundo",
    },
  ];
  return (
    <React.Fragment>
      <section className="section pt-0 mt-5">
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <div className="rounded shadow bg-white p-4">
              <Row id="counter">
                {counters.map((counter, key) => (
                  <Col md={4} key={key}>
                    <div className="counter-box text-center">
                      <h1 className="mb-0 mt-3" style={{ fontSize: 34 }}>
                        <span className="counter-value">
                          <CountUp end={counter.end} duration={8} />
                        </span>
                        {counter.postFix}
                      </h1>
                      <h5 className="counter-head mb-1">{counter.title}</h5>
                      <p className="text-muted mb-0">{counter.desc}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="row justify-content-center pt-4">
              <Col
                lg={2}
                md={2}
                xs={6}
                className="text-center company-icon-container py-4 pb-md-0"
              >
                <img
                  src={img1}
                  className="avatar company-icon avatar-ex-sm grayscale"
                  alt="makers logo"
                />
              </Col>

              <Col
                lg={2}
                md={2}
                xs={6}
                className="text-center company-icon-container py-4 pb-md-0"
              >
                <img
                  src={img2}
                  className="avatar company-icon avatar-ex-sm grayscale"
                  alt="aws logo"
                />
              </Col>

              <Col
                lg={2}
                md={2}
                xs={6}
                className="text-center company-icon-container py-4 pb-md-0"
              >
                <img
                  src={img3}
                  className="avatar company-icon avatar-ex-sm grayscale"
                  alt="google for startups logo"
                />
              </Col>

              <Col
                lg={2}
                md={2}
                xs={6}
                className="text-center company-icon-container py-4 pb-md-0"
              >
                <img
                  src={img4}
                  className="avatar company-icon avatar-ex-sm grayscale w-50"
                  alt="latitud logo"
                />
              </Col>
            </div>
          </div>
          <div className="feature-posts-placeholder bg-primary bg-gradient"></div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Counter;
