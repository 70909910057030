import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

// import images
import online1 from "../../assets/images/course/online/ab01.jpg";
import online2 from "../../assets/images/course/online/ab02.png";
import online3 from "../../assets/images/course/online/ab03.jpg";

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Container className="psi-landing-about mt-5" id="psicologos">
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <Row className="align-items-center">
                <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                    <CardBody className="p-0">
                      <img
                        src={online1}
                        className="img-fluid"
                        alt="psicologo"
                      />
                      <div className="overlay-work bg-dark"></div>
                      <div className="content"></div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={6} xs={6}>
                  <Row>
                    <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                      <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                        <CardBody className="p-0">
                          <img
                            src={online2}
                            className="img-fluid"
                            alt="psicologo"
                          />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content"></div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg={12} md={12} className="mt-4 pt-2">
                      <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                        <CardBody className="p-0">
                          <img
                            src={online3}
                            className="img-fluid"
                            alt="psicologo"
                          />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content"></div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ms-lg-4">
                <div className="section-title mb-4 pb-2">
                  <h2 className="title mb-4 mt-2">
                    Mindly, un espacio seguro para cuidar de ti
                  </h2>
                  <p className="text-muted para-desc">
                    Tu bienestar es <strong>nuestra prioridad.</strong>
                  </p>
                  <p className="text-muted para-desc">
                    Es por eso que nos tomamos <strong>muy en serio</strong> la
                    selección de nuestros psicólogos.
                  </p>
                  <p className="text-muted para-desc mb-0">
                    Cada uno pasa por un <strong>riguroso proceso</strong> de
                    selección para asegurarte que estés en las{" "}
                    <strong>mejores manos.</strong> 💜
                  </p>
                </div>

                <ul className="list-unstyled text-muted">
                  <li className="mb-0">
                    <span className="text-primary h4 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Apoyo psicológico profesional, cálido y humano.{" "}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h4 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Lleva tu terapia a tu propio ritmo, desde donde estés.{" "}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h4 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Citas seguras y privadas desde cualquier dispositivo.{" "}
                  </li>
                </ul>

                <div className="watch-video mt-4 pt-2">
                  <Link to="/register" className="btn btn-primary m-1">
                    Comienza aquí{" "}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link>{" "}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
