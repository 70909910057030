import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import Creativity from "../../assets/images/illustrator/psicologo.png";

export default class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="title-heading mt-4">
                  <h1 className="display-4 fw-bold mb-3">
                    Te ayudamos a crecer con terapia online
                  </h1>
                  <p className="text-muted">
                    Hoy más que nunca el mundo necesita psicologos como vos.
                  </p>
                </div>
                <div className="btn btn-primary mt-3 unirseBtn">
                  <a
                    href="https://fzaqwxs849j.typeform.com/to/AMVX5fVV"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                  >
                    Unirse al equipo
                  </a>
                </div>
              </Col>

              <Col md={6}>
                <img src={Creativity} alt="" className="psi-landing-img" />
              </Col>
            </Row>
          </Container>
        </section>

        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
