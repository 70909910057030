import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardImg, Col } from "reactstrap";

const ArticleCard = ({ article, key }) => {
  return (
    <>
      <Col lg="4" md="6" className="mb-4 pb-2" key={key} name="blog">
        <Card className="blog rounded border-0 shadow overflow-hidden">
          <div className="position-relative">
            <CardImg top src={article.image} className="rounded-top" alt="" />
            <div className="overlay rounded-top bg-dark"></div>
          </div>
          <CardBody className="content">
            <h5>
              <Link to={article.link} className="card-title title text-dark">
                {article.title}
              </Link>
            </h5>
            <div className="post-meta d-flex justify-content-between mt-3">
              <ul className="list-unstyled mb-0">
                <li className="list-inline-item me-2  mb-0">
                  <Link to="#" className="text-muted like">
                    <i className="mdi mdi-heart-outline me-1"></i>
                    {article.like}
                  </Link>
                </li>{" "}
              </ul>
              <Link to={article.link} className="text-muted readmore">
                Leer más <i className="uil uil-angle-right-b"></i>
              </Link>
            </div>
          </CardBody>
          <div className="author">
            <small className="text-light user d-block">
              <i className="mdi mdi-account"></i> {article.autor}
            </small>
            <small className="text-light date">
              <i className="mdi mdi-calendar-check"></i> {article.date}
            </small>
          </div>
        </Card>
      </Col>
    </>
  );
};

export default ArticleCard;
