import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/manejoEmociones/manejoEmociones-min.jpg";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Manejo de Emociones: ¿Qué es?</title>
          <meta
            name="description"
            content="El manejo de emociones es el proceso de ser consciente, comprender y regular nuestras propias emociones. ¡Descubre como lograrlo! "
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img
                    src={standard}
                    className="img-fluid"
                    alt="stickers de emojis representando emociones"
                  />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Emociones y Sentimientos
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        Manejo de emociones: Reconociendo y Transformando
                        Nuestras Emociones
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 1 de
                          Diciembre, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="mt-3">
                      En la complejidad de la vida cotidiana, nuestras emociones
                      juegan un papel crucial en la toma de decisiones y, en
                      última instancia, en nuestra calidad de vida.
                    </p>
                    <p>
                      El <strong>manejo de emociones</strong> se presenta como
                      una habilidad fundamental para navegar eficientemente por
                      nuestras vidas.
                    </p>
                    <p>
                      En este artículo, exploraremos el manejo de nuestros
                      emociones,{" "}
                      <strong className="text-primary">qué implica</strong> y{" "}
                      <strong className="text-primary">cómo lograrlo.</strong>
                    </p>
                    {/* <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote> */}
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Qué es el Manejo de Emociones?
                    </h2>
                    <p>
                      El <strong>manejo de emociones es</strong> el proceso de
                      ser consciente, comprender y regular nuestras propias
                      emociones, así como también de manejar las emociones de
                      los demás de manera efectiva.
                    </p>
                    <p>
                      Se trata de una habilidad emocional clave que va más allá
                      de simplemente sentir y reaccionar; implica una
                      comprensión profunda de nuestras emociones, cómo influyen
                      en nuestro comportamiento y cómo podemos utilizar esta
                      información para mejorar nuestra vida y nuestras
                      relaciones.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Señales de que Necesitas Aprender sobre Manejo de
                      Emociones
                    </h2>
                    <p>
                      Reconocer la necesidad de mejorar el manejo de emociones
                      puede ser el <strong>primer paso</strong> hacia una vida
                      más equilibrada y plena. Algunas señales de que necesitas
                      aprender sobre manejo de emociones incluyen:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Respuestas impulsivas
                    </h3>
                    <p>
                      Actuar de manera impulsiva ante situaciones emocionales,
                      sin pensar en las consecuencias a largo plazo.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Conflictos interpersonales recurrentes
                    </h3>
                    <p>
                      Experimentar conflictos constantes en tus relaciones
                      personales, ya sea en el ámbito laboral, familiar o
                      social.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Dificultad para expresar emociones
                    </h3>
                    <p>
                      Encontrar dificultades para comunicar eficazmente tus
                      emociones a los demás, lo que puede llevar a malentendidos
                      y desconexión emocional.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Altibajos emocionales frecuentes
                    </h3>
                    <p>
                      Experimentar cambios emocionales bruscos y frecuentes que
                      afectan tu estabilidad emocional y bienestar general.
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      5. Autoconciencia limitada
                    </h3>
                    <p>
                      Carecer de una comprensión profunda de tus propias
                      emociones, sus orígenes y cómo afectan tu comportamiento.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Ejemplos del Manejo de Emociones que Puedes Aplicar
                    </h2>
                    {/* <img
                      src={superation}
                      className="img-fluid m-auto d-block"
                      style={{ maxHeight: "40vh" }}
                      alt="hombre superando heridas de la infancia"
                    /> */}
                    <p>
                      El manejo de emociones se manifiesta en una variedad de
                      situaciones cotidianas. Algunos ejemplos concretos
                      incluyen:
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Práctica de la empatía
                    </h3>
                    <p>
                      Ponerse en el lugar de otra persona para comprender sus
                      emociones y reacciones, fomentando así una comunicación
                      más efectiva.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Autocontrol
                    </h3>
                    <p>
                      Mantener la calma y pensar antes de actuar en momentos de
                      frustración, evitando reacciones impulsivas que podrían
                      tener consecuencias negativas.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Comunicación efectiva
                    </h3>
                    <p>
                      Expresar tus emociones de manera clara y respetuosa,
                      promoviendo una comprensión mutua y reduciendo el riesgo
                      de conflictos.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Adaptabilidad
                    </h3>
                    <p>
                      Ser capaz de adaptarse a situaciones cambiantes sin dejar
                      que las emociones negativas dicten tu respuesta,
                      permitiendo una toma de decisiones más objetiva.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      5. Manejo del estrés
                    </h3>
                    <p>
                      Desarrollar estrategias saludables para lidiar con el
                      estrés, como la meditación, el ejercicio o la búsqueda de
                      apoyo emocional cuando sea necesario.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Qué Pasa si no Manejas tus Emociones?
                    </h2>

                    <p>
                      La falta de manejo adecuado de las emociones puede tener
                      <strong> consecuencias significativas</strong> en diversas
                      áreas de la vida. Algunos de los riesgos asociados
                      incluyen:
                    </p>
                    <ul>
                      <li>
                        <strong>Deterioro de las relaciones:</strong> La
                        incapacidad para manejar las emociones puede dar lugar a
                        conflictos constantes, creando barreras en las
                        relaciones personales y laborales.
                      </li>
                      <li>
                        <strong>Impacto en la salud mental:</strong> La
                        acumulación de emociones no gestionadas puede contribuir
                        al estrés crónico, la ansiedad y la depresión, afectando
                        negativamente la salud mental.
                      </li>
                      <li>
                        <strong>Decisiones impulsivas:</strong> La falta de
                        autocontrol puede llevar a decisiones impulsivas que
                        tienen consecuencias a largo plazo en áreas como las
                        finanzas, la carrera o las relaciones personales.
                      </li>
                      <li>
                        <strong>Aislamiento social:</strong> La incapacidad para
                        expresar y comprender emociones puede llevar a un
                        aislamiento social, ya que los demás pueden percibir la
                        falta de empatía y conexión emocional.
                      </li>
                    </ul>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Beneficios del Manejo de Emociones
                    </h2>

                    <p>
                      Por otro lado, desarrollar habilidades sólidas de manejo
                      de emociones conlleva una serie de{" "}
                      <strong>beneficios notables:</strong>
                    </p>

                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      1. Mejoras en las relaciones
                    </h3>
                    <p>
                      La capacidad de comprender y manejar las emociones propias
                      y ajenas fortalece las relaciones interpersonales,
                      promoviendo la empatía y la conexión emocional.
                    </p>
                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      2. Bienestar emocional
                    </h3>
                    <p>
                      El manejo consciente de las emociones reduce el estrés y
                      promueve un estado de bienestar emocional, contribuyendo a
                      una salud mental más sólida.
                    </p>
                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      3. Toma de decisiones informada
                    </h3>
                    <p>
                      La autoconciencia emocional facilita la toma de decisiones
                      más informada y reflexiva, evitando impulsos que podrían
                      tener consecuencias negativas.
                    </p>
                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      4. Crecimiento personal
                    </h3>
                    <p>
                      El manejo de emociones es un componente clave del
                      crecimiento personal, permitiendo una mayor comprensión de
                      uno mismo y la posibilidad de trabajar hacia una versión
                      más equilibrada y auténtica.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Cómo Manejar las Emociones
                    </h2>

                    <p>
                      Desarrollar habilidades efectivas de manejo de emociones
                      implica un enfoque consciente y continuo. Aquí hay algunas
                      estrategias prácticas:
                    </p>

                    <ul>
                      <li>
                        <strong>Practica la autoconciencia:</strong> Tómate un
                        momento para identificar cómo te sientes y qué
                        desencadenó esa emoción. Esto te ayudará a comprender
                        mejor tus reacciones y a manejarlas de manera más
                        efectiva.
                      </li>
                      <li>
                        <strong>Acepta tus emociones:</strong> No juzgues tus
                        emociones como buenas o malas. En su lugar, acéptalas
                        como parte de tu experiencia humana y permítete
                        sentirlas sin culpa.
                      </li>
                      <li>
                        <strong>Practica la empatía:</strong> Intenta ponerte en
                        el lugar de los demás para comprender sus emociones y
                        reacciones. Esto te ayudará a desarrollar una mayor
                        comprensión y conexión emocional.
                      </li>
                      <li>
                        <strong>Toma un descanso:</strong> Si te sientes
                        abrumado por una emoción negativa, tómate un tiempo para
                        calmarte y volver a un estado de calma antes de
                        responder.
                      </li>
                      <li>
                        <strong>Busca apoyo:</strong> Si te sientes abrumado,
                        busca apoyo emocional de un amigo, familiar o
                        profesional de la salud mental.
                      </li>
                    </ul>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Conclusión
                    </h2>
                    <p>
                      En resumen, el manejo de emociones es una{" "}
                      <strong> habilidad esencial</strong> que afecta todos los
                      aspectos de nuestras vidas. Desde nuestras relaciones
                      hasta nuestras decisiones diarias, nuestras emociones
                      están intrínsecamente conectadas con nuestro bienestar
                      general.
                    </p>
                    <p>
                      Reconocer la necesidad de mejorar nuestras habilidades de
                      manejo de emociones es un paso crucial hacia una vida más
                      equilibrada y satisfactoria.
                    </p>
                    <p>
                      La búsqueda de <strong> ayuda profesional</strong> y el
                      compromiso personal con el proceso de curación son pasos
                      fundamentales hacia una vida más plena y equilibrada.
                    </p>

                    <p>
                      Si tú o alguien que conoces está buscando apoyo para el
                      manejo de sus emociones, en{" "}
                      <a
                        href="https://www.mindly.la/home"
                        className="text-primary"
                      >
                        Mindly
                      </a>
                      , tenemos a un grupo de{" "}
                      <strong>psicólogos expertos</strong> en salud mental
                      listos para ofrecer el servicio de mayor calidad. <br />{" "}
                      <br />
                      ¡Agenda tu sesión hoy mismo! <br></br> <br></br> La{" "}
                      <strong>primera sesión</strong> cuenta con un
                      <strong> 50% OFF</strong>.{" "}
                      <Link
                        to="/register"
                        className="text-primary text-decoration-underline"
                      >
                        ¡Regístrate ahora!
                      </Link>
                      <br></br>
                    </p>
                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>122
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
