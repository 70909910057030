// React Basic and Bootstrap
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Helmet } from "react-helmet";

//Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import recoveryimg from "../../assets/images/user/recovery.svg";
import logo from "../../assets/images/logo-light.png";

const PageRecoveryPassword = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string("Ingresa tu email")
        .email("Ingresa un email valido")
        .required("El email es requerido"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      values.email = values.email.toLowerCase();
      try {
        const response = await axiosInstance.post("/auth/recovery", {
          email: values.email,
        });
        if (response.status === 200) {
          setMessage(
            "Se ha enviado un correo con las instrucciones para recuperar tu contraseña"
          );
        } else {
          setMessage("Ha ocurrido un error validando su información");
        }
      } catch (error) {
        setMessage("Ha ocurrido un error validando su información");
      }
      setIsLoading(false);
    },
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Mindly | Recuperar contraseña</title>
        <meta name="description" content="Recuperar contraseña" />
      </Helmet>
      {message && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {message}
        </Alert>
      )}
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-login d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img
                  src={recoveryimg}
                  className="img-fluid d-block mx-auto auth-svg"
                  alt=""
                />
              </div>
            </Col>
            <Col className="auth-form" lg={5} md={6}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <div className="card-title text-center">
                    <img className="auth-logo m-auto" src={logo}></img>
                    <h4 className="card-title text-center">
                      Olvide mi contraseña
                    </h4>
                  </div>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col lg={12}>
                        <p className="text-muted">
                          Escribe el correo con el que te registraste. Nosotros
                          validaremos y te enviaremos un código para que puedas
                          crear una nueva contraseña.
                        </p>
                        <div className="mb-3">
                          <Label className="form-label" for="email">
                            Correo electrónico{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control ps-5"
                            name="email"
                            id="email"
                            placeholder="Ingrese su correo electrónico"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-grid">
                          <Button
                            color="primary"
                            className="d-flex justify-content-center align-items-center "
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="mx-2"></Spinner>
                            ) : (
                              <i className="bx bx-calendar-plus"></i>
                            )}
                            Enviar instrucciones
                          </Button>
                        </div>
                      </Col>
                      <div className="mx-auto">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            ¿Recordaste tu contraseña?
                          </small>{" "}
                          <Link to="/login" className="text-dark fw-bold">
                            Iniciar sesion
                          </Link>
                        </p>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PageRecoveryPassword;
