import React, { Component } from "react";
import { Col, Card, CardBody } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//import images
import client from "../../../assets/images/client/03.jpg";
import blog1 from "../../../assets/images/blog/emociones1/tengyart-DoqtEEn8SOo-unsplash-min.jpg";
import blog2 from "../../../assets/images/blog/empatia1/josh-calabrese-qmnpqDwla_E-unsplash-min.jpg";
import blog3 from "../../../assets/images/blog/standard.jpg";
import { Link } from "react-router-dom";

class BlogSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentPostData: [
        {
          img: blog1,
          title: "Tipos de emociones: Descubre los Secretos para Gestionarlas",
          date: "20 de Agosto, 2023",
          link: "/blog/tipos-de-emociones-como-gestionarlas",
        },
        {
          img: blog2,
          title:
            "Empatía: La Clave para Relaciones Significativas | Aprende Cómo",
          date: "17 de Agosto, 2023",
          link: "/blog/que-es-la-empatia-definicion-importancia-desarrollo",
        },
        {
          img: blog3,
          title: "Las expectativas: ¿Por qué nos afectan tanto?",
          date: "17 de Agosto, 2023",
          link: "/blog/expectativas",
        },
      ],

      iconListData: [
        {
          icon: "facebook",
          link: "https://web.facebook.com/people/Mindly/61550195502102/",
        },
        {
          icon: "instagram",
          link: "https://www.instagram.com/mindly.la/",
        },
        {
          icon: "linkedin",
          link: "https://www.linkedin.com/company/mindly-la/",
        },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <Col lg={4} md={5} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <Card className="border-0 sticky-bar ms-lg-4">
            <CardBody className="p-0">
              <div className="text-center">
                <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                  Autora
                </span>

                <div className="mt-4">
                  <img
                    src={client}
                    className="img-fluid avatar avatar-medium rounded-pill shadow-md d-block mx-auto"
                    alt=""
                  />

                  <Link
                    to="/blog-about"
                    className="text-primary h5 mt-4 mb-0 d-block"
                  >
                    Daniela Castro
                  </Link>
                  <small className="text-muted d-block">
                    Psicóloga Clínica
                  </small>
                </div>
              </div>

              <div className="widget mt-4">
                <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                  Últimos Artículos
                </span>

                <div className="mt-4">
                  {this.state.recentPostData.map((item, key) => (
                    <div key={key} className="d-flex align-items-center mt-3">
                      <img
                        src={item.img}
                        className="avatar avatar-small rounded"
                        style={{ width: "auto" }}
                        alt=""
                      />
                      <div className="flex-1 ms-3">
                        <Link
                          to={item.link}
                          className="d-block title text-dark"
                        >
                          {item.title}
                        </Link>
                        <span className="text-muted">{item.date}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="widget mt-4">
                <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                  Redes Sociales
                </span>

                <ul className="list-unstyled social-icon social text-center mb-0 mt-4">
                  {this.state.iconListData.map((item, key) => (
                    <li key={key} className="list-inline-item me-1">
                      <a
                        href={item.link}
                        className="rounded"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FeatherIcon
                          icon={item.icon}
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default BlogSidebar;
