import React, { useEffect, useState } from "react";
import PsicologoPage from "../PsicologoPage";
import axiosInstance from "../../helpers/axiosConfig";

const PsicologoProfile = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    try {
      const response = await axiosInstance.get("/user/data");
      if (response.status === 200) {
        const userDataResponse = response.data.body;
        setUserData(userDataResponse);
      }
    } catch (error) {}
  }
  return <>{userData && <PsicologoPage psicologoIdProp={userData.id} />}</>;
};

export default PsicologoProfile;
