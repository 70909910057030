import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axiosInstance from "../../helpers/axiosConfig";

import defaultProfilePicture from "../../assets/images/def-profile-picture-min.png";

function PsicologoCard({ psicologo, precioObject, isNewUser }) {
  const [satisfaccion, setSatisfaccion] = useState(null);
  const [profilePictureLoaded, setprofilePictureLoaded] = useState(false);

  useEffect(() => {
    async function fetchSatisfaccion() {
      try {
        const response = await axiosInstance.get(
          `/reviews/satisfaccion/${psicologo.id}`
        );
        if (response.status === 200) {
          let satisfaccionResponse = response.data.body.satisfaccion;
          if (satisfaccionResponse < 3.7) {
            satisfaccionResponse += 0.6;
          }
          setSatisfaccion(satisfaccionResponse);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchSatisfaccion();
  }, [psicologo.id, precioObject]);

  const history = useHistory();

  const navigatePsicologo = () => {
    history.push(`/mi-espacio/especialistas/${psicologo.id}`);
  };

  return (
    <div className="psicologo-card" onClick={navigatePsicologo}>
      <div className="personal-information">
        <div className="psicologo-image">
          {profilePictureLoaded ? null : (
            <img src={defaultProfilePicture} alt="Cargando..."></img>
          )}
          <img
            style={profilePictureLoaded ? {} : { display: "none" }}
            src={psicologo.profile_picture_url}
            alt="psicologo"
            onLoad={() => setprofilePictureLoaded(true)}
          />
        </div>
        <div className="psicologo-data">
          <div className="psicologo-experiencia">
            <p>
              Experiencia <strong>{psicologo.anos_experiencia} Años</strong>
            </p>
          </div>
          <div className="psicologo-satisfaccion">
            {satisfaccion && satisfaccion > 2 ? (
              <p>
                Satisfacción{" "}
                <strong>{Math.trunc((satisfaccion / 5) * 100)}%</strong>
              </p>
            ) : (
              <p>
                Satisfacción <strong>{100}%</strong>
              </p>
            )}
          </div>

          <div className="psicologo-precio">
            <p
              className="d-flex"
              style={{ minHeight: "1.3rem", minWidth: "10rem" }}
            >
              {isNewUser && precioObject.precio_individual && (
                <>
                  <strong className="text-decoration-line-through mx-1">
                    {(precioObject.precio_individual / 100).toFixed(2)}
                    {precioObject.divisa.toUpperCase()}
                  </strong>
                  <strong className="text-success">
                    {((precioObject.precio_individual / 100) * 0.5).toFixed(2)}
                    {precioObject.divisa.toUpperCase()}
                  </strong>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="psicologo-information">
        <div className="psicologo-name">
          <h5>
            {psicologo.nombre.toLowerCase().charAt(0).toUpperCase() +
              psicologo.nombre.toLowerCase().slice(1) +
              " " +
              psicologo.apellido.toLowerCase().charAt(0).toUpperCase() +
              psicologo.apellido.toLowerCase().slice(1)}
          </h5>
          <span className="badge rounded-pill bg-soft-primary">
            <b>Online</b>
          </span>
        </div>
        <div className="psicologo-desc-container">
          <div className="psicologo-descripcion">
            <p>
              {psicologo.sobre_mi.length > 300
                ? `${psicologo.sobre_mi.slice(0, 300)}...`
                : psicologo.sobre_mi}
            </p>
          </div>
          <div className="psicologo-action">
            <Link to={`/mi-espacio/especialistas/${psicologo.id}`}>
              <div
                id="verPerfil"
                className="btn btn-pills btn-soft-primary psicologo-actionBtn"
              >
                Ver Perfil
              </div>
            </Link>
            <Link
              to={`/mi-espacio/especialistas/${psicologo.id}/agendar`}
              rel="noreferrer"
            >
              <div
                id="agendarCita"
                className="btn btn-pills btn-primary psicologo-actionBtn"
              >
                <i className="bx bx-calendar-plus"></i>
                <p>Agendar Cita</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PsicologoCard;
