import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import axiosInstance from "../../helpers/axiosConfig";

function AdminPagosCSV() {
  const months = [
    { value: "1", label: "Enero" },
    { value: "2", label: "Febrero" },
    { value: "3", label: "Marzo" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Mayo" },
    { value: "6", label: "Junio" },
    { value: "7", label: "Julio" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
  ];

  const years = ["2022", "2023", "2024", "2025"];

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/facturas/generar-pago?month=${selectedMonth}&year=${selectedYear}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Pago-${selectedMonth}-${selectedYear}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
    setLoading(false);
  };

  return (
    <Container className="my-5 bg-light rounded p-5">
      <FormGroup>
        <Label for="monthSelect">Select Month</Label>
        <Select
          id="monthSelect"
          options={months}
          value={months.find((obj) => obj.value === selectedMonth)}
          onChange={(selectedOption) => setSelectedMonth(selectedOption.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="yearSelect">Select Year</Label>
        <Input
          type="select"
          name="yearSelect"
          id="yearSelect"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          {years.map((year) => (
            <option key={year}>{year}</option>
          ))}
        </Input>
      </FormGroup>
      <Button color="primary" onClick={handleDownload}>
        {loading && <Spinner size="sm" className="mr-2" />}
        Descargar archivo de Pago
      </Button>
    </Container>
  );
}

export default AdminPagosCSV;
