import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import AccordianCommon from "../../components/Shared/AccordianCommon";

export default class Questions extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60 psi-landing-about" id="faq">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">Preguntas frecuentes</h4>
                <p className="text-muted mb-0 para-desc">
                  Algunas de las preguntas más frecuentes que recibimos de
                  nuestros psicólogos. Si tienes alguna otra pregunta, no dudes
                  en{" "}
                  <Link to="/contact-us" className="text-primary">
                    {" "}
                    contactarnos.
                  </Link>
                </p>
              </div>
            </Col>

            <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="faq-content">
                <div className="accordion" id="accordionExampleone">
                  <AccordianCommon
                    question2="¿Por dónde llevo a cabo las sesiones?"
                    answer2="Las sesiones se llevan a cabo a través de nuestra plataforma para garantizar la privacidad y seguridad de los pacientes."
                    question1="¿Cómo puedo empezar a atender en Mindly?"
                    answer1="Una vez hayas completado el formulario de registro, nos pondremos en contacto con vos."
                    question4="¿Cómo recibire los cobros?"
                    answer4="Una vez por mes, recibirás el pago de tus sesiones realizadas en tu cuenta bancaria. Podrás elegir la divisa de tu preferencia."
                    question5="¿Qué pasa si mi paciente no asiste a una cita?"
                    answer5="Si tu paciente no reprogramó su cita 24hs antes, recibiras igualmente el pago de la sesión."
                    question6="¿Cuánto duran las sesiones?"
                    answer6="50 minutos."
                    question3="¿Cómo se gestiona mi agenda y mi disponibilidad?"
                    answer3="
                    En Mindly, tenes completa autonomía para elegir los días y horarios en los que quieras atender en la plataforma.
                    Una vez hayas definido tu disponibilidad, los pacientes podrán reservar citas en los horarios que hayas seleccionado."
                  />
                </div>
              </div>
            </Col>
            <div className="d-flex justify-content-center align-items-center w-100 mt-5">
              <a
                href="https://fzaqwxs849j.typeform.com/to/AMVX5fVV"
                target="_blank"
                className="btn btn-primary mt-3 unirseBtn"
                rel="noreferrer"
              >
                Comenzar registro
              </a>
            </div>
          </Row>

          <div className="row mt-5 pt-md-4 justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4 className="title mb-4">
                  ¿Alguna otra pregunta? Contactanos!
                </h4>
                <p className="text-muted para-desc mx-auto">
                  Si tenés alguna otra pregunta, no dudes en{" "}
                  <span className="text-primary fw-bold">contactarnos.</span>
                  <br />
                  Estamos para ayudarte.
                </p>
                <Link to="/contact-us" className="btn btn-primary mt-4">
                  <i className="uil uil-fast-mail"></i> Contactanos
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
