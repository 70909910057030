// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";
import { Helmet } from "react-helmet";

class PageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    };
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
    });
  }
  t_col5() {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
    });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn").classList.add("btn-primary");
        document.querySelector(".shoppingbtn").classList.remove("btn-light");
        document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Mindly | Términos y condiciones</title>
          <meta
            name="description"
            content="Mindly es una plataforma de psicólogos online que te permite encontrar al psicólogo ideal para ti. Conoce a nuestros psicólogos y agenda tu cita."
          />
        </Helmet>
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> Términos y condiciones </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="shadow border-0 rounded">
                  <CardBody>
                    <h5 className="card-title">Introducción :</h5>
                    <p className="text-muted">
                      Mindly es una plataforma de terapia en línea que conecta
                      psicólogos con pacientes. Al acceder y utilizar el sitio
                      web de Mindly, el usuario acepta los siguientes términos y
                      condiciones:
                    </p>

                    <h5 className="card-title">
                      1. Aceptación de términos y condiciones:
                    </h5>
                    <p className="text-muted">
                      El acceso y uso del sitio web de Mindly implica la
                      aceptación de estos términos y condiciones. Mindly se
                      reserva el derecho de modificar estos términos y
                      condiciones en cualquier momento sin previo aviso. Por lo
                      tanto, se recomienda al usuario revisar estos términos y
                      condiciones regularmente.
                    </p>

                    <h5 className="card-title">2. Servicios de Mindly :</h5>
                    <p className="text-muted">
                      Mindly proporciona una plataforma en línea que conecta a
                      los pacientes con psicólogos profesionales y autorizados
                      para brindar terapia en línea. El sitio web de Mindly no
                      sustituye la atención médica y no debe ser utilizado en
                      situaciones de emergencia médica. Si el usuario está en
                      una situación de emergencia médica, debe buscar atención
                      médica inmediata en su lugar de residencia.
                    </p>

                    <h5 className="card-title">
                      3. Uso del sitio web de Mindly :
                    </h5>
                    <p className="text-muted">
                      El usuario acepta utilizar el sitio web de Mindly de
                      manera responsable y ética, y cumplir con todas las leyes
                      y regulaciones aplicables. El usuario no puede utilizar el
                      sitio web de Mindly para enviar o publicar contenido
                      ilegal, ofensivo, difamatorio o que infrinja los derechos
                      de propiedad intelectual de terceros. El usuario no puede
                      utilizar el sitio web de Mindly para solicitar servicios
                      de terapia fuera de la plataforma de Mindly.
                    </p>

                    <h5 className="card-title">4. Propiedad intelectual :</h5>
                    <p className="text-muted">
                      Todo el contenido del sitio web de Mindly, incluyendo
                      textos, imágenes, logotipos, nombres comerciales y marcas
                      registradas, son propiedad exclusiva de Mindly y están
                      protegidos por las leyes de propiedad intelectual
                      aplicables. El usuario no puede utilizar, reproducir o
                      distribuir cualquier contenido del sitio web de Mindly sin
                      la autorización previa y por escrito de Mindly.
                    </p>

                    <h5 className="card-title">5. Política de privacidad :</h5>
                    <p className="text-muted">
                      Mindly procesa los datos personales del usuario de acuerdo
                      con su Política de privacidad, la cual puede ser
                      consultada en el sitio web de Mindly.
                    </p>

                    <h5 className="card-title">
                      6. Limitación de responsabilidad :
                    </h5>
                    <p className="text-muted">
                      Mindly no será responsable de ningún daño, pérdida o
                      lesión resultante del acceso o uso del sitio web de
                      Mindly, incluyendo cualquier interrupción o fallo en la
                      plataforma de terapia en línea. Mindly no será responsable
                      de la calidad de los servicios de terapia proporcionados
                      por los psicólogos conectados a través de la plataforma de
                      Mindly.
                    </p>

                    <h5 className="card-title">6. Resolución de disputas :</h5>
                    <p className="text-muted">
                      Cualquier disputa relacionada con el uso del sitio web de
                      Mindly se resolverá mediante la mediación y, en caso de
                      que no se llegue a un acuerdo, se someterá a la
                      jurisdicción y competencia exclusiva de los tribunales
                      competentes de pais en donde fue constituida la sociedad.
                    </p>

                    <div className="mt-5">
                      <p>
                        Al aceptar estos términos y condiciones, el usuario
                        declara ser mayor de edad y tener la capacidad legal
                        para contratar y utilizar el sitio web de Mindly de
                        acuerdo con estos términos y condiciones.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageTerms;
