import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/paquetes.css";
import axiosInstance from "../../helpers/axiosConfig";
import axios from "axios";
import { Spinner } from "reactstrap";

function Paquetes() {
  const [preciosInternacionales, setPreciosInternacionales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    await fetchPreciosInternacionales(res.data.ip);
  };

  const fetchPreciosInternacionales = async (ip) => {
    try {
      const response = await axiosInstance.get(
        `/ip-location/preciosInternacionales/${ip}`
      );
      setPreciosInternacionales(response.data.body);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="pricing pricing-palden">
      <div className="pricing-item">
        <div className="pricing-deco">
          <svg
            className="pricing-deco-img"
            enableBackground="new 0 0 300 100"
            height="100px"
            id="Layer_1"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 300 100"
            width="300px"
            x="0px"
            xmlSpace="preserve"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
          >
            <path
              className="deco-layer deco-layer--1"
              d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
              fill="#FFFFFF"
              opacity="0.6"
            ></path>
            <path
              className="deco-layer deco-layer--2"
              d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
              fill="#FFFFFF"
              opacity="0.6"
            ></path>
            <path
              className="deco-layer deco-layer--3"
              d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z"
              fill="#FFFFFF"
              opacity="0.7"
            ></path>
            <path
              className="deco-layer deco-layer--4"
              d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
              fill="#FFFFFF"
            ></path>
          </svg>
          {isLoading ? (
            <Spinner color="white" className="mb-4" />
          ) : (
            <div className="pricing-price">
              <span className="pricing-currency">$</span>
              {Math.floor(preciosInternacionales.precio_paquete_6 / 100) || 204}
              <span className="pricing-period">
                {preciosInternacionales.divisa || "usd"}
              </span>
            </div>
          )}
          <h3 className="pricing-title">PACK SANAR</h3>
        </div>
        <ul className="pricing-feature-list">
          <li className="pricing-feature">
            <strong>6 sesiones</strong> de 50 minutos
          </li>
          <li className="pricing-feature">Sesiones encriptadas</li>
          <li className="pricing-feature">
            <strong>
              {" "}
              $
              {isLoading
                ? " --"
                : isNaN(preciosInternacionales.precio_paquete_6 / 100 / 6)
                ? 34
                : (preciosInternacionales.precio_paquete_6 / 100 / 6).toFixed(
                    2
                  )}
            </strong>{" "}
            /sesión
          </li>
        </ul>
        <Link
          to="/mi-espacio/paquetePayment/precio_paquete_6"
          className="pricing-action"
        >
          Obtener
        </Link>
      </div>
      <div className="pricing-item pricing__item--featured">
        <div className="pricing-deco">
          <svg
            className="pricing-deco-img"
            enableBackground="new 0 0 300 100"
            height="100px"
            id="Layer_1"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 300 100"
            width="300px"
            x="0px"
            xmlSpace="preserve"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
          >
            <path
              className="deco-layer deco-layer--1"
              d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
              fill="#FFFFFF"
              opacity="0.6"
            ></path>
            <path
              className="deco-layer deco-layer--2"
              d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
              fill="#FFFFFF"
              opacity="0.6"
            ></path>
            <path
              className="deco-layer deco-layer--3"
              d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z"
              fill="#FFFFFF"
              opacity="0.7"
            ></path>
            <path
              className="deco-layer deco-layer--4"
              d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
              fill="#FFFFFF"
            ></path>
          </svg>
          {isLoading ? (
            <Spinner color="white" className="mb-4" />
          ) : (
            <div className="pricing-price">
              <span className="pricing-currency">$</span>
              {Math.floor(preciosInternacionales.precio_paquete_10 / 100) ||
                290}
              <span className="pricing-period">
                {preciosInternacionales.divisa || "usd"}
              </span>
            </div>
          )}
          <h3 className="pricing-title">Pack constancia</h3>
        </div>
        <ul className="pricing-feature-list">
          <li className="pricing-feature">
            <strong>10 sesiones</strong> de 50 minutos
          </li>
          <li className="pricing-feature">Sesiones encriptadas</li>
          <li className="pricing-feature">
            <strong>
              $
              {/* {isNaN(preciosInternacionales.precio_paquete_10 / 100 / 10)
                ? 39
                : (preciosInternacionales.precio_paquete_10 / 100 / 10).toFixed(
                    2
                  )} */}
              {isLoading
                ? " --"
                : isNaN(preciosInternacionales.precio_paquete_10 / 100 / 10)
                ? 29
                : (preciosInternacionales.precio_paquete_10 / 100 / 10).toFixed(
                    2
                  )}
            </strong>{" "}
            /sesión
          </li>
        </ul>
        <Link
          to="/mi-espacio/paquetePayment/precio_paquete_10"
          className="pricing-action"
        >
          Obtener
        </Link>
      </div>
    </div>
  );
}

export default Paquetes;
