const reviews = [
  {
    id: 1,
    img: "men-animation",
    name: "Carlos Ramirez",
    post: "Paciente",
    desc: "Si puedes ir a terapia, hazlo. Un profesional te ayudará a identificar y abordar pensamientos que antes no reconocías, haciendo que vivas el presente.",
    rating: 5,
  },
  {
    id: 2,
    img: "women-animation",
    name: "Valentina Ortiz",
    post: "Paciente",
    desc: "Ahora controlo mis emociones en lugar de ser controlado por ellas. Mi calidad de vida ha mejorado muchísimo y mis relaciones son más saludables.",
    rating: 5,
  },
  {
    id: 3,
    img: "women-animation",
    name: "Sofia Castro",
    post: "Paciente",
    desc: "La calidad de la atención es excelente. Se nota que los psicólogos son realmente expertos. Me siento muy cómoda y segura de poder expresarme libremente.",
    rating: 5,
  },
  {
    id: 4,
    img: "men-animation",
    name: "Diego González",
    post: "Paciente",
    desc: "La plataforma es muy fácil de usar y me ha permitido conectarme con un terapeuta que me ha brindado herramientas valiosas para mejorar mi bienestar mental.",
    rating: 5,
  },
  {
    id: 5,
    img: "women-animation",
    name: "Ana Flores",
    post: "Paciente",
    desc: "Durante todo el proceso, me he sentido escuchada, apoyada y comprendida en todo momento. Gracias a mi psicóloga! ¡La recomiendo al 100%!",
    rating: 5,
  },
  {
    id: 6,
    img: "men-animation",
    name: "Juan Araujo",
    post: "Paciente",
    desc: " La atención al detalle es excepcional. Todo funciona muy bien y muy fluido.",
    rating: 5,
  },
];

export { reviews };
