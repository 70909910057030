import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// import images
import online1 from "../../assets/images/course/online/ab01.jpg";
import online2 from "../../assets/images/course/online/ab02.png";
import online3 from "../../assets/images/course/online/ab03.jpg";

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60 psi-landing-about" id="about">
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <Row className="align-items-center">
                <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                    <CardBody className="p-0">
                      <img src={online1} className="img-fluid" alt="" />
                      <div className="overlay-work bg-dark"></div>
                      <div className="content"></div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={6} xs={6}>
                  <Row>
                    <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                      <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                        <CardBody className="p-0">
                          <img src={online2} className="img-fluid" alt="" />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content"></div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg={12} md={12} className="mt-4 pt-2">
                      <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                        <CardBody className="p-0">
                          <img src={online3} className="img-fluid" alt="" />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content"></div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ms-lg-4">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-4">Sobre nosotros</h4>
                  <p className="text-muted para-desc">
                    Somos un equipo apasionado por la salud mental y la
                    tecnología.
                  </p>
                  <p className="text-muted para-desc mb-0">
                    La misión de{" "}
                    <span className="text-primary fw-bold">Mindly</span> es
                    implementar las últimas tecnologías con el fin de ayudar a
                    las personas a recuperar y mantener la estabilidad emocional
                    que necesitan para su vida diaria. <br />
                    <br />
                    Al día de hoy, hemos ayudado a cientos de personas a lograr
                    sus objetivos psicológicos.
                  </p>
                </div>

                <ul className="list-unstyled text-muted">
                  <li className="mb-0">
                    <span className="text-primary h4 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Herramienta 100% encriptada
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h4 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Citas según tu disponibilidad
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h4 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Sín exclusividad ni mínimo de pacientes
                  </li>
                </ul>

                <div className="watch-video mt-4 pt-2">
                  <a
                    href="https://fzaqwxs849j.typeform.com/to/AMVX5fVV"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary m-1"
                  >
                    Me interesa{" "}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </a>{" "}
                </div>
              </div>
            </Col>
          </Row>
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId="yba7hPeTSjk"
            onClose={() => this.setState({ isOpen: false })}
          />
        </Container>
      </React.Fragment>
    );
  }
}
