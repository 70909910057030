import React, { useEffect, useState } from "react";
import { Alert, Table } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";

const AdminPsicologosPendientes = () => {
  const [psicologos, setPsicologos] = useState([]);
  const [users, setUsers] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchPsicologos() {
      try {
        const response = await axiosInstance.get("/psicologos");
        if (response.data.status === 200) {
          // Filtrar los psicólogos cuya propiedad is_active === false y cuyos ids no sean 5, 6 o 7
          const filteredPsicologos = response.data.body.filter(
            (psicologo) =>
              psicologo.id != 5 &&
              psicologo.id != 6 &&
              psicologo.id != 7 &&
              psicologo.is_active === false
          );
          setPsicologos(filteredPsicologos);
        }
      } catch (error) {
        setError("Error");
      }
    }
    async function fetchUsers() {
      try {
        const response = await axiosInstance.get("/user");
        if (response.data.status === 200) {
          setUsers(response.data.body);
        }
      } catch (error) {
        setError("Error");
      }
    }
    fetchUsers();
    fetchPsicologos();
  }, []);

  const getPsicologoEmail = (psicologoId) => {
    const user = users.find((user) => user.id === psicologoId);
    return user ? user.email : "N/A";
  };

  return (
    <>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {psicologos.length > 0 ? (
        <div className="rounded m-3" style={{ border: "1px solid purple" }}>
          <Table striped className="m-0">
            <thead>
              <tr>
                <th>Nombre y Apellido</th>
                <th>Img</th>
                <th>Sobre Mí</th>
                <th>Estilo de Terapia</th>
                <th>Primera Sesión</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {psicologos.map((psicologo, index) => (
                <tr key={index}>
                  <td>{`${psicologo.nombre} ${psicologo.apellido}`}</td>
                  <td>
                    <img
                      src={psicologo.profile_picture_url}
                      alt="Profile"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>{psicologo.sobre_mi}</td>
                  <td>{psicologo.estilo_terapia}</td>
                  <td>{psicologo.primera_sesion}</td>
                  <td>{getPsicologoEmail(psicologo.id)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="text-center">
          <p className="text-muted p-5">
            No hay psicólogos pendientes de verificacion
          </p>
        </div>
      )}
    </>
  );
};

export default AdminPsicologosPendientes;
