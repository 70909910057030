import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import axiosInstance from "../../helpers/axiosConfig";
import { Alert, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = loadStripe(stripeApiKey);

function CheckoutSession() {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { citaId } = useParams() || null;

  const toggle = () => setShowModal(!showModal);

  useEffect(() => {
    const createPaymentSession = async () => {
      try {
        let endpoint = "/payment";
        let requestData = { citaId };
        const { data } = await axiosInstance.post(endpoint, requestData);
        setClientSecret(data.body);
      } catch (error) {
        setError("Algo salió mal, por favor intente de nuevo");
        setShowModal(true);
      }
    };
    createPaymentSession();
  }, [citaId]);

  return (
    <>
      <Helmet>
        <title>Mindly | Checkout</title>
        <meta name="description" content="Checkout" />
      </Helmet>

      {error && (
        <Modal isOpen={showModal && error} toggle={toggle}>
          <ModalBody>
            <Alert className="bg-soft-primary fw-medium ">
              <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
              {error}
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button className="p-1" color="primary" onClick={toggle}>
              <i className="bx bx-x-circle h5 m-auto"></i>
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <div className="checkout-container mt-1">
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </>
  );
}

export default CheckoutSession;
