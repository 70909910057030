import React, { useEffect } from "react";
import { Container } from "reactstrap";
//Import Components
import Counter from "./Counter";
import Section from "./Section";
import SectionTitle from "../../components/Shared/SectionTitle";
import ReviewsSlider from "../../components/Shared/ReviewsSlider";
import WorkProcess from "./WorkProcess";
import { reviews } from "../../common/data";
import { Helmet } from "react-helmet";
import "../../assets/css/home.css";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import Team from "./Team";
import PromotionBanner from "./PromotionBanner";
import CountDown from "./CountDown";
import Problem from "./Problem";
import PainAndGain from "./PainAndGain";

//Import Images
import BookAppointment from "./BookAppointment";

const Index = () => {
  useEffect(() => {
    document.getElementById("buyButton").classList.add("nav-light");
    document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn").classList.add("btn-primary");
        document.querySelector(".shoppingbtn").classList.remove("btn-light");
        document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Mindly | Psicólogo online para tu bienestar mental y emocional
        </title>
        <meta
          name="description"
          content="Mindly es una plataforma de psicólogos online que te permite encontrar al psicólogo ideal para ti. Conoce a nuestros psicólogos y agenda tu cita."
        />
      </Helmet>
      <Section id="home" />
      <Problem />
      <Team />
      {/* <PromotionBanner /> */}
      <CountDown />
      <section id="comoFunciona" className="p-1 bg-light">
        <WorkProcess />
      </section>
      <Counter />
      <section className="section about-us-section">
        <Pricing />
      </section>
      {/* <Appointment /> */}
      {/* <section className="section">
        <Services />
      </section> */}

      <section className="section qualified-doctors-section">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Los que nuestros pacientes opinan"
            desc=" Regálate la oportunidad de sentirte mejor."
          />

          {/* clients slider */}
          <ReviewsSlider reviews={reviews} colClass="mt-4" />
        </Container>
        <FAQ />
        <PainAndGain />
      </section>

      <BookAppointment />
    </React.Fragment>
  );
};
export default Index;
