import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const WorkProcess = () => {
  return (
    <React.Fragment>
      <Container className="mt-100 mt-60 pb-5">
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="section-title text-center mb-4 pb-2">
              <h6 className="text-primary">Terapia Online Eficiente</h6>
              <h2 className="title mb-4">¿Cómo funciona?</h2>
              <p className=" para-desc mx-auto mb-0">
                Te ayudamos a encontrar al psicólogo que{" "}
                <span className="text-primary fw-bold"> mejor se adapte </span>{" "}
                a tu estilo y ritmo de vida.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4} className="mt-4 pt-2">
            <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
              <div className="icons text-center mx-auto">
                <i className="uil uil-search-alt d-block rounded h3 mb-0"></i>
              </div>

              <CardBody>
                <h5 className="text-dark">
                  1. <span className="text-primary">Elige</span> tu Especialista
                  Ideal
                </h5>
                <p className="text-muted mb-0 como-fuciona-text">
                  Elige el profesional que más se adapte a tus necesidades.
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
            <Card className="features feature-clean work-process bg-transparent process-arrow border-0 text-center">
              <div className="icons text-primary text-center mx-auto">
                <i className="uil uil-calendar-alt d-block rounded h3 mb-0"></i>
              </div>

              <CardBody>
                <h5 className="text-dark">
                  2. <span className="text-primary">Agenda</span> tu Primera
                  Cita
                </h5>
                <p className="text-muted mb-0 como-fuciona-text">
                  Elige el día y la hora que mejor se adapte a tu día.
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
            <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
              <div className="icons text-primary text-center mx-auto">
                <i className="uil uil-water d-block rounded h3 mb-0"></i>
              </div>

              <CardBody>
                <h5 className="text-dark">
                  3. <span className="text-primary">Empieza</span> tu cambio
                </h5>
                <p className="text-muted mb-0 como-fuciona-text">
                  Empieza a cambiar tu vida de la mano de los mejores.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default WorkProcess;
