import React, { useState, useEffect } from "react";
import PsicologosContainer from "./PsicologosContainer";
import "../../assets/css/especialistas.css";
import axiosInstance from "../../helpers/axiosConfig";
import PsicologoCardLoader from "../../components/Shared/Loaders/PsicologoCardLoader";
import { Alert } from "reactstrap";
import { Helmet } from "react-helmet";

function Especialistas() {
  const [psicologos, setPsicologos] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchPsicologos() {
      try {
        const response = await axiosInstance.get("/psicologos");
        if (response.status === 200) {
          setPsicologos(response.data.body);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
      }
    }
    fetchPsicologos();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Mindly | Especialistas</title>
        <meta name="description" content="Especialistas" />
      </Helmet>
      {loading && (
        <div className="text-center psicologo-card mx-auto my-5">
          <PsicologoCardLoader className="p-5" />
        </div>
      )}
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {psicologos.length === 0 && !loading && (
        <p className="text-secondary text-center m-5">
          No hay especialistas que mostrar
        </p>
      )}

      {psicologos.length > 0 && <PsicologosContainer psicologos={psicologos} />}
    </div>
  );
}

export default Especialistas;
