import React, { useState, useEffect } from "react";
import "../../assets/css/miCuenta.css";
import ProfilePictureUpload from "./ProfilePictureUpload";
import { Badge } from "reactstrap";

import {
  Row,
  Col,
  Label,
  Input,
  Form,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { useHistory } from "react-router-dom";
import ProfileLoader from "../../components/Shared/Loaders/ProfileLoader";
import axiosInstance from "../../helpers/axiosConfig";
import { Helmet } from "react-helmet";

function MiCuenta() {
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState("");
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(null);
  const [paises, setPaises] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [availableCitas, setAvailableCitas] = useState(0);

  const [userData, setUserData] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };
  const toggle = () => {
    setUpdated(!updated);
    setReRender(!reRender);
  };

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axiosInstance.get("/user/data");
        const userDataResponse = response.data.body;
        if (userDataResponse !== "") {
          setLoading(false);
          if (userDataResponse.pais_id) {
            const paisResponse = await axiosInstance.get(
              `/paises/${userDataResponse.pais_id}`
            );
            const paisData = paisResponse.data.body;
            userDataResponse.pais = paisData.pais;
          }
          setUserData(userDataResponse);
        } else {
          throw new Error("No se pudo obtener la información del usuario");
        }
      } catch (error) {
        setError(error.message);
      }
    }
    async function fetchPaises() {
      try {
        const response = await axiosInstance.get("/paises");
        const paisesData = response.data.body;
        if (paisesData) {
          setPaises(paisesData);
        } else {
          throw new Error("No se pudo obtener la información de los paises");
        }
      } catch (error) {
        setError(error.message);
      }
    }
    const setPacienteCitas = async () => {
      try {
        const response = await axiosInstance.get("/pacientes/:id");
        if (response.status === 200) {
          const { free_cita, available_citas } = response.data.body;
          setAvailableCitas(available_citas);
          // setFreeCitaStatus(free_cita);
        }
      } catch (error) {
        console.error("Error fetching free_cita:", error);
      }
    };
    setPacienteCitas();
    fetchPaises();
    fetchUserData();
  }, [reRender]);

  const handleSubmit = (event) => {
    event.preventDefault();

    async function updateUserData() {
      try {
        let response = null;
        if (userData.pais_id == "") {
          userData.pais_id = null;
        }
        if (userData.role == "paciente") {
          response = await axiosInstance.patch(
            `/pacientes/${userData.id}`,
            userData
          );
        } else if (userData.role == "psicologo") {
          response = await axiosInstance.patch(
            `/psicologos/${userData.id}`,
            userData
          );
        }
        if (response.status === 200) {
          setUpdated(true);
        }
      } catch (error) {
        setError(error.message);
      }
    }
    updateUserData();
  };

  const handleProfilePictureSave = () => {
    setUpdated(true);
  };
  if (redirectTo) {
    history.push(redirectTo);
  }

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("role");
    setRedirectTo("/login");
  };

  return (
    <>
      <Helmet>
        <title>Mindly | Mi Cuenta</title>
        <meta name="description" content="Mi Cuenta" />
      </Helmet>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {loading && (
        <div className="datos-container p-5">
          <ProfileLoader className="m-5" />
        </div>
      )}
      {updated && (
        <Modal isOpen={updated} toggle={toggle}>
          <ModalBody className="m-auto p-4">
            <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
            <span>Los datos se actualizaron correctamente 🚀</span>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Genial!
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <div className="datos-container">
        {userData && !loading && (
          <div className="miCuenta-container">
            <div className="user-image d-flex flex-column mb-0">
              <ProfilePictureUpload
                userData={userData}
                onProfilePictureSave={handleProfilePictureSave}
              />
              {availableCitas > 0 && (
                <div className="info-item mb-0">
                  <Badge
                    className="bg-soft-primary my-2 p-1"
                    style={{
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      lineHeight: "1.2",
                    }}
                  >
                    {availableCitas} Citas Disponibles
                  </Badge>
                </div>
              )}
            </div>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Nombre</Label>
                    <div className="form-icon position-relative">
                      <FeatherIcon
                        icon="user"
                        className="fea icon-sm icons"
                      ></FeatherIcon>
                      <Input
                        name="nombre"
                        id="name"
                        type="text"
                        className="form-control ps-5"
                        placeholder={userData.nombre || "Nombre:"}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Apellido</Label>
                    <div className="form-icon position-relative">
                      <FeatherIcon
                        icon="user"
                        className="fea icon-sm icons"
                      ></FeatherIcon>
                      <Input
                        name="apellido"
                        id="apellido"
                        type="text"
                        className="form-control ps-5"
                        placeholder={userData.apellido || "Apellido:"}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">País</Label>
                    <div className="form-icon position-relative">
                      <FeatherIcon
                        icon="globe"
                        className="fea icon-sm icons"
                      ></FeatherIcon>
                      <Input
                        name="pais_id"
                        id="pais"
                        type="select"
                        aria-label="Pais select"
                        className="form-control ps-5"
                        placeholder="País"
                        value={userData.pais_id || ""}
                        onChange={handleInputChange}
                      >
                        <option value="">Seleccione su país</option>
                        {paises.map((pais) => (
                          <option key={pais.id} value={pais.id}>
                            {pais.pais}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <div className="form-icon position-relative">
                      <FeatherIcon
                        icon="mail"
                        className="fea icon-sm icons"
                      ></FeatherIcon>
                      <Input
                        required={false}
                        name="email"
                        id="email"
                        type="email"
                        className="form-control ps-5"
                        placeholder={userData.email || "Email"}
                        onChange={handleInputChange}
                        readOnly
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" className="btnActualizar-row">
                  <button
                    className="btn btn-soft-secondary btn-logout"
                    onClick={logout}
                  >
                    <i className="bx bx-log-out"></i>
                    Cerrar sesión
                  </button>
                  <Input
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn btn-pills btn-primary btnActualizar d-flex justify-content-center align-items-center"
                    value="Actualizar Datos"
                  />
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
      {/* <div className="datos-container">
        <div className="rounded shadow mt-4">
          <div className="p-4 border-bottom">
            <h5 className="mb-0 text-danger">Eliminar cuenta :</h5>
          </div>

          <div className="p-4">
            <p className="text-secondary mb-0">
              ¿Deseas borrar tu cuenta? Si estas seguro, oprime "Eliminar
              Cuenta"
            </p>
            <div className="mt-4">
              <button className="btn btn-danger">Eliminar Cuenta</button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default MiCuenta;
