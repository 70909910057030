import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/heridasDeInfancia/heridasDeInfancia-min.jpg";
import superation from "../../../assets/images/blog/heridasDeInfancia/superation-min.jpg";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            ¿Qué son las heridas de la infancia? | Explora sus 5 tipos
          </title>
          <meta
            name="description"
            content="Descubre el impacto de las heridas de infancia y encuentra el camino hacia la sanación. ¡Aprende cómo superarlas y construir una vida plena!"
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img
                    src={standard}
                    className="img-fluid"
                    alt="niña jugando con un oso de peluche"
                  />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Qué son, tipos y cómo sanar
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        Las Heridas de la Infancia: Qué son y sus 5 Tipos
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 23 de
                          Noviembre, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="mt-3">
                      La infancia es una etapa crucial en el desarrollo humano,
                      donde se forman las bases de la personalidad y se
                      establecen los cimientos emocionales que nos acompañarán a
                      lo largo de la vida.
                    </p>
                    <p>
                      Sin embargo, no todas las experiencias durante esta etapa
                      son positivas, y muchas veces, las heridas emocionales
                      pueden dejar cicatrices profundas que afectan el bienestar
                      emocional en la edad adulta.
                    </p>
                    <p>
                      En este artículo, exploraremos las heridas de la infancia,{" "}
                      <strong className="text-primary">qué son</strong> y{" "}
                      <strong className="text-primary">
                        los 5 tipos más comunes
                      </strong>
                      : rechazo, abandono, humillación, traición e injusticia.
                    </p>
                    {/* <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote> */}
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Qué son las heridas de la infancia?
                    </h2>
                    <p>
                      Las heridas de la infancia{" "}
                      <strong>
                        {" "}
                        son experiencias emocionales negativas que ocurren
                        durante los primeros años de vida
                      </strong>{" "}
                      y que tienen un impacto significativo en el desarrollo
                      emocional y psicológico de una persona.
                    </p>
                    <p>
                      Estas experiencias pueden provenir de diversas fuentes,
                      como la familia, los amigos, la escuela o cualquier otro
                      entorno en el que el niño se encuentre. <br></br>{" "}
                      <br></br>Las heridas de la infancia pueden afectar la{" "}
                      <strong>autoestima</strong>, la capacidad para establecer
                      <strong>relaciones saludables</strong> y la forma en que
                      una persona <strong> percibe</strong> el mundo que la
                      rodea.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Los 5 tipos de heridas de la infancia
                    </h2>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Rechazo
                    </h3>
                    <p>
                      El <strong> rechazo</strong> en la infancia puede tener
                      consecuencias devastadoras en la autoestima y la
                      percepción que el niño tiene de sí mismo. Este tipo de
                      herida puede surgir cuando un niño se siente indeseado o
                      no aceptado por sus padres, cuidadores u otros niños.{" "}
                    </p>
                    <p>
                      {" "}
                      Puede manifestarse de diversas maneras, desde la{" "}
                      <strong>falta de atención y cariño</strong> hasta el
                      rechazo abierto y la exclusión. <br />
                      <br />
                      El impacto del rechazo en la infancia puede llevar a
                      problemas de autoestima, dificultades en las relaciones
                      interpersonales y una constante búsqueda de{" "}
                      <strong> validación externa.</strong>
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Abandono
                    </h3>
                    <p>
                      El <strong>abandono</strong> es otra herida emocional que
                      puede dejar cicatrices profundas en la psique de un niño.{" "}
                      Puede manifestarse de manera <strong>física</strong>, como
                      en el caso de la ausencia física de los padres, o de
                      manera <strong>emocional</strong>, cuando un niño se
                      siente ignorado o desatendido emocionalmente.<br></br>
                      <br></br> Las consecuencias del abandono pueden incluir{" "}
                      <strong>dificultades para confiar en los demás</strong>,
                      <strong>miedo al compromiso</strong> y una{" "}
                      <strong>sensación persistente de vacío emocional.</strong>
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Humillación
                    </h3>
                    <p>
                      La <strong>humillación</strong> en la infancia puede
                      ocurrir de diversas formas, ya sea a través de la crítica
                      constante, el ridículo o el menosprecio. <br></br>
                      <br></br> Estas experiencias pueden tener un impacto
                      duradero en la autoestima y la confianza en uno mismo. Los
                      niños que experimentan humillación pueden desarrollar una{" "}
                      <strong>imagen distorsionada</strong> de sí mismos y
                      enfrentar dificultades para aceptar elogios o
                      reconocimiento positivo en la edad adulta. <br></br>
                      <br></br> La humillación también puede contribuir al
                      desarrollo de la vergüenza tóxica, afectando la capacidad
                      de la persona para <strong> expresarse </strong>
                      auténticamente.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Traición
                    </h3>
                    <p>
                      La traición en la infancia se refiere a la experiencia de
                      ser traicionado por aquellos en quienes se confiaba, como
                      padres, familiares o amigos cercanos. Puede manifestarse a
                      través de la ruptura de promesas, la falta de lealtad o la
                      revelación de secretos.
                    </p>
                    <p>
                      La traición puede generar
                      <strong> dificultades para confiar en los demás</strong>,
                      creando barreras en las relaciones interpersonales. Las
                      personas que han experimentado traición en la infancia
                      pueden volverse <strong>cautelosas y reservadas</strong>,
                      evitando ponerse en situaciones donde puedan ser
                      vulnerables emocionalmente.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      5. Injusticia
                    </h3>
                    <p>
                      La percepción de injusticia en la infancia puede surgir
                      cuando un niño experimenta tratamientos desiguales o
                      injustos en comparación con sus pares. Esto puede ocurrir
                      en el ámbito familiar, escolar o social. La injusticia
                      puede llevar a una sensación de
                      <strong> falta de control</strong> sobre la propia vida,
                      generando resentimiento y frustración. <br />
                      <br /> En la edad adulta, aquellos que han experimentado
                      heridas de injusticia pueden tener dificultades para{" "}
                      <strong>aceptar situaciones fuera de su control</strong> y
                      pueden desarrollar patrones de comportamiento destinados a
                      restaurar un sentido de equidad.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Superando las heridas de la infancia
                    </h2>
                    <img
                      src={superation}
                      className="img-fluid m-auto d-block"
                      style={{ maxHeight: "40vh" }}
                      alt="hombre superando heridas de la infancia"
                    />
                    <p>
                      Superar las heridas de la infancia es un proceso que
                      requiere tiempo, <strong>autoconocimiento </strong>y, en
                      muchos casos, la ayuda de{" "}
                      <strong> profesionales de la salud mental. </strong>
                      Reconocer la existencia de estas heridas y comprender cómo
                      han influido en la vida de uno es el{" "}
                      <strong>primer paso</strong> hacia la curación.{" "}
                    </p>
                    <p>
                      {" "}
                      La <strong>terapia psicológica</strong>, ya sea individual
                      o grupal, puede ser una herramienta invaluable para
                      abordar y sanar estas heridas emocionales.
                    </p>
                    <p>
                      Además, la práctica de la autocompasión y el desarrollo de
                      habilidades emocionales pueden contribuir
                      significativamente al proceso de curación.
                    </p>
                    <p>
                      Aprender a establecer límites saludables, construir
                      relaciones de apoyo y trabajar en la construcción de una
                      autoimagen positiva son pasos cruciales para superar las
                      heridas de la infancia.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Conclusión
                    </h2>
                    <p>
                      Las heridas de la infancia son una realidad que muchas
                      personas enfrentan, pero no están condenadas a definir su
                      vida.
                    </p>
                    <p>
                      Con conciencia, comprensión y apoyo adecuado, es posible{" "}
                      <strong>sanar</strong> y construir una base emocional más
                      sólida para el bienestar en la edad adulta.
                    </p>
                    <p>
                      La búsqueda de <strong> ayuda profesional</strong> y el
                      compromiso personal con el proceso de curación son pasos
                      fundamentales hacia una vida más plena y equilibrada.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Preguntas Frecuentes (FAQs)
                    </h2>
                    <p>
                      <strong>
                        1. ¿Cómo puedo identificar si tengo heridas de la
                        infancia?
                      </strong>
                    </p>
                    <p className="text-muted">
                      La identificación de las heridas de la infancia puede ser
                      un proceso complejo. Prestar atención a patrones
                      recurrentes en el comportamiento, relaciones o
                      pensamientos negativos sobre uno mismo puede ser un
                      indicativo. La búsqueda de la ayuda de un profesional de
                      la salud mental también puede proporcionar claridad y
                      orientación.
                    </p>
                    <p>
                      <strong>
                        2. ¿Todas las personas desarrollan heridas de la
                        infancia?
                      </strong>
                    </p>
                    <p className="text-muted">
                      Si bien no todas las personas experimentan heridas de la
                      infancia en el mismo grado, es común que todos enfrenten
                      desafíos emocionales durante su desarrollo. Las
                      experiencias negativas en la infancia pueden variar en
                      intensidad y duración.
                    </p>
                    <p>
                      <strong>
                        3. ¿Las heridas de la infancia se pueden superar?
                      </strong>
                    </p>
                    <p className="text-muted">
                      Sí, las heridas de la infancia se pueden superar con el
                      tiempo y el esfuerzo adecuado. La búsqueda de apoyo
                      terapéutico, el autoconocimiento y el compromiso personal
                      con el proceso de curación son fundamentales para superar
                      estas heridas emocionales.
                    </p>
                    <p>
                      <strong>
                        4. ¿Es necesario buscar ayuda profesional para superar
                        las heridas de la infancia?
                      </strong>
                    </p>
                    <p className="text-muted">
                      La ayuda profesional, como la terapia psicológica, puede
                      ser invaluable en el proceso de superar las heridas de la
                      infancia. Los profesionales de la salud mental están
                      capacitados para proporcionar orientación, herramientas y
                      estrategias específicas para abordar y sanar estas
                      heridas.
                    </p>
                    <p>
                      <strong>
                        5. ¿Cuánto tiempo lleva superar las heridas de la
                        infancia?
                      </strong>
                    </p>
                    <p className="text-muted">
                      El tiempo necesario para superar las heridas de la
                      infancia varía según la persona y la intensidad de las
                      experiencias vividas. El compromiso personal, la
                      consistencia en el trabajo emocional y la búsqueda de
                      ayuda profesional pueden acelerar el proceso de curación.
                    </p>

                    <p>
                      Si tú o alguien que conoces está sufriendo debido a
                      heridas de la infancia, en{" "}
                      <a
                        href="https://www.mindly.la/home"
                        className="text-primary"
                      >
                        Mindly
                      </a>
                      , tenemos a un grupo de psicólogos expertos en salud
                      mental listos para ofrecer el servicio de mayor calidad.{" "}
                      <br /> <br />
                      ¡Agenda tu sesión hoy mismo! <br></br> <br></br> La{" "}
                      <strong>primera sesión</strong> cuenta con un
                      <strong> 50% OFF</strong>.{" "}
                      <Link
                        to="/register"
                        className="text-primary text-decoration-underline"
                      >
                        ¡Regístrate ahora!
                      </Link>
                      <br></br>
                    </p>
                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>102
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
