import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { Helmet } from "react-helmet";
//import images
import standard from "../../../assets/images/blog/narcisismo/narcisismo.jpg";
import narcisismo from "../../../assets/images/blog/narcisismo/narcisismo-2-min.png";

class BlogStandardPost extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            La Personalidad Narcisista - ¿Qué es? ¿Cuáles son sus Síntomas?
          </title>
          <meta
            name="description"
            content="Un narcisista es una persona que exhibe un excesivo interés y admiración por sí misma. Aprender mas sobre qué significa este trastorno. ¡Descúbrelo! "
          />
        </Helmet>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row>
              <Col lg={8} md={7}>
                <Card className="border-0 shadow rounded overflow-hidden">
                  <img
                    src={standard}
                    className="img-fluid"
                    alt="Mujer mirandose en el espejo"
                  />

                  <CardBody>
                    <div className="text-center">
                      <span className="badge bg-primary">
                        Qué es el Narcisismo
                      </span>
                      <h1 className="mt-3" style={{ fontSize: 30 }}>
                        Personalidad narcisista: ¿Qué es y cuales son sus
                        Síntomas?
                      </h1>

                      <ul className="list-unstyled mt-3">
                        <li className="list-inline-item user text-muted me-2">
                          <i className="mdi mdi-account"></i> Daniela Castro
                        </li>{" "}
                        <li className="list-inline-item date text-muted">
                          <i className="mdi mdi-calendar-check"></i> 29 de
                          Noviembre, 2023
                        </li>
                      </ul>
                    </div>

                    <p className="mt-3">
                      El <strong>narcisismo</strong> se caracteriza por un
                      sentido exagerado de la autoimportancia, la necesidad
                      constante de admiración y la{" "}
                      <strong>falta de empatía</strong> hacia los demás.
                    </p>
                    <p>
                      Aquellos con una personalidad narcisista a menudo buscan
                      constantemente la validación y la atención, mientras que
                      pueden mostrar poco interés o comprensión hacia las
                      necesidades y sentimientos de los demás.
                    </p>
                    <p>
                      En este artículo, exploraremos el narcisismo,{" "}
                      <strong className="text-primary">qué es</strong> y{" "}
                      <strong className="text-primary">
                        cuales son sus síntomas
                      </strong>
                    </p>
                    {/* <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Las expectativas, como hojas al viento, pueden ser el
                        combustible de nuestros sueños o el humo de nuestras
                        desilusiones. Aprender a navegar entre ellas con
                        sabiduría es el arte de encontrar equilibrio en un mundo
                        de posibilidades."
                      </p>
                    </blockquote> */}
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Qué es ser Narcisista?
                    </h2>
                    <p>
                      Ser narcisista implica tener una{" "}
                      <strong>visión inflada</strong> de uno mismo, una creencia
                      de que se es excepcionalmente talentoso, atractivo o
                      único. Los individuos con esta personalidad tienden a
                      <strong> sobrevalorar sus logros</strong> y subestimar los
                      de los demás.
                    </p>
                    <p>
                      La obsesión por el éxito, el poder y la belleza es una
                      característica clave. Además, a menudo se perciben como
                      merecedores de trato especial y esperan que los demás
                      estén constantemente a su disposición.
                    </p>
                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Síntomas de la Personalidad Narcisista:
                    </h2>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Falta de Empatía:
                    </h3>
                    <p>
                      La empatía, la capacidad de entender y compartir los
                      <strong> sentimientos de los demás</strong>, suele ser
                      escasa en individuos con personalidad narcisista. Tienden
                      a centrarse en sus propias necesidades y deseos, sin
                      prestar atención a las preocupaciones de los demás.
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Necesidad de Admiración Constante:
                    </h3>
                    <p>
                      Las personas narcisistas buscan una admiración constante
                      de quienes les rodean.{" "}
                      <strong>Necesitan sentirse validados</strong> y elogiados
                      regularmente, y pueden volverse intolerantes o irritables
                      si no reciben la atención que creen merecer.
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Fantasías de Éxito Ilimitado, Poder, Belleza o Amor
                      Ideal:
                    </h3>
                    <p>
                      Los narcisistas a menudo tienen fantasías grandiosas sobre
                      su propio éxito, poder, apariencia física o relaciones
                      amorosas. Estas fantasías exageradas pueden contribuir a
                      una <strong>desconexión con la realidad.</strong>
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      4. Creencia en la Propia Importancia:
                    </h3>
                    <p>
                      La creencia de que son únicos y excepcionales es una
                      característica común. Sienten que solo pueden ser
                      comprendidos por personas especiales o instituciones de
                      alto estatus.
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      5. Explotación de los Demás:
                    </h3>
                    <p>
                      Los narcisistas pueden aprovecharse de las personas a su
                      alrededor para alcanzar sus propios objetivos. Esto puede
                      manifestarse en relaciones interpersonales, en el trabajo
                      o en otros entornos sociales.
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      6. Envidia y Creencia de Ser Envidiado:
                    </h3>
                    <p>
                      Aunque pueden envidiar a otros, los narcisistas creen que
                      son envidiados por todos. Esta contradicción refleja su
                      necesidad constante de{" "}
                      <strong>sentirse superiores.</strong>
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Causas de la Personalidad Narcisista:
                    </h2>
                    <img
                      src={narcisismo}
                      className="img-fluid m-auto d-block"
                      style={{ maxHeight: "30vh" }}
                      alt="hombre superando heridas de la infancia"
                    />
                    <p>
                      Determinar las causas exactas de la personalidad
                      narcisista puede ser complejo, ya que suele ser el
                      resultado de una <strong> combinación de factores</strong>{" "}
                      genéticos, ambientales y psicológicos. Algunos de estos
                      factores pueden incluir:
                    </p>
                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      1. Factores Genéticos:
                    </h3>
                    <p>
                      Se ha sugerido que la predisposición genética puede
                      contribuir al desarrollo de la personalidad narcisista. La
                      herencia de ciertos rasgos de personalidad puede aumentar
                      la probabilidad de que alguien desarrolle este trastorno.
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      2. Ambiente Familiar:
                    </h3>
                    <p>
                      Experiencias en la infancia, como la sobrevaloración
                      excesiva o la infravaloración, pueden influir en el
                      desarrollo de la personalidad narcisista. Un entorno donde
                      el individuo ha sido constantemente sobrevalorado y no ha
                      experimentado límites claros puede contribuir a la
                      formación de estos patrones de comportamiento.
                    </p>

                    <h3
                      className="my-4 text-decoration-underline"
                      style={{ fontSize: 20 }}
                    >
                      3. Trauma o Abuso:
                    </h3>
                    <p>
                      Experiencias traumáticas o abusivas durante la infancia
                      también pueden desempeñar un papel en el desarrollo de la
                      personalidad narcisista. El mecanismo de defensa de
                      sobrevalorarse puede surgir como una forma de protección
                      psicológica.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      ¿Cómo Manejar a Alguien con Personalidad Narcisista?
                    </h2>
                    <p>
                      Lidiar con alguien que tiene una personalidad narcisista
                      puede ser desafiante, pero hay estrategias que pueden
                      ayudar a manejar estas relaciones:
                    </p>

                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      1. Establecer Límites Claros:
                    </h3>
                    <p>
                      Es esencial establecer límites claros y comunicar de
                      manera efectiva qué comportamientos son inaceptables.
                      Mantener límites firmes puede ayudar a evitar la
                      <strong> manipulación y la explotación.</strong>
                    </p>

                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      2. Fomentar la Comunicación Abierta:
                    </h3>
                    <p>
                      Fomentar un ambiente de comunicación abierta puede
                      permitir que la persona narcisista exprese sus necesidades
                      de manera más saludable. Sin embargo, es importante
                      establecer límites para garantizar que la comunicación sea
                      <strong> bidireccional.</strong>
                    </p>

                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      3. Buscar Apoyo Profesional:
                    </h3>
                    <p>
                      La{" "}
                      <strong className="text-primary">
                        {" "}
                        terapia psicológica,
                      </strong>{" "}
                      ya sea individual o en grupo, puede ser beneficiosa tanto
                      para la persona narcisista como para aquellos que
                      interactúan con ella. Un terapeuta puede proporcionar
                      herramientas y estrategias para abordar los patrones de
                      comportamiento disfuncionales.
                    </p>

                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      4. Cultivar la Empatía:
                    </h3>
                    <p>
                      Fomentar la empatía en la persona narcisista puede ser un
                      proceso gradual. Destacar la importancia de comprender las
                      emociones y necesidades de los demás puede contribuir al
                      desarrollo de habilidades emocionales más saludables.
                    </p>

                    <h3 className="my-4" style={{ fontSize: 20 }}>
                      5. Reconocer y Afrontar la Manipulación:
                    </h3>
                    <p>
                      Es crucial reconocer cuando se está siendo manipulado y
                      aprender a afrontarlo. La conciencia de las tácticas
                      manipuladoras puede ayudar a contrarrestarlas de manera
                      efectiva.
                    </p>

                    <h2 className="my-4" style={{ fontSize: 25 }}>
                      Conclusión
                    </h2>
                    <p>
                      La <strong>personalidad narcisista</strong> es un fenómeno
                      complejo que afecta la forma en que una persona se
                      relaciona consigo misma y con los demás. Comprender los
                      <strong> síntomas</strong>, las <strong>causas</strong> y
                      las <strong>estrategias</strong> para manejar a alguien
                      con este trastorno puede ser fundamental para establecer
                      relaciones más saludables y constructivas.
                    </p>

                    <p>
                      Si tú o alguien que conoces está sufriendo debido a un
                      trastorno de la personalidad narcisista, en{" "}
                      <a
                        href="https://www.mindly.la/home"
                        className="text-primary"
                      >
                        Mindly
                      </a>
                      , tenemos a un grupo de{" "}
                      <strong>psicólogos expertos</strong> en salud mental
                      listos para ofrecer el servicio de mayor calidad. <br />{" "}
                      <br />
                      ¡Agenda tu sesión hoy mismo! <br></br> <br></br> La{" "}
                      <strong>primera sesión</strong> cuenta con un
                      <strong> 50% OFF</strong>.{" "}
                      <Link
                        to="/register"
                        className="text-primary text-decoration-underline"
                      >
                        ¡Regístrate ahora!
                      </Link>
                      <br></br>
                    </p>
                    <div className="post-meta mt-3">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item me-2 mb-0">
                          <Link to="#" className="text-muted like">
                            <i className="uil uil-heart me-1"></i>153
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <BlogSidebar />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default BlogStandardPost;
