import React from "react";
import { Redirect } from "react-router-dom";

import Home from "../pages/Home/index";
import Register from "../pages/AuthPages/PageSignup";
import Login from "../pages/AuthPages/PageLogin";
import Recovery from "../pages/AuthPages/PageRecoveryPassword";
import MiEspacio from "../pages/MiEspacio";
import MisCitas from "../pages/MisCitas/";
import Especialistas from "../pages/Especialistas/";
import Agendar from "../pages/Agendar/";
import Sesiones from "../pages/Sesiones/";
import MiCuenta from "../pages/MiCuenta/";
import PaymentPage from "../pages/PaymentPage";
import PaymentSucess from "../pages/PaymentSucess";
import Cita from "../pages/Cita";
import PsicologoPage from "../pages/PsicologoPage";
import PageTerms from "../pages/Terms/PageTerms";
import PageChangePassword from "../pages/AuthPages/PageChangePassword";
import PageOAuthSucceed from "../pages/AuthPages/PageOAuthSucceed";
import PsicolgosSingUp from "../pages/RegistroPsicologos/PsicolgosSingUp";
import Calendar from "../pages/Calendar/Calendar";
import PsiclogosLanding from "../pages/PsicologosLanding/";
import Resenia from "../pages/Resenia";
import MisHorarios from "../pages/MisHorarios";
import Cobros from "../pages/Cobros";
import PsicologoProfile from "../pages/PsicologoProfile";
import AdminPagos from "../pages/AdminPagos";
import AdminPagosCSV from "../pages/AdminPagosCSV";
import AdminValidarPsicologos from "../pages/AdminValidarPsicologos";
import Error from "../pages/Error";
import Blog from "../pages/Blog";
import AdminRegistroPsicologos from "../pages/AdminRegistroPsicologos";
import Contact from "../pages/Contact";
import Chat from "../pages/Chat";
import Paquetes from "../pages/Paquetes";
import PaquetePaymentPage from "../pages/PaquetePaymentPage";
import PaquetePaymentSuccess from "../pages/Paquetes/PaquetePaymentSuccess";
import AdminPsicologosPendientes from "../pages/AdminPsicologosPendientes";
import Descansos from "../pages/Descansos";
import OnBoarding from "../pages/OnBoarding";

import Expectativas1 from "../pages/Articles/expectativas/Expectativas1";
import Empatia1 from "../pages/Articles/empatia/Empatia1";
import Emociones1 from "../pages/Articles/emociones/Emociones1";
import Mitomania from "../pages/Articles/mitomania/Mitomania";
import HeridasInfancia from "../pages/Articles/heridasDeInfancia/HeridasInfancia";
import AmorPropio from "../pages/Articles/amorPropio/AmorPropio";
import Narcisismo from "../pages/Articles/narcisismo/Narcisismo";
import AyudarDepresion from "../pages/Articles/ayudarDepresion/AyudarDepresion";
import ManejoEmociones from "../pages/Articles/manejoEmociones/ManejoEmociones";

const routes = [
  { path: "/home", component: Home },
  { path: "/login", component: Login },
  {
    path: "/oauth-succeed",
    component: PageOAuthSucceed,
    isWithoutLayout: true,
  },
  { path: "/contact-us", component: Contact },
  { path: "/register", component: Register },
  { path: "/on-boarding", component: OnBoarding, isWithoutLayout: true },
  { path: "/recovery", component: Recovery },
  { path: "/blog", component: Blog, isWithBlogLayout: true },
  {
    path: "/blog/expectativas",
    component: Expectativas1,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/que-es-narcisista",
    component: Narcisismo,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/como-ayudar-personas-con-depresion",
    component: AyudarDepresion,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/que-es-la-empatia-definicion-importancia-desarrollo",
    component: Empatia1,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/tipos-de-emociones-como-gestionarlas",
    component: Emociones1,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/manejo-de-emociones",
    component: ManejoEmociones,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/mitomania-sintomas-causas-tratamiento",
    component: Mitomania,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/5-heridas-de-la-infancia",
    component: HeridasInfancia,
    isWithBlogLayout: true,
  },
  {
    path: "/blog/amor-propio-y-autoestima",
    component: AmorPropio,
    isWithBlogLayout: true,
  },
  { path: "/para-especialistas", component: PsiclogosLanding },
  { path: "/registro-psicologos", component: PsicolgosSingUp },
  { path: "/terminos-y-condiciones", component: PageTerms },
  { path: "/mi-espacio/mis-citas", component: MisCitas, isWithHubLayout: true },
  {
    path: "/mi-espacio/mis-citas/:citaId",
    component: Cita,
    isWithHubLayout: true,
  },
  { path: "/cambio-de-contrasena", component: PageChangePassword },
  {
    path: "/mi-espacio/paquetePayment/success",
    component: PaquetePaymentSuccess,
    isWithHubLayout: true,
  },
  {
    path: "/mi-espacio/paquetePayment/:tipoPaquete",
    component: PaquetePaymentPage,
    isWithHubLayout: true,
  },
  {
    path: "/mi-espacio/payment/:citaId",
    component: PaymentPage,
    isWithHubLayout: true,
  },

  {
    path: "/mi-espacio/payment/:citaId/success",
    component: PaymentSucess,
    isWithHubLayout: true,
  },
  {
    path: "/mi-espacio/especialistas",
    component: Especialistas,
    isWithHubLayout: true,
  },
  {
    path: "/mi-espacio/especialistas/:psicologoId",
    component: PsicologoPage,
    isWithHubLayout: true,
  },
  {
    path: "/mi-espacio/paquetes",
    component: Paquetes,
    isWithHubLayout: true,
  },
  {
    path: "/mi-espacio/mi-cuenta",
    component: MiCuenta,
    isWithHubLayout: true,
  },
  {
    path: "/mi-espacio/cuenta",
    component: MiCuenta,
    isWithPsicologoHubLayout: true,
  },
  {
    path: "/mi-espacio/especialistas/:psicologoId/agendar",
    component: Agendar,
    isWithHubLayout: true,
  },
  { path: "/mi-espacio", component: MiEspacio, isWithHubLayout: true },
  { path: "/sesion", component: Sesiones },
  { path: "/chat", component: Chat, isWithHubLayout: true },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/mi-espacio/agenda",
    component: Calendar,
    isWithPsicologoHubLayout: true,
  },
  {
    path: "/opiniones/:psicologoId",
    component: Resenia,
    isWithoutLayout: true,
  },
  {
    path: "/mi-espacio/mis-horarios/descansos",
    component: Descansos,
    isWithPsicologoHubLayout: true,
  },
  {
    path: "/mi-espacio/mis-horarios",
    component: MisHorarios,
    isWithPsicologoHubLayout: true,
  },
  {
    path: "/mi-espacio/cobros",
    component: Cobros,
    isWithPsicologoHubLayout: true,
  },
  {
    path: "/mi-espacio/mi-perfil",
    component: PsicologoProfile,
    isWithPsicologoHubLayout: true,
  },
  {
    path: "/mi-espacio/chat",
    component: Chat,
    isWithPsicologoHubLayout: true,
  },
  {
    path: "/admin/pagos",
    component: AdminPagos,
    isWithAdminHubLayout: true,
  },
  {
    path: "/admin/generar-csv",
    component: AdminPagosCSV,
    isWithAdminHubLayout: true,
  },
  {
    path: "/admin/registro-psicologos",
    component: AdminRegistroPsicologos,
    isWithAdminHubLayout: true,
  },
  {
    path: "/admin/validar-psicologos",
    component: AdminValidarPsicologos,
    isWithAdminHubLayout: true,
  },
  {
    path: "/admin/psicologos-pendientes",
    component: AdminPsicologosPendientes,
    isWithAdminHubLayout: true,
  },
  {
    path: "/*",
    component: Error,
    isWithoutLayout: true,
  },
];

export default routes;
