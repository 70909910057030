import React, { useEffect, useState, useRef } from "react";
import "../../assets/css/misHorarios.css";
import DescansoInput from "./DescansoInput";
import axiosInstance from "../../helpers/axiosConfig";
import autoAnimate from "@formkit/auto-animate";

// card for each day of the week
const DayDescanso = ({ dayOfWeek, availablity }) => {
  const [descansoInputs, setDescansoInputs] = useState([]);
  const [descansos, setDescansos] = useState([]);
  const daysMapCodes = {
    Lunes: "lun",
    Martes: "mar",
    Miércoles: "mié",
    Jueves: "jue",
    Viernes: "vie",
    Sábado: "sáb",
    Domingo: "dom",
  };

  //Animations
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 200 });
  }, [parent]);

  useEffect(() => {
    const fetchDescansosByDayOfWeek = async () => {
      try {
        const response = await axiosInstance.get(
          `psicologos/descanso/${daysMapCodes[dayOfWeek]}`
        );
        if (response.status === 200) {
          setDescansos(response.data.body);
        }
      } catch (error) {
        console.error("Error fetching descansos:", error);
      }
    };

    fetchDescansosByDayOfWeek();
  }, [dayOfWeek]);

  useEffect(() => {
    if (
      descansos?.DescansoRangeTimes &&
      descansos.DescansoRangeTimes.length > 0
    ) {
      addExistingDescansoInputs();
    }
  }, [descansos]);

  const agregarNuevoDescansoInput = () => {
    setDescansoInputs([
      ...descansoInputs,
      <DescansoInput
        key={descansoInputs.length}
        dayOfWeekCode={daysMapCodes[dayOfWeek]}
        newDescanso={true}
        availablity={availablity}
      />,
    ]);
  };

  const addExistingDescansoInputs = () => {
    const inputs = descansos.DescansoRangeTimes.map((descansoRangeTime) => (
      <DescansoInput
        key={descansoInputs.length}
        dayOfWeekCode={daysMapCodes[dayOfWeek]}
        newDescanso={false}
        descansoRange={descansoRangeTime}
        descansoRangeTimeId={descansoRangeTime.id}
        availablity={availablity}
      />
    ));
    setDescansoInputs(inputs);
  };

  return (
    <>
      <div className="dayOfWeek-container">
        <p className="m-0 p-0 dayOfWeek-text">{dayOfWeek}</p>
        <button
          className="btn btn-primary btn-agregarDescanso"
          onClick={agregarNuevoDescansoInput}
        >
          Agregar Descanso
        </button>
        <div className="descansoComponentInputs-container" ref={parent}>
          {descansoInputs.map((input, index) => (
            <div key={index}>{input}</div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DayDescanso;
