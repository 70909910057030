import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { Spinner } from "reactstrap";
import { Helmet } from "react-helmet";

import "../../assets/css/thankyou.css";

function PaquetePaymentSuccess() {
  const location = useLocation();
  const paymentIntentId = new URLSearchParams(location.search).get(
    "payment_intent"
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const addCitasToPaciente = async () => {
      try {
        await axiosInstance.post("/payment/addCitasToPaciente", {
          paymentIntentId,
        });
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    addCitasToPaciente();
  }, [axiosInstance]);

  return (
    <>
      <Helmet>
        <title>Mindly | Gracias por tu pago</title>
        <meta name="description" content="Gracias por tu pago" />
      </Helmet>
      <section className="bg-home d-flex m-0 p-0">
        <Container className="thank-you-container">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="text-center">
                {isLoading ? (
                  <Spinner color="primary" />
                ) : (
                  <>
                    <div
                      className="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                      style={{ height: "90px", width: "90px" }}
                    >
                      <i className="bx bx-check-circle align-middle h1 mb-0"></i>
                    </div>
                    <h1 className="my-4 fw-bold">Genial!</h1>
                    <p className="text-muted para-desc mx-auto">
                      Has completado tu pago con éxito. Puedes tus citas
                      disponibles en el área "Mi Cuenta" o al momento de agendar
                      una. ¡Gracias por confiar en nosotros! 🎉
                    </p>
                    <Link
                      to="/mi-espacio/mi-cuenta"
                      className="btn btn-soft-primary mt-3"
                    >
                      Ir a mi cuenta
                    </Link>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default PaquetePaymentSuccess;
