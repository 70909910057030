import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import AccordianCommon2 from "./FAQAccordian.js";

import travel from "../../assets/images/travel/3.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

export default class Guarantee extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60" id="faq">
          <Row className="align-items-center">
            <Col lg={5} md={6} className="order-1 order-md-2">
              <img
                src={travel}
                className="img-fluid rounded shadow-md"
                alt="mujer con su ipad"
              />
            </Col>
            <Col
              lg={7}
              md={6}
              className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
            >
              <div className="section-title me-lg-5">
                <h4 className="title mb-4">Preguntas frecuentes 💭</h4>
                <p className="text-muted">
                  Algunas de las preguntas más frecuentes que recibimos de
                  nuestros pacientes. Si tienes alguna otra pregunta, no dudes
                  en{" "}
                  <Link to="/contact-us" className="text-primary">
                    contactarnos
                  </Link>
                  .{" "}
                </p>
              </div>
              <AccordianCommon2
                question1="Áreas tratadas"
                answer1="Los psicólogos en Mindly abarcan diversos temas, entre ellos: ansiedad, estrés, depresión, pareja, autoestima, desarrollo personal, ámbito laboral y sexualidad."
                question2="¿Dónde se llevará a cabo mi cita?                "
                answer2="Las sesiones se llevan a cabo a través de nuestra plataforma para garantizar la privacidad y seguridad de los usuarios."
                question3="¿Puedo cambiarme de psicólogo?                "
                answer3="Sin duda alguna. Como paciente, tienes la posibilidad de cambiar de especialista en cualquier momento, ya sea debido a problemas de horarios, falta de empatía personal, o cualquier otro motivos."
                question4="¿Qué experiencia tienen los profesionales?"
                answer4="Todos nuestros psicólogos son licenciados profesionales en psicología y cuentan con una amplia experiencia en el ámbito clínico. A su vez, cada uno de ellos pasa por un riguroso proceso de selección para garantizar una calidad de servicio excepcional."
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
