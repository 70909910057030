import React, { useEffect, useState } from "react";
import { Card, Alert } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";
import { useHistory } from "react-router-dom";
import ListingWithThumbnail from "../../components/Shared/Loaders/ListingWithThumbNail";
import defaultProfilePicture from "../../assets/images/def-profile-picture-min.png";

function CitaCard({ cita }) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [psicologo, setPsicologo] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [profilePictureLoaded, setprofilePictureLoaded] = useState(false);

  useEffect(() => {
    async function getPsicologoData() {
      try {
        const response = await axiosInstance.get(
          `/psicologos/${cita.psicologo_id}`
        );
        if (response.data.status === 200) {
          setPsicologo(response.data.body);
          setIsLoading(false);
        }
      } catch (error) {
        setError("Error al obtener el psicologo");
      }
    }
    getPsicologoData();
  }, []);

  const navigateCita = () => {
    history.push(`/mi-espacio/mis-citas/${cita.id}`);
  };

  return (
    <>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {isLoading && <ListingWithThumbnail className="mt-2" />}
      {psicologo && !isLoading && (
        <Card
          className="shadow rounded border-0 overflow-hidden cita-card"
          onClick={navigateCita}
        >
          <div className="card-img">
            {profilePictureLoaded ? null : (
              <img
                src={defaultProfilePicture}
                className="img-fluid"
                alt="Cargando..."
              ></img>
            )}
            <img
              style={profilePictureLoaded ? {} : { display: "none" }}
              src={psicologo.profile_picture_url}
              alt="psicologo"
              className="img-fluid"
              onLoad={() => setprofilePictureLoaded(true)}
            />
          </div>
          <div className="body-card">
            <p className="card-name">
              {psicologo.nombre + " " + psicologo.apellido}
            </p>
            <p className="card-date">
              {new Date(cita.fecha).toLocaleString("es-AR", {
                dateStyle: "medium",
                timeZone: userTimeZone,
              })}{" "}
              •{" "}
              {new Date(cita.fecha).toLocaleString("es-AR", {
                timeZone: userTimeZone,
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
            <p className="card-status">
              {(() => {
                switch (cita.estado_id) {
                  case 1:
                    return (
                      <small className="text-warning">
                        <i className="bx bx-time"></i> Pendiente de pago
                      </small>
                    );
                  case 2:
                    return <small className="text-success">Confirmada</small>;
                  case 3: {
                    const citaFecha = new Date(cita.fecha);
                    const now = new Date();
                    const differenceInHours =
                      (now - citaFecha) / (1000 * 60 * 60);

                    if (differenceInHours >= 1) {
                      return <small className="text-primary">Completada</small>;
                    } else {
                      return <small className="text-success">Confirmada</small>;
                    }
                  }
                  case 4:
                    return <small className="text-danger">Cancelada</small>;
                  default:
                    return <small className="text-danger">Error</small>;
                }
              })()}
            </p>
          </div>
          <div className="card-icon">
            <i className="bx bx-chevron-right"></i>
          </div>
        </Card>
      )}
    </>
  );
}

export default CitaCard;
