import { useEffect, useRef } from "react";
import React from "react";
import { useDyteClient } from "@dytesdk/react-web-core";
import { DyteMeeting, provideDyteDesignSystem } from "@dytesdk/react-ui-kit";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Sesiones() {
  const [meeting, initMeeting] = useDyteClient();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authToken = searchParams.get("authToken");
  const meetingEl = useRef();

  useEffect(() => {
    initMeeting({
      authToken: authToken,
      defaults: {
        audio: false,
        video: false,
      },
    });

    provideDyteDesignSystem(meetingEl.current, {
      googleFont: "Nunito",
      theme: "light",
      colors: {
        danger: "#de0000",
        brand: {
          500: "#6F33FA",
        },
        text: "#071428",
        "text-on-brand": "#ffffff",
        "video-bg": "#E4E9F7",
      },
      borderRadius: "extra-rounded",
    });
  }, [authToken]);

  const EslangDict = {
    about_call: "Acerca de la llamada",
    screen: "Pantalla",
    leave: "Salir",
    dismiss: "Descartar",
    logo: "Logo",
    page: "Página",
    more: "Más",
    "page.prev": "Anterior",
    "page.next": "Siguiente",
    layout: "Diseño",
    "layout.auto": "Automático",
    settings: "Configuración",
    file: "Archivo",
    image: "Imagen",
    connection: "Conexión",
    leave_confirmation: "Estas seguro de que quieres salir?",
    cancel: "Cancelar",
    yes: "Sí",
    you: "Tú",
    to: "a",
    mute: "Silenciar",
    kick: "Expulsar",
    pin: "Anclar",
    pinned: "Anclado",
    unpin: "Desanclar",
    pip_on: "PIP activado",
    pip_off: "PIP desactivado",
    viewers: "Espectadores",
    join: "Unirse",
    joined: "Unido",
    create: "Crear",
    close: "Cerrar",
    ask: "Preguntar",
    type: "Tipo",
    activate: "Activar",
    mic_off: "Micrófono apagado",
    disable_mic: "Desactivar micrófono",
    mic_on: "Micrófono encendido",
    enable_mic: "Activar micrófono",
    audio: "Audio",
    mute_all: "Silenciar todo",
    "mute_all.description": "Silenciar a todos los participantes",
    "mute_all.header": "Silenciar a todos",
    "mute_all.allow_unmute": "Permitir que los participantes se desactiven",
    video_off: "Vídeo apagado",
    disable_video: "Desactivar vídeo",
    video_on: "Vídeo encendido",
    enable_video: "Activar vídeo",
    video: "Vídeo",
    participants: "Participantes",
    "participants.errors.empty_results": "No se encontraron resultados",
    "participants.empty_list": "Lista vacía",
    "participants.turn_off_video": "Apagar vídeo",
    polls: "Encuestas",
    "polls.by": "por",
    "polls.question": "Pregunta",
    "polls.question.placeholder": "Introduzca la pregunta",
    "polls.option": "Opción",
    "polls.option.placeholder": "Introduzca la opción",
    "polls.results.anon": "Anónimo",
    "polls.results.hide": "Ocultar resultados",
    "polls.create": "Crear",
    "polls.cancel": "Cancelar",
    "polls.errors.question_required": "La pregunta es obligatoria",
    "polls.errors.empty_option": "La opción no puede estar vacía",
    screenshare: "Compartir pantalla",
    "screenshare.min_preview": "Vista previa mínima",
    "screenshare.max_preview": "Vista previa máxima",
    "screenshare.shared": "Compartido",
    "screenshare.start": "Iniciar",
    "screenshare.stop": "Detener",
    "screenshare.error.unknown": "Error desconocido",
    "screenshare.error.max_count": "Se ha alcanzado el recuento máximo",
    plugins: "Plugins",
    perm_denied: "Permiso denegado",
    "perm_denied.audio": "Permiso denegado para audio",
    "perm_denied.video": "Permiso denegado para vídeo",
    "perm_denied.screenshare": "Permiso denegado para compartir pantalla",
    "perm_denied.audio.chrome.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra pestaña.",
    "perm_denied.video.chrome.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra pestaña.",
    "perm_denied.screenshare.chrome.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra pestaña.",
    "perm_denied.audio.safari.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra pestaña.",
    "perm_denied.video.safari.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra pestaña.",
    "perm_denied.screenshare.safari.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra pestaña.",
    "perm_denied.audio.edge.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra pestaña.",
    "perm_denied.video.edge.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra pestaña.",
    "perm_denied.screenshare.edge.message":
      "No se puede compartir la pantalla.",
    "perm_denied.audio.firefox.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra pestaña.",
    "perm_denied.video.firefox.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra pestaña.",
    "perm_denied.screenshare.firefox.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra pestaña.",
    "perm_denied.audio.others.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra pestaña.",
    "perm_denied.video.others.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra pestaña.",
    "perm_denied.screenshare.others.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra pestaña.",
    perm_sys_denied: "Permiso denegado",
    "perm_sys_denied.audio": "Permiso denegado para audio",
    "perm_sys_denied.video": "Permiso denegado para vídeo",
    "perm_sys_denied.screenshare": "Permiso denegado para compartir pantalla",
    "perm_sys_denied.audio.macos.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra aplicación.",
    "perm_sys_denied.video.macos.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra aplicación.",
    "perm_sys_denied.screenshare.macos.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra aplicación.",
    "perm_sys_denied.audio.ios.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra aplicación.",
    "perm_sys_denied.video.ios.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra aplicación.",
    "perm_sys_denied.screenshare.ios.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra aplicación.",
    "perm_sys_denied.audio.windows.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra aplicación.",
    "perm_sys_denied.video.windows.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra aplicación.",
    "perm_sys_denied.screenshare.windows.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra aplicación.",
    "perm_sys_denied.audio.android.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra aplicación.",
    "perm_sys_denied.video.android.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra aplicación.",
    "perm_sys_denied.screenshare.android.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra aplicación.",
    "perm_sys_denied.audio.others.message":
      "No se puede acceder al micrófono. Asegúrese de que el micrófono no esté en uso en otra aplicación.",
    "perm_sys_denied.video.others.message":
      "No se puede acceder a la cámara. Asegúrese de que la cámara no esté en uso en otra aplicación.",
    "perm_sys_denied.screenshare.others.message":
      "No se puede compartir la pantalla. Asegúrese de que la pantalla no esté en uso en otra aplicación.",
    perm_could_not_start: "No se pudo iniciar",
    "perm_could_not_start.audio": "No se pudo iniciar el audio",
    "perm_could_not_start.video": "No se pudo iniciar el vídeo",
    "perm_could_not_start.screenshare":
      "No se pudo iniciar el compartir pantalla",
    "perm_could_not_start.audio.message":
      "No se pudo iniciar el audio. Asegúrese de que el micrófono no esté en uso en otra aplicación.",
    "perm_could_not_start.video.message":
      "No se pudo iniciar el vídeo. Asegúrese de que la cámara no esté en uso en otra aplicación.",
    "perm_could_not_start.screenshare.message":
      "No se pudo iniciar el compartir pantalla. Asegúrese de que la pantalla no esté en uso en otra aplicación.",
    full_screen: "Pantalla completa",
    "full_screen.exit": "Salir de pantalla completa",
    "waitlist.header_title": "Lista de espera",
    "waitlist.body_text": "Hay personas en la lista de espera",
    "waitlist.deny_request": "Denegar solicitud",
    "waitlist.accept_request": "Aceptar solicitud",
    "waitlist.accept_all": "Aceptar todo",
    "stage_request.header_title": "Solicitud de escenario",
    "stage_request.deny_request": "Denegar solicitud",
    "stage_request.accept_request": "Aceptar solicitud",
    "stage_request.accept_all": "Aceptar todo",
    "stage_request.deny_all": "Denegar todo",
    "stage_request.approval_pending": "Aprobación pendiente",
    "stage_request.denied": "Denegado",
    "stage_request.request": "Solicitud",
    "stage_request.cancel_request": "Cancelar solicitud",
    "stage_request.leave_stage": "Dejar escenario",
    "stage_request.request_tip": "Solicitar escenario",
    "stage_request.leave_tip": "Dejar escenario",
    "stage_request.pending_tip": "Solicitud pendiente",
    "stage_request.denied_tip": "Solicitud denegada",
    "stage.empty_host": "El anfitrión no está en el escenario",
    "stage.empty_host_summary": "El anfitrión debe estar en el escenario",
    "stage.empty_viewer": "No hay nadie en el escenario",
    "stage.remove_from_stage": "Quitar del escenario",
    "stage.add_to_stage": "Añadir al escenario",
    "stage.join_title": "Unirse al escenario",
    "stage.join_summary": "¿Estás seguro de que quieres unirte al escenario?",
    "stage.join_cancel": "Cancelar",
    "stage.join_confirm": "Confirmar",
    "setup_screen.join_in_as": "Unirse como",
    "setup_screen.joining_as": "Uniendose como",
    "setup_screen.your_name": "Tu nombre",
    "stage.reconnecting": "Reconectando",
    "recording.label": "Grabación",
    "recording.indicator": "Grabando",
    "recording.started": "Grabación iniciada",
    "recording.stopped": "Grabación detenida",
    "recording.error.start": "Error al iniciar la grabación",
    "recording.error.stop": "Error al detener la grabación",
    "recording.start": "Iniciar grabación",
    "recording.stop": "Detener grabación",
    "recording.starting": "Iniciando grabación",
    "recording.stopping": "Deteniendo grabación",
    "recording.loading": "Cargando grabación",
    "recording.idle": "Grabación inactiva",
    audio_playback: "Reproducción de audio",
    "audio_playback.title": "Reproducción de audio",
    "audio_playback.description": "Reproducir audio de la reunión",
    breakout_rooms: "Salas de grupos",
    "breakout_rooms.room_config_header": "Configuración de la sala",
    "breakout_rooms.join_breakout_header": "Unirse a la sala",
    "breakout_rooms.empty": "Vacío",
    "breakout_rooms.delete": "Eliminar",
    "breakout_rooms.switch": "Cambiar",
    "breakout_rooms.main_room": "Sala principal",
    "breakout_rooms.shuffle_participants": "Mezclar participantes",
    "breakout_rooms.deselect": "Deseleccionar",
    "breakout_rooms.selected": "Seleccionado",
    "breakout_rooms.num_of_rooms": "Número de salas",
    "breakout_rooms.approx": "Aproximadamente",
    "breakout_rooms.participants_per_room": "Participantes por sala",
    "breakout_rooms.division_text": "División",
    "breakout_rooms.start_breakout": "Iniciar sala",
    "breakout_rooms.close_breakout": "Cerrar sala",
    "breakout_rooms.update_breakout": "Actualizar sala",
    "breakout_rooms.discard_changes": "Descartar cambios",
    "breakout_rooms.room": "Sala",
    "breakout_rooms.rooms": "Salas",
    "breakout_rooms.room_name": "Nombre de la sala",
    "breakout_rooms.edit_room_name": "Editar nombre de la sala",
    "breakout_rooms.save_room_name": "Guardar nombre de la sala",
    "breakout_rooms.add_room": "Añadir sala",
    "breakout_rooms.add_room_brief": "Añadir una sala de grupos",
    "breakout_rooms.select_all": "Seleccionar todo",
    "breakout_rooms.unassign_all": "Desasignar todo",
    "breakout_rooms.assign": "Asignar",
    "breakout_rooms.assign_participants": "Asignar participantes",
    "breakout_rooms.none_assigned": "Ninguno asignado",
    "breakout_rooms.drag_drop_participants": "Arrastrar y soltar participantes",
    "breakout_rooms.click_drop_participants":
      "Haga clic para soltar participantes",
    "breakout_rooms.status.assign_multiple": "Asignar a múltiples salas",
    "breakout_rooms.status.select_room": "Seleccionar sala",
    "breakout_rooms.ephemeral_status.participants_assigned":
      "Participantes asignados",
    "breakout_rooms.ephemeral_status.participants_assigned_randomly":
      "Participantes asignados al azar",
    "breakout_rooms.ephemeral_status.changes_discarded": "Cambios descartados",
    "breakout_rooms.confirm_modal.start_breakout.header":
      "¿Estás seguro de que quieres iniciar la sala?",
    "breakout_rooms.confirm_modal.start_breakout.content":
      "Los participantes asignados se moverán a la sala de grupos.",
    "breakout_rooms.confirm_modal.start_breakout.cancelText": "Cancelar",
    "breakout_rooms.confirm_modal.start_breakout.ctaText": "Iniciar",
    "breakout_rooms.confirm_modal.close_breakout.header":
      "¿Estás seguro de que quieres cerrar la sala?",
    "breakout_rooms.confirm_modal.close_breakout.content":
      "Los participantes asignados se moverán a la sala principal.",
    "breakout_rooms.confirm_modal.close_breakout.ctaText": "Cerrar",
    "breakout_rooms.move_reason.started_msg": "Iniciado por",
    "breakout_rooms.move_reason.started_desc": "Iniciado la sala",
    "breakout_rooms.move_reason.closed_msg": "Cerrado por",
    "breakout_rooms.move_reason.closed_desc": "Cerrado la sala",
    "breakout_rooms.move_reason.switch_room": "Cambiar de sala",
    "breakout_rooms.move_reason.switch_main_room": "Cambiar a sala principal",
    "breakout_rooms.all_assigned": "Todos asignados",
    "breakout_rooms.empty_main_room": "Sala principal vacía",
    "breakout_rooms.leave_confirmation": "Estas seguro de que quieres salir?",
    "breakout_rooms.leave_confirmation.main_room_btn": "Sala principal",
    search: "Buscar",
    "search.could_not_find": "No se pudo encontrar",
    "search.empty": "Vacío",
    end: "Fin",
    "end.all": "Todo",
    ended: "Finalizado",
    "ended.rejected": "Rechazado",
    "ended.left": "Has salido de la reunión",
    "ended.kicked": "Expulsado",
    "ended.disconnected": "Desconectado",
    "ended.network": "Red",
    network: "Red",
    "network.reconnecting": "Reconectando",
    "network.delay": "Retraso",
    "network.delay_extended": "Retraso extendido",
    "network.restored": "Restaurado",
    "network.lost": "Perdido",
    "network.lost_extended": "Perdido extendido",
    "network.disconnected": "Desconectado",
    "network.leaving": "Saliendo",
    "cta.help": "Ayuda",
    "cta.continue": "Continuar",
    "cta.reload": "Recargar",
    "cta.confirmation": "Confirmación",
    "remote_access.empty": "Vacío",
    "remote_access.requests": "Solicitudes",
    "remote_access.allow": "Permitir",
    "remote_access.grant": "Conceder",
    "remote_access.indicator": "Indicador",
    chat: "Chat",
    "chat.new": "Nuevo",
    "chat.new_channel": "Nuevo canal",
    "chat.channel_name": "Nombre del canal",
    "chat.member_name": "Nombre del miembro",
    "chat.add_members": "Añadir miembros",
    "chat.delete_msg": "Eliminar mensaje",
    "chat.edit_msg": "Editar mensaje",
    "chat.send_msg": "Enviar mensaje",
    "chat.send_attachment": "Enviar adjunto",
    "chat.send_img": "Enviar imagen",
    "chat.send_file": "Enviar archivo",
    "chat.send_emoji": "Enviar emoji",
    "chat.update_msg": "Actualizar mensaje",
    "chat.channel_members": "Miembros del canal",
    "chat.img.loading": "Cargando",
    "chat.error.img_not_found": "Imagen no encontrada",
    "chat.error.empty_results": "No se encontraron resultados",
    "chat.img.shared_by": "Compartido por",
    "chat.reply": "Responder",
    "chat.message_placeholder": "Escribe un mensaje",
    "chat.click_to_send": "Haga clic para enviar",
    "chat.search_msgs": "Buscar mensajes",
    "chat.search_conversations": "Buscar conversaciones",
    "chat.start_conversation": "Iniciar conversación",
    "chat.empty_search": "Búsqueda vacía",
    "chat.empty_channel": "Canal vacío",
    "chat.cancel_upload": "Cancelar carga",
    "date.today": "Hoy",
    "date.yesteday": "Ayer",
    "date.sunday": "Domingo",
    "date.monday": "Lunes",
    "date.tuesday": "Martes",
    "date.wednesday": "Miércoles",
    "date.thursday": "Jueves",
    "date.friday": "Viernes",
    "date.saturday": "Sábado",
    "list.empty": "Vacío",
  };

  const Dyte18nES = (key) => {
    return EslangDict[key];
  };

  return (
    <>
      <Helmet>
        <title>Mindly | Sesión</title>
        <meta name="description" content="Sesión" />
      </Helmet>
      <DyteMeeting t={Dyte18nES} meeting={meeting} ref={meetingEl} />
    </>
  );
}
