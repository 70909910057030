import React, { useState, useEffect } from "react";
import { Button, Progress } from "reactstrap";
import "../../assets/css/cobros.css";
import CobrosBody from "./CobrosBody";
import { Helmet } from "react-helmet";
import axiosInstance from "../../helpers/axiosConfig";

const Cobros = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [completedCitas, setCompletedCitas] = useState(0);

  useEffect(() => {
    fetchCompletedCitas();
  }, []);

  const fetchCompletedCitas = async () => {
    try {
      const response = await axiosInstance.get("/psicologos/completedCitas");
      if (response.status === 200) {
        setCompletedCitas(response.data.body);
      } else {
        setError("Error al obtener las citas completadas");
      }
    } catch (error) {
      setError("Error al obtener las citas completadas");
    }
  };

  const handlePreviousMonth = () => {
    setSelectedMonth((prevMonth) => {
      if (prevMonth === 1) {
        setSelectedYear((prevYear) => prevYear - 1);
        return 12;
      } else {
        return prevMonth - 1;
      }
    });
  };

  const handleNextMonth = () => {
    setSelectedMonth((prevMonth) => {
      if (prevMonth === 12) {
        setSelectedYear((prevYear) => prevYear + 1);
        return 1;
      } else {
        return prevMonth + 1;
      }
    });
  };

  const getMonthName = (month) => {
    const date = new Date(selectedYear, month - 1);
    const monthName = date.toLocaleString("es", { month: "long" });
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };

  const calculateProgress = () => {
    let progress1 = 0;
    let progress2 = 0;
    let progress3 = 0;

    if (completedCitas >= 1 && completedCitas <= 20) {
      progress1 = (completedCitas / 20) * 100;
    } else if (completedCitas >= 21 && completedCitas <= 40) {
      progress1 = 100;
      progress2 = ((completedCitas - 20) / 20) * 100;
    } else if (completedCitas >= 41) {
      progress1 = 100;
      progress2 = 100;
      progress3 = 100;
    }

    return { progress1, progress2, progress3 };
  };

  const { progress1, progress2, progress3 } = calculateProgress();

  return (
    <>
      <Helmet>
        <title>Mindly | Cobros</title>
        <meta name="description" content="Cobros" />
      </Helmet>
      <div className="cobros-section">
        <div className="cobros-container">
          <div className="cobros-title">
            <h6>Cobros</h6>
          </div>
          <div className="cobros-month">
            <Button color="link" onClick={handlePreviousMonth}>
              <i className="bx bx-chevron-left"></i>
            </Button>
            <span>{`${getMonthName(selectedMonth)} ${selectedYear}`}</span>
            <Button color="link" onClick={handleNextMonth}>
              <i className="bx bx-chevron-right"></i>
            </Button>
          </div>
          <CobrosBody
            month={selectedMonth}
            year={selectedYear}
            completedCitas={completedCitas}
          />
          <div className="payment-brackets">
            <h6>Tarifa por sesión:</h6>
            <div className="progressbar-container">
              <div className="progress-box mt-4">
                <Progress
                  value={progress1}
                  color="primary"
                  barClassName="position-relative"
                ></Progress>
                {progress1 > 0 && progress2 === 0 && progress3 === 0 ? (
                  <>
                    <h5 className="title text-muted">
                      <strong>16.5 USD</strong>
                    </h5>
                    <p className="text-muted m-0">
                      <strong>1-20 sesiones</strong>
                    </p>
                  </>
                ) : (
                  <>
                    <h5 className="title text-muted">16.5 USD</h5>
                    <p className="text-muted m-0">1-20 sesiones</p>
                  </>
                )}
              </div>
              <div className="progress-box mt-4">
                <Progress
                  value={progress2}
                  color="primary"
                  barClassName="position-relative"
                ></Progress>
                {progress1 > 0 && progress2 > 0 && progress3 === 0 ? (
                  <>
                    <h5 className="title text-muted">
                      <strong>19.5 USD</strong>
                    </h5>
                    <p className="text-muted m-0">
                      <strong>21-40 sesiones</strong>
                    </p>
                  </>
                ) : (
                  <>
                    <h5 className="title text-muted">19.5 USD</h5>
                    <p className="text-muted m-0">21-40 sesiones</p>
                  </>
                )}
              </div>
              <div className="progress-box mt-4">
                <Progress
                  value={progress3}
                  color="primary"
                  barClassName="position-relative"
                ></Progress>
                {progress1 > 0 && progress2 > 0 && progress3 > 0 ? (
                  <>
                    <h5 className="title text-muted">
                      <strong>22.5 USD</strong>
                    </h5>
                    <p className="text-muted m-0">
                      <strong>41+ sesiones</strong>
                    </p>
                  </>
                ) : (
                  <>
                    <h5 className="title text-muted">22.5 USD</h5>
                    <p className="text-muted m-0">41+ sesiones</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="cobros-footer">
            <p
              className="text-muted m-0 mt-4"
              style={{ fontSize: 12, textAlign: "center" }}
            >
              * No se abonará por la primera sesión con un nuevo paciente
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cobros;
