import React, { useState, useEffect } from "react";
import "../../assets/css/psicolgosSingup.css";
import {
  Row,
  Col,
  Label,
  Input,
  Form,
  Alert,
  Button,
  Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import axiosInstance from "../../helpers/axiosConfig";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function PsicolgosSingUp() {
  const history = useHistory();
  const [paises, setPaises] = useState([]);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [generos, setGeneros] = useState([]);
  const [redirectTo, setRedirectTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (!token) {
      setRedirectTo("/404");
    }
  }, [token]);

  useEffect(() => {
    async function fetchPaises() {
      try {
        const response = await axiosInstance.get("/paises");
        const paisesData = response.data.body;
        if (paisesData) {
          setPaises(paisesData);
        } else {
          throw new Error("No se pudo obtener la información de los paises");
        }
      } catch (error) {
        setError(error.message);
      }
    }
    async function fetchGeneros() {
      try {
        const response = await axiosInstance.get("/generos");
        const generosData = response.data.body;
        if (generosData) {
          setGeneros(generosData);
        } else {
          throw new Error("No se pudo obtener la información de los generos");
        }
      } catch (error) {
        setError(error.message);
      }
    }
    fetchGeneros();
    fetchPaises();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const { password, confirmPassword, email } = userData;
    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      setIsLoading(false);
      return;
    }
    if (email !== confirmEmail) {
      setError("Los correos electrónicos no coinciden");
      setIsLoading(false);
      return;
    }
    if (
      !userData.pais_id ||
      !userData.genero_id ||
      !userData.anos_experiencia ||
      !userData.telefono ||
      !userData.apellido ||
      !userData.nombre ||
      !userData.email ||
      !userData.password ||
      !userData.confirmPassword
    ) {
      setError("Por favor complete todos los campos");
      setIsLoading(false);
      return;
    }
    async function createPsicologo() {
      const { email, password } = userData;
      try {
        const createResponse = await axiosInstance.post("/psicologos/sign-up", {
          psicologoData: userData,
          token,
        });
        if (createResponse.status === 201) {
          const expiresAt = new Date().getTime() + 1000 * 60 * 60 * 11;
          const response = await axiosInstance.post("/auth/login", {
            email,
            password,
          });
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem("expiresAt", expiresAt);
          localStorage.setItem("role", response.data.user.role);
          setRedirectTo("/mi-espacio/agenda");
          setIsLoading(false);
        } else {
          throw new Error("Error al crear el psicologo");
        }
      } catch (error) {
        setError("Error al crear el psicologo");
      }
      setIsLoading(false);
    }
    createPsicologo();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  if (redirectTo) {
    history.push(redirectTo);
  }

  return (
    <>
      <Helmet>
        <title>Mindly | Registro Psicologos</title>
        <meta name="description" content="Registro Psicologos" />
      </Helmet>
      <div className="singup-section">
        <div className="singup-form-container">
          <div className="singup-form-header">
            {error && (
              <Alert className="bg-soft-primary fw-medium">
                <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
                {error}
              </Alert>
            )}
            <h5>¡Hola! Te damos la bienvenida a Mindly.</h5>
            <p className="text-muted">
              Completa el siguiente formulario para registrarte como
              profesional. 🚀
            </p>
          </div>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Nombre</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="user"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="nombre"
                      id="name"
                      type="text"
                      className="form-control ps-5"
                      placeholder="Nombre"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Apellido</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="user"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="apellido"
                      id="apellido"
                      type="text"
                      className="form-control ps-5"
                      placeholder="Apellido"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Correo Electrónico</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="mail"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="email"
                      id="email"
                      type="email"
                      className="form-control ps-5"
                      placeholder="Email"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    Confirmar Correo Electrónico
                  </Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="mail"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="confirmEmail"
                      id="confirmEmail"
                      type="email"
                      className="form-control ps-5"
                      placeholder="Confirmar Email"
                      onChange={(e) => setConfirmEmail(e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Contraseña</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="lock"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="password"
                      id="password"
                      type="password"
                      className="form-control ps-5"
                      placeholder="Contraseña (mínimo 8 caracteres)"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Confirmar Contraseña</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="lock"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="confirmPassword"
                      id="confirmPassword"
                      type="password"
                      className="form-control ps-5"
                      placeholder="Confirmar Contraseña"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Género</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="user"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="genero_id"
                      id="genero"
                      type="select"
                      className="form-control ps-5"
                      placeholder="Seleccione su género"
                      onChange={handleInputChange}
                    >
                      <option hidden selected>
                        Seleccione su género
                      </option>
                      {generos.map((genero) => (
                        <option key={genero.id} value={genero.id}>
                          {genero.genero}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Teléfono</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="phone"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="telefono"
                      id="telefono"
                      type="text"
                      className="form-control ps-5"
                      placeholder="Numero de celular"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Nacionalidad</Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="globe"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="pais_id"
                      id="pais"
                      type="select"
                      className="form-control ps-5"
                      placeholder="Seleccione Nacionalidad"
                      onChange={handleInputChange}
                    >
                      <option hidden selected>
                        Seleccione su nacionalidad
                      </option>
                      {paises.map((pais) => (
                        <option key={pais.id} value={pais.id}>
                          {pais.pais}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    Años de experiencia clínica
                  </Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="briefcase"
                      className="fea icon-sm icons"
                    ></FeatherIcon>
                    <Input
                      required={true}
                      name="anos_experiencia"
                      id="anos_experiencia"
                      type="number"
                      className="form-control ps-5"
                      placeholder="Años de experiencia"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="12" className="btnActualizar-row">
                <Button
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn btn-pills btn-primary btnActualizar d-flex justify-content-center align-items-center"
                >
                  {isLoading ? (
                    <Spinner size="sm" className="mx-2"></Spinner>
                  ) : (
                    <i className="bx bx-calendar-plus"></i>
                  )}
                  <p className="m-0">Registrarme</p>
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default PsicolgosSingUp;
