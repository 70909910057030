import React from "react";
import CitasCard from "../MisCitas/CitaCard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CitaLoader from "../../components/Shared/Loaders/ListingWithThumbNail";

const ProximaCita = ({ nextCita, loading }) => {
  return (
    <div className="proxima-cita-section-container">
      <h5 className="m-1">Próxima Cita</h5>
      <div className="next-cita-container">
        {loading && <CitaLoader className="px-5 mt-5" />}
        {!loading && !nextCita && (
          <div className="my-5 d-flex flex-column">
            <p className="text-secondary text-center">
              No hay citas que mostrar
            </p>
            <Link to="/mi-espacio/especialistas" className="btn btn-primary">
              Agendar Cita
            </Link>
          </div>
        )}
        {nextCita && (
          <div className="citacard-container">
            <CitasCard cita={nextCita} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProximaCita;
