import React, { useState } from "react";
import { Container, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import axiosInstance from "../../helpers/axiosConfig";

const AdminRegistroPsicologos = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/psicologos/enviar-email-registro?email=${email}`
      );
      if (response.status === 200) {
        setMessage("Email enviado correctamente a: " + email);
      }
    } catch (error) {
      setError("Error al enviar el email" + error);
    }
    setLoading(false);
  };
  return (
    <Container className="my-5 bg-light rounded p-5">
      {error && <p className="text-danger">{error}</p>}
      {message && <p className="text-success">{message}</p>}
      <Form className="d-flex flex-column justify-content-center align-items center px-5">
        <FormGroup>
          <Label for="email">
            Correo Electrónico del psicologo a incorporar
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Ingrese el correo electrónico del psicologo a registrar"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <button className="btn btn-primary" onClick={sendEmail}>
          {loading && <Spinner className="mx-2" size="sm" />}
          Enviar Email de Bienvenida
        </button>
      </Form>
    </Container>
  );
};

export default AdminRegistroPsicologos;
