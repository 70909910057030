import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

// import images
import classic from "../../assets/images/saas/classic01.png";
import inbox from "../../assets/images/illustrator/psicologo-system.png";
import { Link } from "react-router-dom";
export default class Website extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <img src={classic} className="img-fluid" alt="" />
              </Col>

              <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title ms-lg-5">
                  <h4 className="title mb-4">
                    Incrementa en un <br /> 300% tus ingresos.
                  </h4>
                  <p className="text-muted">
                    Mindly ofrece sus servicios en todo el mundo, logrando que
                    los psicologos sean recomepensados como lo merecen.
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      En promedio, nuestros psicologos multiplican x3 sus
                      ingresos.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Acceden a pacientes de todo el mundo.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Cobran en dólares por sus servicios.
                    </li>
                  </ul>
                  <a
                    href="https://fzaqwxs849j.typeform.com/to/AMVX5fVV"
                    target="_blank"
                    className="mt-3 h6 text-primary"
                    rel="noreferrer"
                  >
                    Aplicar ahora <i className="uil uil-angle-right-b"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col md={6} className="order-1 order-md-2">
                <img src={inbox} alt="" className="psicologo-system-img" />
              </Col>

              <Col md={6} className="order-2 order-md-1">
                <div className="section-title ms-lg-5">
                  <h4 className="title mb-4">
                    Despreocupate de las tareas administrativas, nosotros nos
                    encargamos.
                  </h4>
                  <p className="text-muted">
                    Enfocate unicamente en ofrecer un servicio ético y
                    profesional a tus pacientes. Nosotros nos encargamos de lo
                    demas.
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Sistema de cobros y pagos automatizado.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Gestion de citas y pacientes.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Logística y soporte al cliente.
                    </li>
                  </ul>
                  <a
                    href="https://fzaqwxs849j.typeform.com/to/AMVX5fVV"
                    target="_blank"
                    className="mt-3 h6 text-primary"
                    rel="noreferrer"
                  >
                    Comenzar registro <i className="uil uil-angle-right-b"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
