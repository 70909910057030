import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import axiosInstance from "../../helpers/axiosConfig";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import TimesLoader from "../../components/Shared/Loaders/TimesLoader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DateTimePicker = ({ psicologoId, availableCitas }) => {
  const options = {
    dateStyle: "medium",
  };

  const location = useLocation();
  let reScheduleCitaId = false;
  if (location.state) {
    reScheduleCitaId = location.state.reScheduleCitaId;
  }

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [redirectTo, setRedirectTo] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timeIsLoading, setTimeIsLoading] = useState(false);
  const [fetchPsicologoIsLoading, setFetchPsicologoIsLoading] = useState(true);
  const [unavailableTimes, setUnavailableTimes] = useState([]);
  const [availability, setAvailability] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [descansos, setDescansos] = useState(null);
  const [psicologo, setPsicologo] = useState(null);

  const daysMap = {
    0: "dom",
    1: "lun",
    2: "mar",
    3: "mié",
    4: "jue",
    5: "vie",
    6: "sáb",
  };

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const role = localStorage.getItem("role");

  useEffect(() => {
    const fetchPsicologo = async () => {
      try {
        const response = await axiosInstance.get(`/psicologos/${psicologoId}`);
        if (response.status === 200) {
          console.log(response.data.body);
          setPsicologo(response.data.body);
        }
      } catch (error) {
        console.error("Error fetching psicologo:", error);
      }
      setFetchPsicologoIsLoading(false);
    };

    const fetchAvailability = async () => {
      try {
        const response = await axiosInstance.get(
          `/psicologos/${psicologoId}/disponibilidad`
        );
        if (response.status === 200) {
          setAvailability(response.data.body);
        }
      } catch (error) {
        console.error("Error fetching availability:", error);
      }
    };

    const fetchDescansos = async () => {
      try {
        const response = await axiosInstance.get(
          `/psicologos/${psicologoId}/descansos`
        );
        if (response.status === 200) {
          setDescansos(response.data.body);
        }
      } catch (error) {
        console.error("Error fetching descansos:", error);
      }
    };

    fetchPsicologo();
    fetchAvailability();
    fetchDescansos();
  }, [psicologoId]);

  const handleDateChange = async (date) => {
    setTimeIsLoading(true);
    setSelectedDate(date);
    const selectedUTCDate = new Date(date.toISOString());
    const selectedUTCDateFormatted = date.toISOString().slice(0, 10);
    let times = await getUnavailableTimes(selectedUTCDateFormatted);
    if (descansos && descansos.length > 0) {
      const dayOfWeek = daysMap[selectedUTCDate.getUTCDay()];
      const descanso = descansos.find(
        (descanso) => descanso.day_of_week === dayOfWeek
      );

      if (descanso) {
        const { DescansoRangeTimes } = descanso;
        const today = moment.tz(new Date(), userTimezone).format("YYYY-MM-DD");
        for (const range of DescansoRangeTimes) {
          const { fromTime, toTime } = range;
          const fromTimeUTC = moment.tz(`${today}T${fromTime}:00Z`, "UTC");
          const toTimeUTC = moment.tz(`${today}T${toTime}:00Z`, "UTC");

          const fromTimeLocal = fromTimeUTC.clone().tz(userTimezone);
          const toTimeLocal = toTimeUTC.clone().tz(userTimezone);

          const fromHour = fromTimeLocal.hour();
          const toHour = toTimeLocal.hour();

          for (let hour = fromHour; hour < toHour; hour++) {
            const timeString = `${hour.toString().padStart(2, "0")}:00`;
            if (!times.includes(timeString)) {
              times.push(timeString);
            }
          }
        }
      }
    }
    setUnavailableTimes(times);
    setTimeIsLoading(false);
  };

  async function getUnavailableTimes(selectedUTCDate) {
    const dateTimesArray = [];
    await axiosInstance
      .get(`/psicologos/${psicologoId}/unavailableDateTimes`)
      .then((response) => {
        const unavailableDateTimes = response.data.body;
        if (unavailableDateTimes.length === 0) {
          return;
        }
        for (const date of unavailableDateTimes) {
          dateTimesArray.push(
            moment.tz(date, userTimezone).format("YYYY-MM-DD HH:mm")
          );
        }
      });
    return await getMatchingTimes(dateTimesArray, selectedUTCDate);
  }

  function getMatchingTimes(datesTimeArray, selectedUTCDate) {
    const selectedDate = moment.utc(selectedUTCDate);
    const result = [];

    for (let i = 0; i < datesTimeArray.length; i++) {
      const dateTimeString = datesTimeArray[i];
      const [dateString, timeString] = dateTimeString.split(" ");
      const [year, month, day] = dateString.split("-");
      const [hour, minute] = timeString.split(":");

      const date = moment.utc([year, month - 1, day, hour, minute]);

      if (
        date.year() === selectedDate.year() &&
        date.month() === selectedDate.month() &&
        date.date() === selectedDate.date()
      ) {
        result.push(date.format("HH:mm"));
      }
    }
    return result;
  }

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const [hours, minutes] = selectedTime.split(":");
    const selectedDateTime = new Date(year, month, day, hours, minutes);

    try {
      if (!reScheduleCitaId) {
        const response = await axiosInstance.post(
          `/psicologos/${psicologoId}/citas`,
          {
            fecha: selectedDateTime.toISOString(),
            psicologo_id: psicologoId,
          }
        );

        if (response.status === 201) {
          const role = localStorage.getItem("role");
          if (role === "paciente") {
            // if (freeCita == 1) {
            //   const claimFreeCitaResponse = await axiosInstance.patch(
            //     `/payment/claimFreeCita`,
            //     {
            //       citaId: response.data.body,
            //       userTimezone,
            //     }
            //   );
            //   if (claimFreeCitaResponse.status === 200) {
            //     setIsLoading(false);
            //     setShowModal(true);
            //   } else {
            //     setError("Error al reclamar la cita gratuita");
            //   }
            // } else if
            if (availableCitas > 0) {
              const claimCitaResponse = await axiosInstance.patch(
                `/payment/claimCita`,
                {
                  citaId: response.data.body,
                  userTimezone,
                }
              );
              if (claimCitaResponse.status === 200) {
                setIsLoading(false);
                setShowModal(true);
              } else {
                setError("Error al reclamar la cita");
              }
            } else {
              setRedirectTo(`/mi-espacio/payment/${response.data.body}`);
            }
          } else {
            window.location.reload();
          }
        }
      } else {
        const response = await axiosInstance.patch(
          `/citas/${reScheduleCitaId}/reprogramar`,
          {
            fecha: selectedDateTime.toISOString(),
          }
        );
        if (response.status === 200) {
          setRedirectTo(`/mi-espacio/mis-citas/${reScheduleCitaId}`);
        }
      }
    } catch (error) {
      setError("Error al agendar la cita, por favor intente nuevamente");
      setIsLoading(false);
    }
  };

  const today = new Date();
  const maxDate = new Date(today.setDate(today.getDate() + 19));

  const dateFilter = (date) => {
    const availableDays = availability?.availableDays;
    const isWithinRange = date < maxDate && date >= Date.now() - 86400000;
    const enabledDays = availableDays.split(",");
    const dayAbbreviation = date.toLocaleString("es", { weekday: "short" });
    const isEnabledDay = enabledDays.includes(dayAbbreviation);
    return isWithinRange && isEnabledDay;
  };

  const hourOptions = [];
  if (availability && descansos) {
    const { availableFromTime, availableToTime } = availability;
    const today = moment.tz(new Date(), userTimezone).format("YYYY-MM-DD");

    const utcFromDate = moment.tz(`${today}T${availableFromTime}:00Z`, "UTC");
    const utcToDate = moment.tz(`${today}T${availableToTime}:00Z`, "UTC");

    const fromTimeLocal = utcFromDate.clone().tz(userTimezone);
    const toTimeLocal = utcToDate.clone().tz(userTimezone);

    const fromHour = fromTimeLocal.hour();
    let toHour = toTimeLocal.hour();

    if (fromHour > toHour) {
      toHour = 23;
    }

    for (let hour = fromHour; hour <= toHour; hour++) {
      const timeString = `${hour.toString().padStart(2, "0")}:00`;
      if (selectedDate && selectedDate <= new Date()) {
        if (
          !unavailableTimes.includes(timeString) &&
          hour > new Date().getHours() + 4
        ) {
          hourOptions.push(timeString);
        }
      } else {
        if (!unavailableTimes.includes(timeString)) {
          hourOptions.push(timeString);
        }
      }
    }
  }

  if (redirectTo && reScheduleCitaId) {
    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { isUpdate: true },
        }}
      />
    );
  } else if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const toggle = () => {
    setShowModal(!showModal);
    setRedirectTo(`/mi-espacio/mis-citas`);
  };

  return (
    <>
      {error && (
        <Alert className="bg-soft-primary fw-medium main-error-alert">
          <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
          {error}
        </Alert>
      )}
      {showModal && (
        <Modal isOpen={showModal} toggle={toggle}>
          <ModalBody className="m-auto p-4">
            <i className="uil uil-info-circle fs-5 align-middle me-1"></i>
            <span>Cita reservada correctamente 🚀</span>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Genial!
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <div className="schedule-card">
        <div className="card-body">
          {!fetchPsicologoIsLoading ? (
            psicologo?.is_public ? (
              <Row>
                <Col>
                  {availability && descansos ? (
                    <Form>
                      <FormGroup>
                        <Label for="dateSelect">Fecha:</Label>
                        <div className="datePickerWrapper">
                          <DatePicker
                            locale={es}
                            id="dateSelect"
                            name="dateSelect"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            filterDate={dateFilter}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Abrir Calendario..."
                            calendarClassName="datePickerCalendar"
                            wrapperClassName="datePickerInputWrapper"
                            className="datePickerInput"
                            disabledKeyboardNavigation
                            onFocus={(e) => e.target.blur()}
                          />
                        </div>
                      </FormGroup>
                      {selectedDate && (
                        <FormGroup>
                          <Label>Hora:</Label>
                          {!timeIsLoading ? (
                            <>
                              {hourOptions.length === 0 && (
                                <p
                                  className="m-3 w-100 text-secondary"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  No hay horarios disponibles para esta fecha
                                </p>
                              )}
                              <div className="timePickerWrapper">
                                {hourOptions.map((time) => (
                                  <div
                                    key={time}
                                    className={`timePickerOption ${
                                      time === selectedTime ? "selected" : ""
                                    }`}
                                    onClick={() => handleTimeClick(time)}
                                  >
                                    {time}
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <div className="timePickerWrapper d-flex flex-nowrap">
                              <TimesLoader />
                            </div>
                          )}
                        </FormGroup>
                      )}
                      {selectedTime && (
                        <FormGroup>
                          <Label for="confirmation">Confirmación:</Label>
                          <Input
                            type="text"
                            name="confirmation"
                            id="confirmation"
                            value={`${selectedDate.toLocaleDateString(
                              "es-ES",
                              options
                            )} ${selectedTime}hs. (${userTimezone})`}
                            readOnly
                            className="input-confirmation"
                          />
                        </FormGroup>
                      )}
                      {selectedTime && (
                        <div
                          rel="noreferrer"
                          className="agendarCitaBtn-container"
                          onClick={handleSubmit}
                        >
                          <div
                            id="agendarCita"
                            className="btn btn-pills btn-primary agendarBtn"
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="m-1"></Spinner>
                            ) : (
                              <i className="bx bx-calendar-plus"></i>
                            )}

                            <p>
                              {" "}
                              {role === "psicologo"
                                ? "Ocupar Espacio"
                                : "Agendar Cita"}
                            </p>
                          </div>
                        </div>
                      )}
                    </Form>
                  ) : (
                    <div className="text-center">
                      <Spinner color="primary" />
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <p
                className="center text-secondary p-4"
                style={{ fontSize: "0.9rem", textAlign: "center" }}
              >
                Este terapeuta ha desactivado su agenda, puedes ponerte en
                contacto con el/ella a traves del{" "}
                <Link
                  to="/chat"
                  className="text-primary text-decoration-underline"
                >
                  chat
                </Link>
                .
              </p>
            )
          ) : (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DateTimePicker;
